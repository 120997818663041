import * as React from 'react';

export const FlagIcon = (props?: any) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
      {...props}
    >
      <rect width="50" height="50" fill="#000" rx="25"></rect>
      <g clipPath="url(#clip0_11898_118932)">
        <path
          fill="#fff"
          d="M27 19l-1-2h-8v17h2v-7h5l1 2h7V19h-6zm4 8h-4l-1-2h-6v-6h5l1 2h5v6z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_11898_118932">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(13 13)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
  
import * as React from 'react';
import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { Button } from './index';
import { HeartIcon as Heart } from '../assets/icons';
import { textButton, shimmerStyles } from '../Variables/mixins';
import { StyledPropsTheme } from 'Variables';

type Icons = 'heart';

const icon = (type: Icons) => {
  switch (type) {
    case 'heart':
      return <Heart type={type} />;
    default:
      return null;
  }
};

export type CompactButtonProps = {
  iconType: Icons;
  active?: boolean;
  disabled?: boolean;
  load?: boolean;
  small?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const CompactButton: React.FC<CompactButtonProps> = ({
  iconType,
  ...rest
}) => (
  <ButtonContainer iconType={iconType} {...rest}>
    {icon(iconType)}
  </ButtonContainer>
);

CompactButton.defaultProps = {
  iconType: 'heart',
  active: false,
};

const getButtonBackground = (props: CompactButtonProps & StyledPropsTheme) => {
  switch (props.iconType) {
    case 'heart': {
      return css`
        background: ${(props: CompactButtonProps & StyledPropsTheme) =>
          props.disabled
            ? props.theme.colors.compactButtonDisabled
            : props.active
            ? props.theme.colors.compactButtonPushed
            : props.theme.colors.compactButton};
        .fill,
        .border {
          fill: ${(props: CompactButtonProps & StyledPropsTheme) =>
            props.active && !props.disabled
              ? props.theme.colors.compactButtonIconFill
              : ''};
        }
        &:hover,
        &:focus {
          background: ${(props: CompactButtonProps & StyledPropsTheme) =>
            props.disabled
              ? props.theme.colors.button
              : props.theme.colors.buttonBorder};
        }
        &:active {
          background: ${props => props.theme.colors.compactButtonPushed};
          .fill,
          .border {
            fill: ${props => props.theme.colors.compactButtonIconFill};
          }
        }
      `;
    }
    default:
      return null;
  }
};

const ButtonContainer = styled(Button)<StyledPropsTheme & CompactButtonProps>`
  ${textButton};
  ${props => props.small ? css`
    width: 40px;
    height: 40px;
  ` :  css`
    width: 50px;
    height: 50px;
  `}
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props =>
    props.disabled || props.load
      ? '1px solid transparent'
      : '1px solid ' + props.theme.colors.buttonBorder};
  border-radius: 10px;
  opacity: ${props => (props.disabled ? '0.25' : '1')};
  ${props => (props.load ? shimmerStyles : getButtonBackground)};
`;

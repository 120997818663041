import * as React from 'react'
import { FC } from 'react'
import styled from 'styled-components'

import { Divider } from '../Menus/divider'

export const FlatButtonCard:FC<{className?: string; children?: React.ReactNode;}> = ({ children, className }) => {
  return (
    <Container className={className}>
      <Divider />
      <InnerContainer>
        {children}
      </InnerContainer>
    </Container>
  )
}

const Container = styled.div`
  box-shadow: 0px -28px 26px ${props => props.theme.colors.flatButtonContainerShadow};
`

const InnerContainer = styled.div`
  display: flex;
  button {
    flex: 1;
  }
`

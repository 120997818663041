import * as React from 'react';

export const AddGoodIcon: React.FC = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="9" width="20" height="2" rx="1" fill="white" />
    <rect
      y="29"
      width="20"
      height="2"
      rx="1"
      transform="rotate(-90 0 20)"
      fill="white"
    />
  </svg>
);

export default AddGoodIcon;

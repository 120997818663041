import * as React from 'react';

export const OpenedEnvelopIcon = (props: any) => {
  return ( 
    <svg {...props} width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.0143 101.486L2.39297 147.606H147.606V48.6707L34.0143 101.486Z" fill="#FFE777"/>
      <path d="M56.8494 98.1384L2.39297 147.606V48.6707L56.8494 69.5713V98.1384Z" fill="#FFF1B1"/>
      <path d="M138.031 57.3679V138.91L93.3188 97.9693L138.031 57.3679Z" fill="#FFF1B1"/>
      <path d="M2.39297 48.6707L31.9134 75.4878L74.9994 98.6713L118.085 75.4884L147.606 48.6707H118.085H31.9143H2.39297Z" fill="#969696"/>
      <path d="M31.9143 62.55V75.4881L74.9994 114.629L118.085 75.4884V62.55H31.9143Z" fill="#DCDCDC"/>
      <path d="M118.085 2.39355V62.55L74.9994 101.694L31.9143 62.55V2.39355H118.085Z" fill="white"/>
      <path d="M147.606 46.2771H120.478V2.39355C120.478 1.07168 119.406 0 118.085 0H31.9143C30.5924 0 29.5207 1.07168 29.5207 2.39355V46.2768H2.39355C1.07168 46.2768 0 47.3484 0 48.6703V147.606C0 148.928 1.07168 150 2.39355 150H147.606C148.928 150 150 148.928 150 147.606V48.6706C150 47.3487 148.928 46.2771 147.606 46.2771ZM34.3078 4.78711H115.691V74.4278L91.5627 96.3463C91.5554 96.3527 91.5483 96.3595 91.541 96.3662L74.9985 111.394L58.4637 96.3732C58.4525 96.363 58.4417 96.3527 58.4306 96.3428L34.3078 74.429V4.78711ZM145.213 145.213H4.78711V51.0642H29.5204V70.0805L14.5421 56.4738C13.5639 55.585 12.0498 55.6573 11.1609 56.6358C10.2721 57.6144 10.3444 59.1281 11.3229 60.017L53.2881 98.1387L11.3247 136.259C10.3462 137.148 10.2738 138.662 11.1627 139.641C11.635 140.161 12.2839 140.425 12.9352 140.425C13.5094 140.425 14.0854 140.219 14.5438 139.803L56.8479 101.373L73.3895 116.4C73.846 116.815 74.4223 117.022 74.9991 117.022C75.5757 117.022 76.1522 116.815 76.6087 116.4L93.1515 101.372L135.458 139.804C135.916 140.221 136.492 140.426 137.067 140.426C137.718 140.426 138.367 140.162 138.839 139.642C139.728 138.664 139.656 137.15 138.677 136.261L96.7107 98.1378L138.675 60.0164C139.654 59.1275 139.726 57.6138 138.837 56.6353C137.948 55.6567 136.434 55.5844 135.456 56.4732L120.478 70.0796V51.0645H145.213V145.213H145.213Z" fill="black"/>
      <path d="M51.0633 23.936H98.9356C100.257 23.936 101.329 22.8644 101.329 21.5425C101.329 20.2206 100.257 19.1489 98.9356 19.1489H51.0633C49.7414 19.1489 48.6697 20.2206 48.6697 21.5425C48.6694 22.8644 49.7414 23.936 51.0633 23.936Z" fill="black"/>
      <path d="M51.0633 36.7022H98.9356C100.257 36.7022 101.329 35.6305 101.329 34.3086C101.329 32.9867 100.257 31.915 98.9356 31.915H51.0633C49.7414 31.915 48.6697 32.9867 48.6697 34.3086C48.6694 35.6305 49.7414 36.7022 51.0633 36.7022Z" fill="black"/>
      <path d="M51.0633 49.468H74.9994C76.3213 49.468 77.393 48.3963 77.393 47.0745C77.393 45.7526 76.3213 44.6809 74.9994 44.6809H51.0633C49.7414 44.6809 48.6697 45.7526 48.6697 47.0745C48.6697 48.3963 49.7414 49.468 51.0633 49.468Z" fill="black"/>
    </svg>
  )
};

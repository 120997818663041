import * as React from 'react';

export const CheckIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.79359 15.8749L5.32359 12.4049C5.13676 12.2176 4.88311 12.1124 4.61859 12.1124C4.35408 12.1124 4.10043 12.2176 3.91359 12.4049C3.52359 12.7949 3.52359 13.4249 3.91359 13.8149L8.09359 17.9949C8.48359 18.3849 9.11359 18.3849 9.50359 17.9949L20.0836 7.41489C20.4736 7.02489 20.4736 6.39489 20.0836 6.00489C19.8968 5.81764 19.6431 5.7124 19.3786 5.7124C19.1141 5.7124 18.8604 5.81764 18.6736 6.00489L8.79359 15.8749Z"
        fill="#7CB342"
      />
    </svg>
  );
};

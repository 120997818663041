import * as React from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonProps } from './index';

import { textButton, shimmerStyles } from '../Variables/mixins';
import { StyledPropsTheme } from 'Variables';

type Props = Omit<ButtonProps, 'form' | 'filled'> & {
  pressed?: boolean;
  transparent?: boolean;
  children?: React.ReactNode;
};

export const SecondaryButton: React.FC<Props> = ({ children, ...rest }) => (
  <ButtonContainer {...rest}>{children}</ButtonContainer>
);

const getButtonBackground = (props: Props & StyledPropsTheme) => {
  if (props.transparent) {
    if (props.disabled) {
      return css`
        opacity: 0.15;
        background: none;
        border: none;
      `;
    } else if (props.pressed) {
      return css`
        background: ${props => props.theme.colors.transparentSecondaryButtonActive};
        color: ${props => props.theme.colors.dark};
        border: none;
        &:hover,
        &:active {
          background: ${props => props.theme.colors.transparentSecondaryButtonActive};
          color: ${props => props.theme.colors.dark};
          border: none;
        }
      `;
    }
    return css`
      background: none;
      border: none;
      &:hover {
        background: ${props => props.theme.colors.transparentSecondaryButtonHover};
        box-shadow: 0px 4px 4px ${props => props.theme.colors.buttonActive},
          inset 0px -3px 0px ${props => props.theme.colors.cartButtonDisabled};
      }
      &:active {
        background: ${props => props.theme.colors.transparentSecondaryButtonActive};
        color: black;
      }
    `;
  } else if (props.disabled) {
    return css`
      background: ${props.theme.colors.button};
    `;
  } else if (props.pressed) {
    return css`
      background: ${props => props.theme.colors.secondaryButtonPushed};
    `;
  } else {
    return css`
      background: ${props.theme.colors.secondaryButton};
      &:hover {
        background: ${(props: Props & StyledPropsTheme) =>
          props.disabled
            ? props.theme.colors.button
            : props.theme.colors.buttonBorder};
      }
      &:active {
        background: ${props => props.theme.colors.secondaryButtonPushed};
      }
    `;
  }
};

const ButtonContainer = styled(Button)<{children?: React.ReactNode; active?: boolean;}>`
  ${textButton};
  border: ${props =>
    props.disabled || props.load
      ? '1px solid transparent'
      : '1px solid ' + props.theme.colors.buttonBorder};
  color: black;
  &:active {
    color: ${props =>
      props.disabled || props.load
        ? props.theme.colors.dark
        : props.theme.colors.light};
  }
  
  ${props =>
    props.pressed &&
    css`
      color: ${props => props.theme.colors.light};
    `}
  opacity: ${props => (props.disabled ? '0.25' : '1')};
  ${props => (props.load ? shimmerStyles : getButtonBackground)};
`;

import * as React from 'react';
import { Fragment, useCallback, FC } from 'react';
import styled, { css } from 'styled-components';

import { ContentCard } from './ContentCard';
import { shadowBackground } from '../Variables/mixins';

type BottomFixedCardProps = {
  top?: boolean;
  overflow?: boolean;
  onClose?: (any?: any) => any;
  children?: React.ReactNode;
};

export const BottomFixedCard: FC<BottomFixedCardProps> = ({
  children,
  overflow,
  onClose,
  ...props
}) => {
  const handleOnClose = useCallback(
    e => {
      e.preventDefault();
      onClose && onClose();
    },
    [onClose]
  );
  return (
    <Fragment>
      {overflow && (
        <ShadowBackground {...props} onClick={handleOnClose}></ShadowBackground>
      )}
      <BottomCardContainer {...props}>{children}</BottomCardContainer>
    </Fragment>
  );
};

const BottomCardContainer = styled(ContentCard)<{top?: boolean}>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  margin: 0;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  ${props =>
    props.top &&
    css`
      bottom: auto;
      top: 0;
    `}
`;

const ShadowBackground = styled.div`
  ${shadowBackground};
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

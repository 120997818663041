import * as React from 'react';

export const FileIcon = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="none"
            viewBox="0 0 36 36"
            {...props}
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M33.62 6.083h-3.703V2.38A1.8 1.8 0 0028.12.583h-.055A1.804 1.804 0 0026.25 2.38v3.703h-3.685c-.99 0-1.797.807-1.815 1.797v.055c0 1.008.807 1.815 1.815 1.815h3.685v3.685c0 .99.807 1.815 1.815 1.797h.055a1.8 1.8 0 001.797-1.797V9.75h3.703a1.8 1.8 0 001.797-1.797V7.88a1.8 1.8 0 00-1.797-1.797zm-9.203 7.352v-1.852h-1.852a3.696 3.696 0 01-2.585-1.063 3.729 3.729 0 01-1.063-2.64c0-.66.183-1.265.495-1.797H4.25A3.677 3.677 0 00.583 9.75v22a3.677 3.677 0 003.667 3.667h22a3.677 3.677 0 003.667-3.667V16.57a3.755 3.755 0 01-1.87.513c-1.999-.018-3.63-1.65-3.63-3.648zM6.083 31.75h18.26c.77 0 1.192-.88.715-1.485l-5.408-6.747c-.367-.476-1.082-.458-1.449.019l-4.785 6.38-2.933-4.419c-.367-.513-1.118-.55-1.503-.036l-3.63 4.821c-.459.605-.019 1.467.733 1.467z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

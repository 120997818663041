import React, { FC, memo, useState } from "react";
import styled, { css } from 'styled-components';
import { StyledPropsTheme, StyledPropsTheme2 } from "Variables";
import { shimmerStyles, textRoundButton } from '../../Variables/mixins';
import { size } from "../../Variables/sizes";

export type RoundButtonProps = {
  disabled?: boolean;
  load: boolean;
  title?: string;
  className?: string;
  round?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  pressed?: boolean;
  focus?: boolean;
  Icon?: React.ReactNode;
  IconPosition?: "left" | "right" | "center";
  size?: "small" | "large";
  onClick?: (a?: any) => any;
  onMouseEnter?: (a?: any) => any;
  onMouseLeave?: (a?: any) => any;
  tooltipText?: React.ReactNode;
  form?: string;
  children?: React.ReactNode;
  minimized?: boolean;
  buttonType?: "primary" | "secondary";
};

export const RoundButton: FC<RoundButtonProps> = memo(({ children, tooltipText, ...rest }) => {
  const [isHovered, setIsHovered] = useState(false);
  const showTooltip = isHovered;

  return (
    <RoundButtonContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}>
      {children}
      {showTooltip && tooltipText}
    </RoundButtonContainer>
  )
});


const getSize = (props: RoundButtonProps & StyledPropsTheme2) => {
  switch (props.size) {
    case "large":
      return css`
              min-width: 50px;
              min-height: 50px;
            `
    case 'small':
      return css`
              min-width: 40px;
              min-height: 40px;
            `
    default:
      return css`
              height: initial;
            `
  }
}

const minimizedButton = (props: RoundButtonProps & StyledPropsTheme2) => {
  if (props.minimized) {
    return css`
          max-width: 137px;
          width: 100%;
          height: 40px;
          font-size: 14px;
        `
  } else {
    return;
  }
}

const RoundButtonContainer = styled.button<RoundButtonProps & StyledPropsTheme>`
  ${textRoundButton};
  position: relative;
  border-radius: ${(props) =>
    props.round
      ? '50px'
      : '10px'};
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  order: ${props => props.theme.colors.white};
  max-width: 400px;
  ${(props: RoundButtonProps & StyledPropsTheme) => props.load && shimmerStyles};
  ${getSize};
  ${minimizedButton};
  padding: ${props => props.round ? '11px' : '13px 25px'};
  ${props => props.buttonType === "secondary" 
    && `:hover:active {
        padding: ${props.round ? '9px' : '11px 23px'};
      }`
  }
  @media screen and (max-width: ${size.mobile}){
    max-width: 100%;
    width: 100%;
  }
`

RoundButton.defaultProps = {
  load: false,
  disabled: false,
};

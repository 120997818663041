import * as React from 'react';

export const TagIcon = ((props: any) => {
  return (
    <svg
      {...props}
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18248_50829)">
        <path
          d="M10.0667 17.1L5.46669 12.5L10.0667 7.9L8.66669 6.5L2.66669 12.5L8.66669 18.5L10.0667 17.1ZM15.2667 17.1L19.8667 12.5L15.2667 7.9L16.6667 6.5L22.6667 12.5L16.6667 18.5L15.2667 17.1Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_18248_50829">
          <rect width="24" height="24" fill="white" transform="translate(0.666687 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
});
import * as React from 'react';
import { useState, useEffect, memo, Fragment, useCallback } from 'react';
import styled, { CSSObject } from 'styled-components';

import { P2 } from '../Text/P2';
import { B } from '../Text/B';
import { IncreaseIcon, DecreaseIcon, DeleteIcon } from '../assets/icons';
import { BottomFixedCard as FixedCard } from '../Cards/FixedCard';
import { PillNumber } from '../Forms/PillNumber';
import { size } from '../Variables/sizes';
import { usePreventDefaultCallback } from '../hooks/preventDefault';
import { DefaultButton } from '../Buttons/primary';
import { ErrorButton } from '../Buttons/error';
import { useMobile } from '../hooks/mobile';
import { formatCartPrice } from '../utils/priceCeil';
import {PrimaryRoundButton} from "../Buttons/RoundButton/roundButtonPrimary";

type CartItemProps = {
  itemPrice: number;
  onAmountChange: (a: any) => any;
  onDelete: (a: any) => any;
  img?: string;
  title?: string;
  labels?: string[];
  restAmount: number;
  amount: number;
  currencySign: string;
  index?: string | number;
  presellLabel?: string;
  doneLabel?: string;
  deleteLabel?: string;
  properties?: {
    setName: string;
    propertyName: string;
  }[];
  inStockLabel: string;
  piecesLabel: string;
  emailDeliveryLabel: string;
  goods_type_id?: number;
  normalized_title: string;
  onItemClick: (alias: string) => void;
  onCloseMenu: (isMobileMenuVisible: boolean) => void;
};

const CartItemComponent = ({
  img,
  title,
  amount,
  labels,
  restAmount,
  itemPrice,
  currencySign,
  onAmountChange,
  onDelete,
  index,
  doneLabel,
  deleteLabel,
  presellLabel,
  properties,
  inStockLabel,
  piecesLabel,
  emailDeliveryLabel,
  goods_type_id,
  normalized_title,
  onItemClick,
  onCloseMenu,
}: CartItemProps) => {
  const [price, setPrice] = useState(itemPrice);
  const [isMobile] = useMobile();
  const [isBottomVisible, setIsBottomVisible] = useState(false);

  // TODO: add format price here
  useEffect(() => {
    setPrice(formatCartPrice(itemPrice * amount) as number);
  }, [setPrice, itemPrice, amount]);

  const handleIncrease = usePreventDefaultCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onAmountChange(amount + 1);
    },
    [amount, onAmountChange]
  );

  const handleDecrease = usePreventDefaultCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (amount !== 1) {
      onAmountChange(amount - 1);
    }
  }, [amount, onAmountChange]);

  const handleDelete = usePreventDefaultCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  const toggleBottomVisible = usePreventDefaultCallback(() => {
    onCloseMenu(isBottomVisible)
    setIsBottomVisible(!isBottomVisible);
  }, [isBottomVisible, setIsBottomVisible]);

  const handleContainerClick = useCallback(() => {
    if (isMobile) {
      setIsBottomVisible(true);
      onCloseMenu(false)
    } else {
      onItemClick(normalized_title)
    }
  }, [setIsBottomVisible, isMobile]);

  const showEmailDelivery = goods_type_id === 2;

  const disabled = React.useMemo(() => {
    return restAmount !== null && amount >= restAmount;
  }, [restAmount, amount]);

  const functionSelection = usePreventDefaultCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (amount !== 1) {
      handleDecrease(e)
    }else{
      handleDelete(e)
    }
  }, [amount, onAmountChange]);


  return (
    <Fragment>
      <Container>
        <CartItemContainer onClick={handleContainerClick}>
          <CartImg img={img} />
          <CartMobileAmount>
            {amount}
            <Cross>x</Cross>
          </CartMobileAmount>
          <CartAmount>
            {amount}
            <Cross>x</Cross>
          </CartAmount>
          <CartDescription>
            {title && <StyledTitle>
                {title}
              </StyledTitle>}
            <CartDetail>
              {labels && labels.map((label, index) => (
                <SpanStyled key={`${index}${label}`}>
                  {label}
                </SpanStyled>
              ))}
            </CartDetail>
            {properties &&
              properties.length > 0 &&
              properties.map((property) => (
                <CartProperty key={property.propertyName}>
                  {`${property.setName}: ${property.propertyName}`}
                </CartProperty>
              ))}
            {showEmailDelivery && (
              <EmailDelivery>
                <EmailDeliveryText>{emailDeliveryLabel}</EmailDeliveryText>
              </EmailDelivery>
            )}
            <CartPrice>
              <B>{price > 0 ? `${price} ${currencySign}` : presellLabel}</B>
            </CartPrice>
            {disabled && (
              <CartRestAmount>
                {`${inStockLabel} ${restAmount} ${piecesLabel}`}
              </CartRestAmount>
            )}
          </CartDescription>
          <ChangeButtons>
            <PrimaryRoundButtonStyled onClick={functionSelection} load={false} Icon={amount !== 1 ? <DecreaseIcon fill="#FFFFFF"/> : <DeleteIcon fill="#FFFFFF"/> }  size="large"/>
            <PrimaryRoundButtonStyled onClick={handleIncrease} load={false} Icon={<IncreaseIcon fill="#FFFFFF"/>} disabled={disabled} size="large"/>
          </ChangeButtons>
        </CartItemContainer>
      </Container>
      {isBottomVisible && (
        <StyledFixedCard overflow onClose={toggleBottomVisible}>
          <P2>{title}</P2>
          <FixedBottomContainer>
            <PillNumber
              value={amount}
              onChange={onAmountChange}
              maximum={disabled}
            ></PillNumber>
            {amount === 0 ? (
              <ErrorButton onClick={handleDelete}>{deleteLabel}</ErrorButton>
            ) : (
              <PrimaryRoundButtonMobile onClick={toggleBottomVisible} load={false}>
                {doneLabel}
              </PrimaryRoundButtonMobile>
            )}
          </FixedBottomContainer>
        </StyledFixedCard>
      )}
    </Fragment>
  );
};

const EmailDeliveryText = styled(P2)`
  font-size: ${props => props.theme.typo.smallSize};
`;

const EmailDelivery = styled('div')`
  background: ${props => props.theme.colors.emailDeliveryItemCard};
  border-radius: 10px;
  margin-top: 5px;
  padding: 3px 10px;
  opacity: 0.5;
  width: fit-content;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10px;
  @media screen and (max-width: ${size.mobile}) {
    margin-bottom: 4px;
    max-width: 100%;
  }
`;

const CartItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding: 20px;
  overflow: hidden;
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  background: ${props => props.theme.colors.light};
  border-radius: 10px;
  box-shadow: 0px 0px 10px ${props => props.theme.colors.button};
  position: relative;
  @media screen and (max-width: ${size.mobile}) {
    max-width: 100%;
    padding: 27px 0;
    border-radius: 0;
  }
`;

const CartImg = styled.div<{
  img?: string;
}>`
  width: 50px;
  height: 50px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(${props => props.img});
  background-size: cover;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`;

const Cross = styled.span`
  margin: 0 10px;
`;

const CartDescription = styled.div`
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  flex: 1;
  margin-top: 0;
  @media screen and (max-width: ${size.mobile}) {
    max-width: 100%;
    flex: 1;
    padding-left: 27px;
  }
`;
const CartDetail = styled(P2)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  & span {
    font-size: 14px;
    line-height: 1.4;
    opacity: 0.5;
  }
  @media screen and (max-width: ${size.mobile}) {
    max-width: 100%;
    flex: 1;
    padding-left: 0;
  }
`;

const CartPrice = styled(P2)`
  padding: 0;
  box-sizing: border-box;
  position: relative;
  margin: 0;
  //margin-top: 10px;
  @media screen and (max-width: ${size.mobile}) {
    flex-basis: 100%;
    text-align: left;
    //margin: 15px 0 0 0;
  }
`;

const StyledTitle = styled.div`
  padding-bottom: 10px;
  @media screen and (max-width: ${size.mobile}) {
    padding-bottom: 15px;
  }
`

const CartRestAmount = styled(P2)`
  padding: 0;
  box-sizing: border-box;
  position: relative;
  margin: 15px 0 0;
  font-size: 14px;
  line-height: 1.4;
  color: ${props => props.theme.colors.error};
`;

const CartProperty = styled(P2)`
  opacity: 0.5;
`;

const CartAmount = styled(P2)`
  padding-left: 20px;
  margin-top: 0;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`;

const CartMobileAmount = styled(P2)`
  width: 60px;
  padding: 0 0 0 27px;
  text-align: center;
  box-sizing: border-box;
  display: none;
  @media screen and (max-width: ${size.mobile}) {
    display: block;
    margin-bottom: 10px;
  }
`;

const ChangeButtons = styled.aside`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  > button:first-of-type::before {
    transform: rotateY(-180deg);
  }
  & > button:first-child{
    border-radius: 10px 0 0 10px;
  }
  & > button:last-child{
    border-radius: 0 10px 10px 0;
  }
`;

const PrimaryRoundButtonStyled = styled(PrimaryRoundButton)`
  margin: 0;
  height: 40px;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`

const PrimaryRoundButtonMobile = styled(PrimaryRoundButton)`
  margin: 0 0 0 9px;
`

const FixedBottomContainer = styled.div`
  display: flex;
  margin-top: 12px;
  ${(DefaultButton as unknown) as CSSObject}, ${ErrorButton} {
    width: auto;
    flex: none;
    margin-left: 9px;
  }
`;

const StyledFixedCard = styled(FixedCard)`
  display: none;
  z-index: 5;
  @media screen and (max-width: ${size.mobile}) {
    display: block;
  }
`;

const SpanStyled = styled.span`
  white-space: break-spaces;
`

export const CartItem = memo(CartItemComponent);

import * as React from 'react';

export const PlasticCardsIcon = (props: any) => {
  return ( 
    <svg {...props} width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M141.053 27.5001H44.2773C40.7178 27.5001 37.832 30.3858 37.832 33.9454V92.388C37.832 95.9475 40.7178 98.8333 44.2773 98.8333H141.053C144.613 98.8333 147.499 95.9475 147.499 92.388V33.9454C147.499 30.3855 144.613 27.5001 141.053 27.5001Z" fill="#CCCCCC" />
    <path d="M147.5 41.8333H37.8335V58.5H147.5V41.8333Z" fill="#4D4D4D" />
    <path d="M105.721 51.1667H8.94531C5.38574 51.1667 2.5 54.0524 2.5 57.612V116.055C2.5 119.614 5.38574 122.5 8.94531 122.5H105.721C109.281 122.5 112.167 119.614 112.167 116.055V57.612C112.167 54.0524 109.281 51.1667 105.721 51.1667Z" fill="#FFCA6C" />
    <path d="M112.167 65.4999H2.5V82.1666H112.167V65.4999Z" fill="#4D4D4D" />
    <path d="M34.1667 113.167C39.6894 113.167 44.1666 108.689 44.1666 103.167C44.1666 97.6438 39.6894 93.1667 34.1667 93.1667C28.6439 93.1667 24.1667 97.6438 24.1667 103.167C24.1667 108.689 28.6439 113.167 34.1667 113.167Z" fill="#47A7DD" />
    <path d="M24.1666 113.167C29.6894 113.167 34.1665 108.689 34.1665 103.167C34.1665 97.6438 29.6894 93.1667 24.1666 93.1667C18.6439 93.1667 14.1667 97.6438 14.1667 103.167C14.1667 108.689 18.6439 113.167 24.1666 113.167Z" fill="#FC583D" />
    <path d="M141.053 24.9999H111.167C109.786 24.9999 108.667 26.1193 108.667 27.4998C108.667 28.8803 109.786 29.9997 111.167 29.9997H141.053C143.23 29.9997 145 31.7704 145 33.9463V39.3328H40.3333V33.9463C40.3333 31.7704 42.104 29.9997 44.2799 29.9997H102.833C104.214 29.9997 105.333 28.8803 105.333 27.4998C105.333 26.1193 104.214 24.9999 102.833 24.9999H44.2799C39.3469 24.9999 35.3332 29.0136 35.3332 33.9466V48.6665H8.94522C4.01309 48.6668 0 52.6796 0 57.612V98.1668C0 99.5476 1.11943 100.667 2.4999 100.667C3.88037 100.667 4.9998 99.5473 4.9998 98.1668V84.6668H109.666V116.055C109.666 118.23 107.896 120 105.721 120H8.94522C6.76992 120 4.9998 118.23 4.9998 116.055V106.5C4.9998 105.119 3.88037 104 2.4999 104C1.11943 104 0 105.119 0 106.5V116.055C0 120.987 4.01309 125 8.94522 125H105.721C110.654 125 114.667 120.987 114.667 116.055V57.612C114.667 52.6796 110.653 48.6668 105.721 48.6668H40.3333V44.3335H145V56.0001H120.167C118.786 56.0001 117.667 57.1195 117.667 58.5C117.667 59.8805 118.786 60.9999 120.167 60.9999H145V92.3865C145 94.5624 143.229 96.3331 141.053 96.3331H120.5C119.119 96.3331 118 97.4525 118 98.833C118 100.214 119.12 101.333 120.5 101.333H141.053C145.987 101.333 150 97.3192 150 92.3862V33.9466C150 29.0136 145.986 24.9999 141.053 24.9999ZM109.667 57.612V63H18.1667C16.7859 63 15.6668 64.1194 15.6668 65.4999C15.6668 66.8807 16.7862 67.9998 18.1667 67.9998H109.667V79.6664H5.0001V67.9998H9.8335C11.2143 67.9998 12.3334 66.8804 12.3334 65.4999C12.3334 64.1191 11.214 63 9.8335 63H5.0001V57.612C5.0001 55.4367 6.77022 53.6666 8.94551 53.6666H105.722C107.897 53.6666 109.667 55.4367 109.667 57.612Z" fill="black" />
    <path d="M29.1696 114.618C30.7358 115.304 32.4377 115.667 34.1665 115.667C41.0592 115.667 46.6666 110.059 46.6666 103.167C46.6666 96.2739 41.0592 90.6665 34.1665 90.6665C32.4374 90.6665 30.7358 91.0295 29.1699 91.7147C27.6365 91.0424 25.9452 90.6665 24.1666 90.6665C17.2739 90.6665 11.6665 96.2739 11.6665 103.167C11.6665 110.059 17.2739 115.667 24.1666 115.667C25.9449 115.667 27.6365 115.291 29.1696 114.618ZM41.6665 103.167C41.6665 107.302 38.3021 110.667 34.1665 110.667C34.1642 110.667 34.1621 110.666 34.1598 110.666C35.7327 108.576 36.6664 105.978 36.6664 103.167C36.6664 100.355 35.7327 97.7572 34.1598 95.6669C34.1621 95.6669 34.1642 95.6666 34.1665 95.6666C38.3023 95.6666 41.6665 99.0311 41.6665 103.167ZM16.6666 103.167C16.6666 99.0311 20.0311 95.6666 24.1666 95.6666C28.3021 95.6666 31.6666 99.0311 31.6666 103.167C31.6666 107.302 28.3021 110.667 24.1666 110.667C20.0311 110.667 16.6666 107.302 16.6666 103.167Z" fill="black" />
    <path d="M100.046 95.4199C101.427 95.4199 102.546 94.3005 102.546 92.92C102.546 91.5395 101.427 90.4201 100.046 90.4201H63.0482C61.6675 90.4201 60.5483 91.5395 60.5483 92.92C60.5483 94.3005 61.6678 95.4199 63.0482 95.4199H100.046Z" fill="black" />
  </svg>
  )
};
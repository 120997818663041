import React from "react";
import styled, { css } from 'styled-components';
import { RoundButton, RoundButtonProps } from "./index";
import { StyledPropsTheme2 } from "Variables";

export const SecondaryRoundButton: React.FC<RoundButtonProps> = ({ children, Icon, IconPosition, ...rest }) => {
  return (
    <SecondaryRoundButtonWrapper {...rest} IconPosition={IconPosition} buttonType="secondary">
      {Icon && <IconContainer IconPosition={IconPosition}>
        {Icon}
      </IconContainer>}
      {children &&
        <Container>
          {children}
        </Container>
      }
    </SecondaryRoundButtonWrapper>
  )
}


const getButtonBackground = (props: RoundButtonProps & StyledPropsTheme2) => {
  const buttonType: string = (props.disabled && 'disabled') || (props.pressed && 'pressed') || (props.focus && 'pressed') || 'undefined'
  switch (buttonType) {
    case 'disabled':
      return css`
              background: ${props.theme.colors.roundButtonSecondary};
              opacity: 0.15;
              & > div{
                pointer-events: none;
              }
              svg{
                pointer-events: none;
              }
            `;
    case 'pressed':
      return css`
              background: ${props => props.theme.colors.roundButtonSecondaryPressed};
              border: 2px solid #000000 !important;
            `;
    case 'focus':
      return css`
              background: ${props => props.theme.colors.roundButtonSecondaryFocus};
              border: 2px solid #000000 !important;
            `;
    default:
      return css`
              background: ${props.theme.colors.roundButtonSecondary};
              &:hover {
                ${(props: RoundButtonProps & StyledPropsTheme2) => {
                  if (props.disabled) {
                    return css`
                      background: ${props.theme.colors.roundButtonSecondary};
                    `
                  } else {
                    return css`
                      background: ${props.theme.colors.roundButtonSecondaryHover};
                      box-shadow: ${props.theme.colors.roundButtonSecondaryBoxShadow};
                    `
                  }
                }}
              }
              &:active {
                border: ${props => `2px solid ${props.theme.colors.roundButtonSecondaryPushed}`};
                box-shadow: none;
              }
            `;
  }
};

const PositionIcon = (props: { IconPosition?: "left" | "right" | "center" }) => {
  switch (props.IconPosition) {
    case 'right':
      return css`
              order: 1;
              margin-left: 10px;
            `;
    case 'center':
      return css`
              order: 0;
              margin-bottom: 10px;
            `;
    case 'left':
      return css`
              order: 0;
              margin-right: 10px;
            `;
    default:
      return css`
              order: 0;
            `;
  }
}


export const IconContainer = styled.div<{ IconPosition?: "left" | "right" | "center" }>`
  ${PositionIcon};
  display: flex;
  align-items: center;
`;


export const SecondaryRoundButtonWrapper = styled(RoundButton) <RoundButtonProps>`
  background: #FFFFFF;
  display: flex;
  flex-direction: ${props => props.IconPosition === 'center' ? 'column' : 'row'};
  color: ${props => props.theme.colors.black};
  ${getButtonBackground};
`;

export const Container = styled.div``;
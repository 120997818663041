import { useCallback } from 'react';

export const useDelayedCallback = (
  func: (arg0?: any) => void,
  dependencies = [],
  delay = 300
) => {
  let timeout: any;
  return useCallback((...params) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...params);
    }, delay);
  }, dependencies);
};

export default useDelayedCallback;

import * as React from 'react';

export const BracketIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0005 17.2894C15.3905 16.8994 15.3905 16.2694 15.0005 15.8794L11.1205 11.9994L15.0005 8.11937C15.3905 7.72937 15.3905 7.09937 15.0005 6.70937C14.6105 6.31937 13.9805 6.31937 13.5905 6.70937L9.00047 11.2994C8.61047 11.6894 8.61047 12.3194 9.00047 12.7094L13.5905 17.2994C13.9705 17.6794 14.6105 17.6794 15.0005 17.2894Z"
        fill="black"
      />
    </svg>
  );
};

import * as React from 'react'

export const ImportTimerIcon = (props: any) => (
    <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.336 1.25h3.333c.458 0 .833.375.833.833a.836.836 0 0 1-.833.834H8.336a.836.836 0 0 1-.834-.834c0-.458.375-.833.834-.833Zm2.5 10a.836.836 0 0 1-.834.833.836.836 0 0 1-.833-.833V7.917c0-.459.375-.834.833-.834.459 0 .834.375.834.834v3.333Zm5.025-4.675.625-.625a.827.827 0 0 0 0-1.167l-.009-.008a.827.827 0 0 0-1.166 0l-.625.625a7.468 7.468 0 0 0-4.684-1.65c-4 0-7.4 3.3-7.5 7.3a7.499 7.499 0 0 0 7.5 7.7 7.495 7.495 0 0 0 5.859-12.175ZM4.169 11.25a5.829 5.829 0 0 0 5.833 5.833 5.829 5.829 0 0 0 5.834-5.833 5.829 5.829 0 0 0-5.834-5.833A5.83 5.83 0 0 0 4.17 11.25Z"
            fill="#000"
            fillOpacity={0.75}
            id="icon/image/timer_24px_2"
        />
    </svg>
);
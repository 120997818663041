import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

export type BProps = {
  children?: React.ReactNode;
};
export const B: FC<BProps> = ({ children, ...props }) => (
  <B1 {...props}>{children}</B1>
);

const B1 = styled.b`
  font-weight: ${props => props.theme.typo.fontSemiBold};
`;

import * as React from "react";

export const StarIcon = (props?: any) => (
    <svg
        width={150}
        height={150}
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="m75 6.513 22.253 45.09 49.759 7.23-36.006 35.096 8.5 49.558L75 120.089l-44.506 23.398 8.5-49.558L2.988 58.833l49.76-7.23L75 6.512Z"
                fill="#FFE289"
            />
            <path
                d="M119.506 146.475a2.98 2.98 0 0 1-1.39-.343L75 123.465l-43.115 22.667a2.99 2.99 0 0 1-4.336-3.15l8.235-48.01-34.881-34a2.988 2.988 0 0 1 1.656-5.097l48.204-7.005L72.321 5.19a2.989 2.989 0 0 1 5.36 0l21.557 43.68 48.204 7.005a2.986 2.986 0 0 1 1.656 5.097l-34.881 34 8.234 48.01a2.986 2.986 0 0 1-2.945 3.493ZM75 117.101c.478 0 .955.115 1.39.343l39.148 20.581-7.477-43.59a2.99 2.99 0 0 1 .86-2.645l31.67-30.871-43.768-6.36a2.988 2.988 0 0 1-2.25-1.634L75 13.265l-19.573 39.66a2.988 2.988 0 0 1-2.25 1.634L9.41 60.919l31.67 30.87a2.987 2.987 0 0 1 .86 2.645l-7.477 43.591 39.147-20.581a2.995 2.995 0 0 1 1.39-.343Z"
                fill="#000"
            />
            <path
                d="M106.969 128.785a2.99 2.99 0 0 1-2.942-2.483l-.284-1.66a2.988 2.988 0 1 1 5.89-1.01l.284 1.66a2.988 2.988 0 0 1-2.948 3.493ZM105.044 117.564a2.988 2.988 0 0 1-2.941-2.483l-2.204-12.849a2.988 2.988 0 1 1 5.89-1.01l2.204 12.849a2.989 2.989 0 0 1-2.949 3.493Z"
                fill="#000"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h150v150H0z" />
            </clipPath>
        </defs>
    </svg>

);

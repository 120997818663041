import * as React from "react";

export const FeedbackEmptyStarIcon = ({large}: {large?: boolean}) => (
  <React.Fragment>
  {
    !large
      ? <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.3333 6.70008L11.3416 6.18341L8.99996 0.666748L6.65829 6.19175L0.666626 6.70008L5.21663 10.6417L3.84996 16.5001L8.99996 13.3917L14.15 16.5001L12.7916 10.6417L17.3333 6.70008ZM8.99996 11.8334L5.86663 13.7251L6.69996 10.1584L3.93329 7.75841L7.58329 7.44175L8.99996 4.08341L10.425 7.45008L14.075 7.76675L11.3083 10.1667L12.1416 13.7334L8.99996 11.8334Z"
          fill="black" />
      </svg>
      : <svg
        width="28"
        height="26"
        viewBox="0 0 28 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27.3332 10.3201L17.7465 9.49341L13.9998 0.666748L10.2532 9.50675L0.666504 10.3201L7.9465 16.6267L5.75984 26.0001L13.9998 21.0267L22.2398 26.0001L20.0665 16.6267L27.3332 10.3201ZM13.9998 18.5334L8.9865 21.5601L10.3198 15.8534L5.89317 12.0134L11.7332 11.5067L13.9998 6.13341L16.2798 11.5201L22.1198 12.0267L17.6932 15.8667L19.0265 21.5734L13.9998 18.5334Z"
          fill="black" />
      </svg>
  }
  </React.Fragment>
);


import * as React from 'react';

export const GlasesIcon = (props: any) => {
  return ( 
    <svg {...props} width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M99.7249 106.358H98.6684C83.0424 106.358 70.3753 93.6914 70.3753 78.0653V68.7908H122.266V83.818C122.266 96.2668 112.174 106.358 99.7249 106.358Z" fill="#2DC6DB"/>
      <path d="M25.5286 106.358H26.585C42.2111 106.358 54.8782 93.6914 54.8782 78.0653V68.7908H2.98783V83.818C2.98783 96.2668 13.0797 106.358 25.5286 106.358Z" fill="#2DC6DB"/>
      <path d="M0 68.7911V83.8184C0 97.8946 11.4521 109.347 25.5287 109.347H26.5852C43.8337 109.347 57.8663 95.3145 57.8663 78.0659V70.1476C60.6741 68.0476 64.5516 68.0203 67.3869 70.0667V78.0659C67.3869 95.3145 81.4198 109.347 98.6681 109.347H99.7245C113.801 109.347 125.253 97.8949 125.253 83.8184V68.7911V68.3877C125.253 56.39 135.014 46.6292 147.012 46.6292C148.662 46.6292 150 45.2915 150 43.6412C150 41.9909 148.662 40.6532 147.012 40.6532C132.591 40.6532 120.707 51.7178 119.399 65.8031H71.6344C66.4614 61.5053 58.902 61.5053 53.729 65.8031H6.13506C7.41797 55.0201 16.6119 46.6295 27.7345 46.6295C29.3848 46.6295 30.7225 45.2918 30.7225 43.6415C30.7225 41.9912 29.3848 40.6535 27.7345 40.6535C12.4415 40.6532 0 53.0944 0 68.3877V68.7911ZM51.8903 78.0656C51.8903 92.0188 40.5384 103.371 26.5852 103.371H25.5287C14.7472 103.371 5.97598 94.5993 5.97598 83.8178V71.7785H51.8903V78.0656ZM119.278 83.8178C119.278 94.5993 110.506 103.371 99.7248 103.371H98.6684C84.7151 103.371 73.3632 92.0186 73.3632 78.0653V71.7788H119.278V83.8178Z" fill="black"/>
      <path d="M12.251 80.6771H27.49C29.1403 80.6771 30.478 79.3394 30.478 77.6891C30.478 76.0388 29.1403 74.7011 27.49 74.7011H12.251C10.6007 74.7011 9.26297 76.0388 9.26297 77.6891C9.26297 79.3394 10.6007 80.6771 12.251 80.6771Z" fill="black"/>
      <path d="M37.0514 80.6771H38.3587C40.0089 80.6771 41.3466 79.3394 41.3466 77.6891C41.3466 76.0388 40.0089 74.7011 38.3587 74.7011H37.0514C35.4011 74.7011 34.0634 76.0388 34.0634 77.6891C34.0634 79.3394 35.4011 80.6771 37.0514 80.6771Z" fill="black"/>
      <path d="M80.2662 80.6771H95.5052C97.1555 80.6771 98.4932 79.3394 98.4932 77.6891C98.4932 76.0388 97.1555 74.7011 95.5052 74.7011H80.2662C78.6159 74.7011 77.2782 76.0388 77.2782 77.6891C77.2782 79.3394 78.6159 80.6771 80.2662 80.6771Z" fill="black"/>
      <path d="M105.067 80.6771H106.375C108.025 80.6771 109.363 79.3394 109.363 77.6891C109.363 76.0388 108.025 74.7011 106.375 74.7011H105.067C103.417 74.7011 102.079 76.0388 102.079 77.6891C102.079 79.3394 103.417 80.6771 105.067 80.6771Z" fill="black"/>
    </svg>
  )
};
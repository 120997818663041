export const imgFormats = [
  '.bmp',
  '.gif',
  'ico',
  '.jpeg',
  '.jpg',
  '.png',
  '.wbmp',
  '.webp',
  '.pkm',
  '.ktx',
  '.astc',
  '.dng',
  '.heif',
  '.svg',
  '.jfif',
];

export const imgSize = 10000000;

export const fileSize = 100000000;

export const limitCountImages = 10;

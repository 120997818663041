import React, {FC} from "react"
import styled, {css} from "styled-components"
import {newEvenLoading, newLoading} from "../Variables/mixins"
import {size} from "../Variables/sizes"


type Props = {
    loading: boolean,
    backgroundOpacity: string,
    mobileVisible?: boolean
    children?: React.ReactNode
};

export const CardComponent: FC<Props> = ({children, loading, backgroundOpacity, mobileVisible, ...rest}) => {
    return(
        <WrapperCard loading={loading} backgroundOpacity={backgroundOpacity} mobileVisible={mobileVisible} {...rest}>
            {children}
        </WrapperCard>
    )
}

export const WrapperCard = styled.div<{
    backgroundOpacity: string;
    mobileVisible?: boolean;
    loading: boolean;
}>`
  width: calc(33.33333% - 20px);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 30px;
  background: ${(props) => `rgba(255, 255, 255, ${props.backgroundOpacity})`};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 22px;
  ${(props) => props.mobileVisible && "cursor: pointer;"}
  &:nth-child(3n - 1) {
    margin-left: 30px;
    margin-right: 30px;
  }
  @media screen and (max-width: ${size.mobile}) {
    max-width: 280px;
    width: 100%;
  }
  ${(props) =>
    props.loading &&
    css`
            position: relative;
            overflow: hidden;
            &:nth-of-type(odd) {
              &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                ${newLoading}
              }
            }
            &:nth-of-type(even) {
              &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                ${newEvenLoading}
              }
            }
          `}
`

import * as React from 'react';
import { useState, useEffect, Fragment, useCallback } from 'react';
import styled, { CSSObject } from 'styled-components';
import { P2 } from '../Text/P2';
import { B } from '../Text/B';
import { IncreaseIcon, DeleteIcon } from '../assets/icons';
import { BottomFixedCard as FixedCard } from '../Cards/FixedCard';
import { size } from '../Variables/sizes';
import { usePreventDefaultCallback } from '../hooks/preventDefault';
import { DefaultButton } from '../Buttons/primary';
import { ErrorButton } from '../Buttons/error';
import { useMobile } from '../hooks/mobile';
import { formatCartPrice } from '../utils/priceCeil';
import {PrimaryRoundButton} from "../Buttons/RoundButton/roundButtonPrimary";

type CartItemProps = {
  itemPrice: number;
  // onAmountChange: (a: any) => any;
  onDelete: (a: any) => any;
  img?: string;
  amount: number;
  title?: string;
  labels?: string[];
  booked: boolean;
  currencySign: string;
  index?: string | number;
  presellLabel?: string;
  doneLabel?: string;
  deleteLabel?: string;
  date?: string;
  time?: string;
  properties?: {
    setName: string;
    propertyName: string;
  }[];
  inStockLabel: string;
  goods_type_id?: number;
  normalized_title: string;
  onItemClick: (alias: string) => void;
  period?: { startDate: string, endDate: string }
  selectedLocationNo: string;
  cartChoose: string;
  isByTheDay: boolean;
  checkIn?: string;
  checkOut?: string;
  checkOutDate?:string;
  onCloseMenu: (isMobileMenuVisible: boolean) => void;
};

export const CartItemBooking = ({
                                  img,
                                  title,
                                  booked,
                                  labels,
                                  amount,
                                  itemPrice,
                                  currencySign,
                                  onDelete,
                                  period,
                                  index,
                                  doneLabel,
                                  deleteLabel,
                                  date,
                                  time,
                                  presellLabel,
                                  normalized_title,
                                  onItemClick,
                                  selectedLocationNo,
                                  cartChoose,
                                  isByTheDay,
                                  checkIn,
                                  checkOut,
                                  checkOutDate,
                                  onCloseMenu,
                                }: CartItemProps) => {
  const [price, setPrice] = useState(itemPrice);
  const [isMobile] = useMobile();
  const [isBottomVisible, setIsBottomVisible] = useState(false);

  // TODO: add format price here
  useEffect(() => {
    setPrice(formatCartPrice(itemPrice * amount) as number);
  }, [setPrice, itemPrice, amount]);

  const handleDelete = usePreventDefaultCallback((e) => {
    e.stopPropagation()
    onDelete(index);
  }, [index, onDelete]);

  const toggleBottomVisible = usePreventDefaultCallback(() => {
    onCloseMenu(isBottomVisible)
    setIsBottomVisible(!isBottomVisible);
  }, [isBottomVisible, setIsBottomVisible]);

  const handleContainerClick = useCallback(() => {
    if (isMobile) {
      setIsBottomVisible(true);
      onCloseMenu(false)
    } else {
      onItemClick(normalized_title)
    }
  }, [setIsBottomVisible, isMobile]);

  return (
      <Fragment>
        <Container>
          <CartItemContainer onClick={handleContainerClick}>
            <CartImg img={img} />
            <CartMobileAmount>
              {amount}
              <Cross>x</Cross>
            </CartMobileAmount>
            <CartAmount>
              {amount}
              <Cross>x</Cross>
            </CartAmount>
            <CartDescription>
              {title}
              <CartDetail>
                {labels && labels.map(label => <SpanStyled>{label}</SpanStyled>)}
              </CartDetail>

              {booked &&
                  <DateContainerBooked>
                    {cartChoose}
                  </DateContainerBooked>
              }

              {date &&
                  <DateContainer>
                    {date} {time && `в ${time}`} {isByTheDay && (`${checkIn && ' с ' + checkIn} ${checkOut && ' по ' + checkOut} ${checkOutDate}`)}
                  </DateContainer>}

              {period &&
                  <DateContainer>
                    c {period.startDate}{checkIn && ' ' + checkIn} по {period.endDate}{checkOut && ' ' + checkOut}
                  </DateContainer>}

              <CartPrice>
                <B>{price > 0 ? `${price} ${currencySign}` : presellLabel}</B>
              </CartPrice>
              {booked && (
                  <CartRestAmount>
                    {selectedLocationNo}
                  </CartRestAmount>
              )}
            </CartDescription>
            <ChangeButtons>
              <PrimaryRoundButtonStyled onClick={handleDelete} load={false} Icon={<DeleteIcon fill="#FFFFFF"/>} size="large"/>
              <PrimaryRoundButtonStyled load={false} Icon={<IncreaseIcon fill="#FFFFFF"/>} disabled={true} size="large"/>
            </ChangeButtons>
          </CartItemContainer>
        </Container>
        {isBottomVisible && (
            <StyledFixedCard overflow onClose={toggleBottomVisible}>
              <P2>{title}</P2>
              <FixedBottomContainer>
                <ErrorButton onClick={handleDelete}>{deleteLabel}</ErrorButton>
                <PrimaryRoundButtonMobile onClick={toggleBottomVisible} load={false}>
                  {doneLabel}
                </PrimaryRoundButtonMobile>

              </FixedBottomContainer>
            </StyledFixedCard>
        )}
      </Fragment>
  );
};



const DateContainer = styled.div`
  color: #3F51B5;
  font-size: 12px;
  line-height: 140%;
  background: rgba(63, 81, 181, 0.1);
  border-radius: 10px;
  padding: 3px 10px;
  text-decoration: underline;
  display: -webkit-inline-box;
`;

const DateContainerBooked = styled.div`
  color: #FF4081;
  font-size: 12px;
  line-height: 140%;
  background: rgba(255, 64, 129, 0.1);
  border-radius: 10px;
  padding: 3px 10px;
  text-decoration: underline;
  display: -webkit-inline-box;
  white-space: break-spaces;
`;



const Container = styled.div`
  display: flex;
  position: relative;
  //max-width: calc(100% - 40px);
  margin-bottom: 10px;
  @media screen and (max-width: ${size.mobile}) {
    margin-bottom: 4px;
    max-width: 100%;
  }
`;

// const DeleteButton = styled.button`
//   appearance: none;
//   background: none;
//   border: none;
//   position: absolute;
//   right: -40px;
//   top: 50%;
//   transform: translateY(-50%);
//   svg path {
//     fill: ${props => props.theme.colors.buttonPushed};
//   }
//   &:hover {
//     svg path {
//       fill: ${props => props.theme.colors.error};
//     }
//   }
//   @media screen and (max-width: ${size.mobile}) {
//     display: none;
//   }
// `;

const CartItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding: 20px;
  overflow: hidden;
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  background: ${props => props.theme.colors.light};
  border-radius: 10px;
  box-shadow: 0px 0px 10px ${props => props.theme.colors.button};
  position: relative;
  @media screen and (max-width: ${size.mobile}) {
    max-width: 100%;
    padding: 27px 0;
    border-radius: 0;
  }
`;

const CartImg = styled.div<{
  img?: string;
}>`
  width: 50px;
  height: 50px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 100%
  ),
  url(${props => props.img});
  background-size: cover;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`;

const Cross = styled.span`
  margin: 0 10px;
`;

const CartDescription = styled(P2)`
  box-sizing: border-box;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  flex: 1;
  margin-top: 0;
  @media screen and (max-width: ${size.mobile}) {
    max-width: 100%;
    flex: 1;
    padding-left: 27px;
  }
`;
const CartDetail = styled(P2)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  & span {
    font-size: 14px;
    line-height: 1.4;
    opacity: 0.5;
  }
  @media screen and (max-width: ${size.mobile}) {
    max-width: 100%;
    flex: 1;
    padding-left: 0;
  }
`;

const CartPrice = styled(P2)`
  padding: 0;
  box-sizing: border-box;
  position: relative;
  margin: 0;
  margin-top: 10px;
  @media screen and (max-width: ${size.mobile}) {
    flex-basis: 100%;
    text-align: left;
    margin: 15px 0 0 0;
  }

  // for what?
  // @media screen and (min-width: ${size.mobile}) {
  //   ${CartItemContainer}:hover & {
  //     b {
  //       display: none;
  //     }
  //   }
  // }
`;

const CartRestAmount = styled(P2)`
  padding: 0;
  box-sizing: border-box;
  position: relative;
  margin: 15px 0 0;
  font-size: 14px;
  line-height: 1.4;
  color: ${props => props.theme.colors.error};
`;


const CartAmount = styled(P2)`
  padding-left: 20px;
  margin-top: 0;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`;

const CartMobileAmount = styled(P2)`
  width: 60px;
  padding: 0 0 0 27px;
  text-align: center;
  box-sizing: border-box;
  display: none;
  @media screen and (max-width: ${size.mobile}) {
    display: block;
    margin-bottom: 10px;
  }
`;

const ChangeButtons = styled.div`
  display: flex;
  //opacity: 0.15;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  > button:first-of-type::before {
    transform: rotateY(-180deg);
  }
  & > button:first-child{
    border-radius: 10px 0 0 10px;
  }
  & > button:last-child{
    border-radius: 0 10px 10px 0;
  }
`;

// const ChangeButton = styled.button`
//   position: relative;
//   width: 50px;
//   pointer-events: none;
// cursor: default;
//   height: 40px;
//   border: none !important;
//   outline: none !important;
//   cursor: pointer;
//   box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1);
//   overflow: hidden;
//   > svg {
//     position: absolute;
//     z-index: 2;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     margin: auto;
//     content: '';
//   }
//   &::before {
//     position: absolute;
//     z-index: 1;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: ${props => props.theme.colors.cartButtonGradient};
//     opacity: 0.25;
//     border-top-right-radius: 10px;
//     border-bottom-right-radius: 10px;
//     content: '';
//   }
//   &:hover::before {
//     opacity: 0.4;
//   }
//   &:disabled {
//     cursor: not-allowed;
//     &::before {
//       background: ${props => props.theme.colors.cartButtonDisabled};
//     }
//   }
//   @media screen and (max-width: ${size.mobile}) {
//     display: none;
//   }
//   @media screen and (min-width: ${size.mobile}) {
//     ${CartItemContainer}:hover & {
//       display: block;
//     }
//   }
// `;

const PrimaryRoundButtonStyled = styled(PrimaryRoundButton)`
  margin: 0;
  height: 40px;
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
`

const PrimaryRoundButtonMobile = styled(PrimaryRoundButton)`
  margin: 0 0 0 9px;
`

const FixedBottomContainer = styled.div`
  display: flex;
  margin-top: 12px;
  ${(DefaultButton as unknown) as CSSObject}, ${ErrorButton} {
    width: auto;
    flex: none;
    margin-left: 9px;
  }
`;

const StyledFixedCard = styled(FixedCard)`
  display: none;
  z-index: 5;
  @media screen and (max-width: ${size.mobile}) {
    display: block;
  }
`;

const SpanStyled = styled.span`
  white-space: break-spaces;
`

// export const CartItemBooking = memo(CartItemBookingComponent);

import * as React from 'react';

export const OkIcon = (props: any) => (
    <svg
        width={18}
        height={27}
        viewBox="0 0 18 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3 7.742c0-1.768-1.49-3.205-3.32-3.205-1.83 0-3.321 1.437-3.321 3.205 0 1.766 1.49 3.204 3.32 3.204 1.832 0 3.321-1.438 3.321-3.204Zm4.7 0c0 4.268-3.597 7.74-8.02 7.74S.96 12.01.96 7.742C.96 3.472 4.557 0 8.98 0S17 3.473 17 7.742Zm-.098 12.197a15.33 15.33 0 0 1-4.657 1.861l4.483 4.328a2.211 2.211 0 0 1 0 3.208 2.408 2.408 0 0 1-3.32 0l-4.409-4.253-4.404 4.253A2.391 2.391 0 0 1 2.932 30c-.6 0-1.201-.222-1.66-.664a2.213 2.213 0 0 1-.001-3.207L5.754 21.8a15.353 15.353 0 0 1-4.657-1.861C0 19.27-.33 17.872.362 16.81c.69-1.061 2.14-1.381 3.239-.713 3.283 1.994 7.512 1.994 10.798 0 1.099-.668 2.549-.348 3.24.713.691 1.06.36 2.46-.737 3.128Z"
            fill="#fff"
        />
    </svg>
);
import * as React from 'react';

export const MaestroIcon = (props: any) => (
  <svg
    {...props}
    width="33"
    height="20"
    viewBox="0 0 33 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.165 10.0249C32.1688 11.9986 31.5859 13.9288 30.4904 15.5704C29.3949 17.2121 27.8361 18.4911 26.0122 19.2449C24.1882 19.9988 22.1813 20.1935 20.2464 19.8044C18.3115 19.4152 16.536 18.4597 15.1453 17.0593C13.7546 15.6589 12.8116 13.8767 12.4359 11.9392C12.0603 10.0016 12.269 7.99615 13.0355 6.17748C13.8021 4.35881 15.0919 2.80903 16.7412 1.72498C18.3904 0.64093 20.3247 0.0715407 22.2982 0.0891412C23.5998 0.0891093 24.8885 0.34659 26.0901 0.846753C27.2917 1.34692 28.3825 2.07988 29.2996 3.00342C30.2167 3.92696 30.942 5.02282 31.4338 6.22789C31.9256 7.43296 32.1741 8.72342 32.165 10.0249Z"
      fill="#E30520"
    />
    <path
      d="M19.911 9.93619C19.9149 11.9098 19.332 13.84 18.2365 15.4817C17.141 17.1233 15.5822 18.4023 13.7582 19.1562C11.9343 19.91 9.92739 20.1048 7.99251 19.7156C6.05764 19.3264 4.28208 18.371 2.8914 16.9706C1.50071 15.5701 0.557652 13.788 0.182001 11.8504C-0.19365 9.91288 0.0150678 7.90741 0.781636 6.08874C1.5482 4.27007 2.83803 2.72028 4.48728 1.63623C6.13653 0.552185 8.07077 -0.0172044 10.0443 0.000396046C11.3459 0.000364157 12.6346 0.257845 13.8362 0.758008C15.0378 1.25817 16.1286 1.99113 17.0457 2.91467C17.9628 3.83821 18.6881 4.93408 19.1799 6.13915C19.6717 7.34422 19.9202 8.63468 19.911 9.93619Z"
      fill="#0081C4"
    />
    <path
      d="M16.1524 2.14154C14.9685 3.05635 14.0081 4.22844 13.3439 5.56915C12.6797 6.90985 12.3291 8.38407 12.3186 9.88024C12.3081 11.3764 12.6381 12.8554 13.2835 14.2052C13.9289 15.5551 14.8728 16.7405 16.0439 17.6718C17.2279 16.757 18.1883 15.5849 18.8525 14.2442C19.5167 12.9035 19.8673 11.4292 19.8777 9.93307C19.8882 8.43689 19.5582 6.95793 18.9128 5.60807C18.2675 4.25821 17.3235 3.07281 16.1524 2.14154Z"
      fill="#0F5787"
    />
  </svg>
);

import React, {FC, memo} from "react";
import styled, {css} from "styled-components";
import {textButton} from "../Variables/mixins";
import {StyledPropsTheme} from "Variables";


export type TooltipProps = {
    className?: string;
    position?: 'top' | 'left' | 'bottom' | 'right';
    children?: React.ReactNode;
};

export const Tooltip: FC<TooltipProps> = memo(({children, ...rest}) => (
    <TooltipContainer {...rest}>
        {children}
    </TooltipContainer>
))

const getPosition = (props: TooltipProps & StyledPropsTheme) => {
    switch (props.position) {
        case 'bottom':
            return css`
              bottom: unset;
              top: calc(100% + 15px);
            `
        case 'left':
            return css`
              right: calc(100% + 15px);
            `
        case 'right':
            return css`
              left: calc(100% + 15px);
            `
        default:
            return css`
              bottom: calc(100% + 15px)
            `
    }
}

const TooltipContainer = styled.div<TooltipProps & StyledPropsTheme>`
  ${textButton};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: cacl(100% + 5px);
  background: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  border-radius: 10px;
  box-sizing: border-box;
  transition: ease-in-out 5s;
  color: #000;
  z-index: 3;
  ${getPosition}
`;

Tooltip.defaultProps = {
    position: 'bottom'
};
import React, { FC } from "react";
import styled, { css } from 'styled-components';
import { RoundButton, RoundButtonProps } from "./index";
import { StyledPropsTheme2 } from "../../Variables";

export const PrimaryRoundButton: React.FC<RoundButtonProps> = ({ children, Icon, IconPosition, ...rest }) => (
  <PrimaryRoundButtonWrapper {...rest} IconPosition={IconPosition} buttonType="primary">
    {Icon && <IconContainer IconPosition={IconPosition}>
      {Icon}
    </IconContainer>}
    {children &&
      <Container>
        {children}
      </Container>
    }
  </PrimaryRoundButtonWrapper>
)

const getButtonBackground = (props: RoundButtonProps & StyledPropsTheme2) => {
  const buttonType: string = (props.disabled && 'disabled') || (props.load && 'load') || (props.pressed && 'pressed') || 'undefined'
  switch (buttonType) {
    case 'load':
      return css``
    case 'disabled':
      return css`
              background: ${props.theme.colors.roundButtonPrimary};
              opacity: 0.15;
              & > div{
                pointer-events: none;
              }
              svg{
                pointer-events: none;
              }
            `;
    case 'pressed':
      return css`
              background: ${props.theme.colors.roundButtonPrimary};
              box-shadow: ${props.theme.colors.roundButtonPrimaryPressed};
            `;
    default:
      return css`
              background: ${props.theme.colors.roundButtonPrimary} ;
              border: 1px solid transparent !important;

              &:hover {
                transition: .3s ease-in-out;
                border: 1px solid ${props.theme.colors.black};

                ${(props: RoundButtonProps & StyledPropsTheme2) => {
          if (props.disabled) {
            return css`
                      background: ${props.theme.colors.roundButtonPrimary};
                    `
          } else {
            return css`
                      background: ${props.theme.colors.roundButtonPrimaryHover};
                      box-shadow: ${props.theme.colors.roundButtonPrimaryBoxShadow};
                    `
          }
        }}
              }
              &:active {
                  background: ${props.theme.colors.roundButtonPrimary};
                  box-shadow: ${props.theme.colors.roundButtonPrimaryPressed};
              }
            `;
  }
};

const PositionIcon = (props: { IconPosition?: "left" | "right" | "center" }) => {
  switch (props.IconPosition) {
    case 'right':
      return css`
              order: 1;
              margin-left: 10px;
            `;
    case 'center':
      return css`
              order: 0;
              margin-bottom: 10px;
            `;
    case 'left':
      return css`
              order: 0;
              margin-right: 10px;
            `;
    default:
      return css`
              order: 0;
            `;
  }
}

export const IconContainer = styled.div<{ IconPosition?: "left" | "right" | "center" }>`
  ${PositionIcon};
  display: flex;
  align-items: center;
`;

const PrimaryRoundButtonWrapper: FC<RoundButtonProps> = styled(RoundButton)`
  display: flex;
  flex-direction: ${props => props.IconPosition === 'center' ? 'column' : 'row'};
  color: ${props => props.theme.colors.white};
  ${getButtonBackground};
`;

export const Container = styled.div``;

import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import 'tippy.js/dist/tippy.css';
import styled from 'styled-components'
import { P2 } from '../Text/P2';


type Props = {
    content: string;
    children: React.ReactElement<any>;
}

export const HoverTooltip = ({
    content,
    children,
}: Props) => {
    const [open, setOpen] = useState(false)


    const Popup = ({ attrs }: any) => {
        return (
            <>
                {open &&
                    <div {...attrs}>
                        <LayoutPopup>
                            <ContentP>{content}</ContentP>
                        </LayoutPopup>
                    </div>
                }
            </>
        )
    }

    return (
        <Tippy
            render={(attrs) => <Popup attrs={attrs} />}
            onMount={() => setOpen(true)}
            onHide={() => setOpen(false)}
        >
            {children}
        </Tippy>
    )
}

const LayoutPopup = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
`
const ContentP = styled(P2)`
    padding: 5px 10px;
`


HoverTooltip.defaultProps = {
    content: 'text',
};


import * as React from 'react';

export const TextIcon = (props?: any) => (
    <svg
        {...props}
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M0 0V3H5.5V15H8.5V3H14V0H0Z" fill="#000" />
    </svg>
);

import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { contentCardStyles } from '../Variables/mixins';

type ContentCardPropType = {
  noPadding?: boolean;
  mobileSharp?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const ContentCard: FC<ContentCardPropType> = ({ children, ...props }) => {
  return <Card {...props}>{children}</Card>;
};

const Card = styled.div`
  ${contentCardStyles};
`;

import React, {FC, memo} from "react";
import styled, {css} from 'styled-components';
import {StyledPropsTheme} from "Variables";
import {size} from "../Variables/sizes";

type ControlPanelProps = {
    children?: any;
    isBooking?: boolean,
}

export const ControlPanel: FC<ControlPanelProps> = memo(({children, ...rest}) => (
    <ControlPanelContainer {...rest}>
        {...children}
    </ControlPanelContainer>
))

const getDirection = (props: ControlPanelProps & StyledPropsTheme) => {
    if (props.isBooking) {
        return css`
          flex-direction: column;
          border-radius: 15px 15px 15px 80px;
          align-items: flex-end;
        `
    } else {
        return css`
          flex-direction: row;
        `
    }
}

const ControlPanelContainer = styled.div`
  background: rgba(159, 159, 159, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 80px;
  box-sizing: border-box;
  min-width: 40px;
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  ${getDirection};
  gap: 10px;
  
  @media screen and (max-width: ${size.mobile}){
    button{
      width: initial;
      height: initial;
    }
  }
`;

import styled from 'styled-components';

export const CrossIcon = styled.span`
  width: 15px;
  height: 15px;
  opacity: 0.54;
  position: relative;
  display: inline-block;
  &:hover {
    opacity: 0.8;
  }
  &:before,
  &:after {
    position: absolute;
    left: 6px;
    content: ' ';
    height: 14px;
    width: 1px;
    background-color: ${props => props.theme.colors.dark};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

import * as React from 'react';
import { useState } from 'react';
import { size } from '../Variables/sizes';
import styled, { css } from 'styled-components';
import { CloseIcon, FileIcon as File } from '../assets/icons';
import { imgFormats, imgSize, limitCountImages } from '../Variables/imgFormats';

type IconFileProps = {
  onChange: (file?: File, position?: number) => any;
  onDeleteIcon?: () => void;
  background?: string;
  fullCover?: boolean;
  small?: boolean;
  whiteIcon?: boolean;
  onError: (err?: any, count?: number[]) => any;
  mobileSharp?: boolean;
  value: any;
  hideFile?: boolean;
  iconFill?: string;
  multiple?: boolean;
  fillesCount?: number;
};

export const IconFile = ({
  onChange,
  background,
  fullCover,
  small,
  whiteIcon,
  onError,
  mobileSharp,
  value,
  hideFile,
  iconFill,
  onDeleteIcon,
  multiple = false,
  fillesCount = 0,
  ...props
}: IconFileProps) => {
  const [file, setFile] = useState<string | null>(null);

  const handleReaderListener = React.useCallback((nestedReader) => {
    setFile(nestedReader.result as string);
  }, [setFile]);


  React.useEffect(() => {
    if (!value) {
      return;
    }
    if (value) {
      const reader = new FileReader();
      reader.addEventListener('load', () => handleReaderListener(reader), false);
      reader.readAsDataURL(value);
    }
  }, [value, handleReaderListener, onError]);

  const handleChangeSingleFile = React.useCallback(
    (file: File, position: number) => {

      let appropriateFormat = false;
      let appropriateSize = false;
      imgFormats.forEach(format => {
        const name = file.name.toLowerCase();
        if (name.indexOf(format) >= 0) {
          appropriateFormat = true;
        }
      });

      if (file.size <= imgSize) {
        appropriateSize = true;
      }
      if (!appropriateFormat) {
        onError('format');
        return;
      }

      if (!appropriateSize) {
        onError('size');
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => handleReaderListener(reader), false);
      reader.readAsDataURL(file)

      onChange(file, position);
    }, [handleReaderListener, onChange, onError])

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()
      const files = e.currentTarget.files;
      if (!files) {
        onError('notFound');
        return;
      }
      if (files.length === 0) {
        return;
      }
      if (multiple) {
        if (files.length > (limitCountImages - fillesCount)) {
          onError('filesCount', [limitCountImages - fillesCount, limitCountImages]);
          return;
        }
        for (let i = 0, file; file = files[i]; i++) {
          handleChangeSingleFile(file, i)
        }
      } else {
        handleChangeSingleFile(files[0], 0)
      }

    }, [onError, handleChangeSingleFile, multiple, fillesCount]
  );

  const handleDeleteIcon = React.useCallback(() => {
    if ((onDeleteIcon && background) || (file && onDeleteIcon)) {
      setFile(null)
      onDeleteIcon()
    }
  }, [onDeleteIcon, background, file])

  // TODO: fix here
  return (
    <Container {...props}>
      <Icon fullCover={fullCover} small={small} mobileSharp={mobileSharp}>
        <Background
          small={small}
          fullCover={fullCover}
          background={(!hideFile && file) || background}
        >
          {(!file || hideFile) && !background && (
            <FileIcon small={small} whiteIcon={whiteIcon} fill={iconFill} />
          )}
        </Background>
        <Input type="file" accept={imgFormats} onChange={handleChange} multiple={multiple} />
        {((onDeleteIcon && background) || (file && onDeleteIcon)) && <RemoveIcon onClick={handleDeleteIcon} small={small} />}
      </Icon>
    </Container>
  );
};

IconFile.defaulProps = {
  onError: () => { },
};

const FileIcon = styled(File)`
  ${props => (props.small ? 'height: 22px; width: 22px;' : '')};

  .iconFill {
    fill: ${props =>
    props.whiteIcon ? (props.fill || props.theme.colors.handlerGray) : 'url(#lgrad)'};
  }
`;

const Background = styled.div<{
  fullCover?: boolean;
  small?: boolean;
  background?: string;
}>`
  width: ${props => {
    if (props.fullCover) return '100%';
    if (props.small) return 'calc(100% - 10px)';
    return 'calc(100% - 20px)';
  }};
  height: ${props => {
    if (props.fullCover) return '100%';
    if (props.small) return 'calc(100% - 10px)';
    return 'calc(100% - 20px)';
  }};
  background-image: ${props => 'url(' + props.background + ')'};
  position: absolute;
  top: ${props => {
    if (props.fullCover) return '0';
    if (props.small) return '5px';
    return '10px';
  }};
  left: ${props => {
    if (props.fullCover) return '0';
    if (props.small) return '5px';
    return '10px';
  }};
  border-radius: ${props => (props.fullCover ? 'inherit;' : '50%;')};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position-x: center;
`;

const Container = styled.div``;

const Icon = styled.div<{
  fullCover?: boolean;
  small?: boolean;
  mobileSharp?: boolean;
}>`
  width: ${props => {
    if (props.fullCover) return '100%';
    if (props.small) return '50px';
    return '100px';
  }};
  height: ${props => {
    if (props.fullCover) return '100%';
    if (props.small) return '50px';
    return '100px';
  }};
  border-radius: ${props => (props.fullCover ? 'inherit;' : '50%;')};
  border: 1px solid ${props =>
    props.fullCover ? 'transparent' : props.theme.colors.buttonBorder};
  background-color: ${props =>
    props.fullCover ? props.theme.colors.light : props.theme.colors.button};
  position: relative;
  @media screen and (max-width: ${size.mobile}) {
    ${props =>
    props.mobileSharp &&
    css`
              border-radius: 0;
            `}
  }
`;

const Input = styled.input<{
  accept?: any;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
`;

const RemoveIcon = styled(CloseIcon) <{ small?: boolean }>`
  position: absolute;
  top: ${({ small }) => !small ? '-10px' : '-15px'};
  right: -10px;
  width: ${({ small }) => !small ? 'initial' : '15px'};
  cursor: pointer;
  fill: rgba(0, 0, 0, 0.54);

  :hover {
    fill: #000000;
  }
`

import React from "react";

export const AnaliticsIcon = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="100"
            fill="none"
            viewBox="0 0 101 100"
            {...props}
        >
            <g clipPath="url(#clip0_13187_135244)">
                <path
                    fill="#FF4081"
                    d="M43.97 78.957l4.108-2.295 2.15 4.301A9.567 9.567 0 0145.85 85.6a9.604 9.604 0 01-7.376.624L24.956 81.89c-4.732-1.516-7.508-6.363-6.56-11.131l4.302-2.15 1.965 4.159a3.266 3.266 0 002.262 2.978l13.519 4.334c.81.26 1.677.184 2.44-.21a3.208 3.208 0 001.087-.913z"
                ></path>
                <path
                    fill="#FF4081"
                    d="M53.136 71.991l-2.518 7.947c-.11.352-.241.694-.39 1.025l-6.258-2.006c.218-.286.383-.609.495-.96l2.518-7.943 6.153 1.937zM27.33 63.72l-2.519 7.947a3.247 3.247 0 00-.148 1.1l-6.267-2.008a9.44 9.44 0 01.262-1.032l2.519-7.946 6.152 1.94zM95.411 11.331V88.67l-9.677-3.101L77.132 50l8.602-35.568 9.677-3.1z"
                ></path>
                <path
                    fill="#FF4081"
                    d="M85.734 14.432v71.136l-21.505-6.895L55.625 50l8.602-28.673 21.506-6.895zM95.411 6.99a3.227 3.227 0 00-3.225 3.225v79.57a3.227 3.227 0 006.451 0v-79.57a3.227 3.227 0 00-3.226-3.226z"
                ></path>
                <path
                    fill="#EEE"
                    d="M64.228 21.327v57.346l-27.956-8.959L27.669 50l8.603-19.714 27.956-8.96z"
                ></path>
                <path
                    fill="#FF4081"
                    d="M36.272 30.286v39.428L8.314 60.753 5.304 50l3.01-10.753 27.958-8.961z"
                ></path>
                <path
                    fill="#FF4081"
                    d="M5.089 36.022a3.227 3.227 0 00-3.226 3.225v21.506a3.227 3.227 0 006.451 0V39.247a3.227 3.227 0 00-3.225-3.225z"
                ></path>
                <path
                    fill="#000"
                    d="M95.411 5.376a4.844 4.844 0 00-4.838 4.839v.974L9.465 37.185a4.844 4.844 0 00-4.376-2.776A4.844 4.844 0 00.25 39.247v21.506a4.844 4.844 0 004.839 4.838c1.93 0 3.6-1.136 4.376-2.776l8.798 2.82-1.143 3.607c-1.867 5.925 1.427 12.288 7.343 14.184l13.519 4.333c1.119.359 2.275.54 3.438.54 1.782 0 3.57-.438 5.172-1.267a11.177 11.177 0 005.564-6.608l1.13-3.564 37.287 11.95v.975a4.844 4.844 0 004.838 4.838 4.844 4.844 0 004.839-4.838v-79.57a4.844 4.844 0 00-4.839-4.839zM5.09 62.366c-.89 0-1.613-.724-1.613-1.613V39.247c0-.89.723-1.613 1.613-1.613s1.613.724 1.613 1.613v21.501c0 .894-.724 1.618-1.613 1.618zM49.08 79.452a7.977 7.977 0 01-3.97 4.715 7.952 7.952 0 01-6.143.52l-13.52-4.332c-4.23-1.356-6.585-5.906-5.25-10.14l1.138-3.595 3.072.985-1.133 3.576a4.882 4.882 0 003.159 6.102l13.519 4.333c.48.154.972.23 1.463.23.76 0 1.515-.183 2.211-.543a4.77 4.77 0 002.377-2.817l1.139-3.595 3.072.985-1.134 3.576zm-22.73-7.3l1.129-3.563 16.59 5.318-1.142 3.606a1.549 1.549 0 01-.783.925c-.385.2-.803.236-1.208.106L27.417 74.21a1.648 1.648 0 01-1.068-2.058zm70.674 17.633c0 .89-.723 1.613-1.613 1.613s-1.613-.724-1.613-1.613v-2.131V31.72a1.613 1.613 0 10-3.225 0v53.703L9.927 59.576V40.424l80.646-25.848V25.27a1.613 1.613 0 103.225 0V12.36v-2.146c0-.89.724-1.613 1.613-1.613.89 0 1.613.724 1.613 1.613v79.57z"
                ></path>
                <path
                    fill="#000"
                    d="M36.272 35.484c-.891 0-1.613.722-1.613 1.613v25.841a1.613 1.613 0 103.225 0V37.097c0-.891-.722-1.613-1.612-1.613zM64.228 26.488c-.89 0-1.612.722-1.612 1.613v43.798a1.613 1.613 0 103.225 0V28.1c0-.89-.722-1.613-1.612-1.613z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_13187_135244">
                    <path
                        fill="#fff"
                        d="M0 0H100V100H0z"
                        transform="translate(.25)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
import React from "react";
import styled from "styled-components";
import { FeedbacksStarIcon, FeedbacksHalfStarIcon, FeedbackEmptyStarIcon } from "..";

type ScoreType = {
  starsNumber: number;
  unpaintedStars: number;
  isCount?: boolean;
  count?: number;
}

export const Score = ({
  starsNumber,
  unpaintedStars,
  isCount,
  count,
}: ScoreType) => {
  const stars = [];

  for (let i = 1; i <= starsNumber; i++) {
    if (i > starsNumber - unpaintedStars) {
      stars.push(<IconWrapper key={i}><FeedbackEmptyStarIcon /> </IconWrapper>)
    } else {
      stars.push(<IconWrapper key={i}><FeedbacksStarIcon color='black' /> </IconWrapper>)
    }
  }
  return (
    <FeedbacksScoreContainer isCount={isCount || false} key={unpaintedStars}>
      <IconContainer>{stars}</IconContainer>
      {isCount
        && <FedbacksCount>
          <StyledSpan>{count}</StyledSpan>
        </FedbacksCount>}
    </FeedbacksScoreContainer>
  )
}

export const AverageScoreComponent = ({
  score,
  maxScore,
}: { score: number, maxScore: number }) => {
  const stars = []
  const [star, halfStar] = score.toString().split('.');
  const emptyStars = maxScore - Number(star) - (Boolean(halfStar) ? 1 : 0);
  for (let i = 0; i < +star; i++) {
    stars.push(<IconWrapper
      key={i}
      data-testid='filled-feedback-star'
    >
      <FeedbacksStarIcon color='black' />
    </IconWrapper>
    )
  }
  if (halfStar) {
    stars.push(<IconWrapper
      key={maxScore + 1}
      data-testid='half-feedback-star'
    >
      <FeedbacksHalfStarIcon />
    </IconWrapper>
    )
  }
  if (emptyStars >= 1) {
    for (let i = 0.5; i < emptyStars; i++) {
      stars.push(<IconWrapper
        key={i}
        data-testid='epmty-feedback-star'
      >
        <FeedbackEmptyStarIcon />
      </IconWrapper>
      )
    }
  }
  return (
    <IconContainer>
      {stars}
    </IconContainer>
  )
}

const StyledSpan = styled.span``

const IconWrapper = styled.div`
  margin-right: 5px;
`

const FeedbacksScoreContainer = styled.div<{ isCount?: boolean }>`
  ${props => props.isCount && "width: 240px;"}
  ${props => props.isCount && "margin-top: 20px;"}
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const FedbacksCount = styled.div`
  color: #000000;
  opacity: 0.5;
  height: 20px;
  margin-left: 10px;
`

import { useEffect, useState, useCallback } from 'react';
import { size } from '../Variables/sizes';

export const useMobile = () => {
  const checkInitialMobile = useCallback(() => {
    if (typeof window !== 'undefined' && window.innerWidth >= size.mobileNum) {
      return false;
    } else {
      return true;
    }
  }, []);
  const [isMobile, setIsMobile] = useState(checkInitialMobile());
  let resizeTimer: any;
  const callback = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      if (window.innerWidth >= size.mobileNum) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }, 150);
  };
  // @ts-ignore
  useEffect(() => {
    if (typeof window !== 'undefined') {
      callback();
      window.addEventListener('resize', callback);
      return () => {
        window.removeEventListener('resize', callback);
      };
    }
  }, []);
  return [isMobile, setIsMobile];
};

import * as React from "react";
import styled from "styled-components";
import { P, PrimaryRoundButton } from "..";
import { size } from '../Variables/sizes';
import { CartEmptyIcon } from '../assets/icons';
import { CardComponent } from './ElementCard'

type EmptyCartCardProps = {
  handleCatalogClick: (a: any) => any;
  loading: boolean;
  loadButton: boolean;
  description: string;
  buttonTitle: string;
};

export const EptyCartCard: React.FC<EmptyCartCardProps> = React.memo(({ handleCatalogClick, loading, description, buttonTitle, loadButton }) => {
  return (
    <Container>
      <CardComponentStyled loading={loading} backgroundOpacity="1">
        <CartEmptyIcon />
        <DescriptionP>
          {description}
        </DescriptionP>
        <Button load={loadButton}  onClick={handleCatalogClick}>
          {buttonTitle}
        </Button>
      </CardComponentStyled>
    </Container>
    );
});

const Button = styled(PrimaryRoundButton)`
  max-width: 160px;
  font-size: 14px;
  margin: 2px;
`

const Container = styled.div`
  max-width: 960px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  @media screen and (max-width: ${size.mobile}) {
    padding: 20px;

  }
`

const CardComponentStyled = styled(CardComponent)`
  margin-top: 0;
`

const DescriptionP = styled(P)`
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
`
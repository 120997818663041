import * as React from "react";
import styled from "styled-components";
import { P2 } from "..";
import { CloudIcon } from "./cardIcons/cloudIcon.icon";
import { ProfileCard } from "./ProfileCard";
import { size } from '../Variables/sizes';

type CloudCardProps = {
    сloudInformationTranslations: {
        maxSizeText: string;
        highlightedText: string; 
        alreadyTakenText: string;
        enlargeText:string;
    }
    enlargeCloud?: (e:any) => void;
    cloudTaken: number;
};

export const CloudCard: React.FC<CloudCardProps> = React.memo(({сloudInformationTranslations, cloudTaken, enlargeCloud }) => {
    return (
        <ProfileCard>
        <ClouInfoWrapper>
          <CloudIconWrapper>
            <CloudIcon/>
          </CloudIconWrapper>
          <CloudInfo>
            <CloudInfoText>{сloudInformationTranslations.maxSizeText}</CloudInfoText>
            <CloudInfoText>{сloudInformationTranslations.highlightedText}</CloudInfoText>
            <CloudInfoText>{сloudInformationTranslations.alreadyTakenText}</CloudInfoText>
            <CloudProgress>
              <CloudTaken width={100} opacity={0.1}></CloudTaken>
             {cloudTaken ? <CloudTaken animate width={cloudTaken} opacity={1}></CloudTaken> : <></>}
            </CloudProgress>
            {enlargeCloud && <EnlargeCloud onClick={enlargeCloud}>+ {сloudInformationTranslations.enlargeText}</EnlargeCloud>}

          </CloudInfo>
        </ClouInfoWrapper>
      </ProfileCard>
    );
});

const CloudTaken = styled.div<{width?:number; animate?: boolean; opacity: number}>`
  position: absolute;
  background: #000000;
  width: ${ props => props.width || 0 }%;
  opacity: ${ props => props.opacity};
  border-radius: 10px;
  height:5px;
  ${ props => props.animate && `animation: translating 1s linear; animation-duration: 1s;`}
  ${ props => props.animate && `
  @keyframes translating {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0%, 0);
  }
}`}
`;

const CloudProgress = styled.div`
  position: relative;
  width: 100%;
  height:5px;
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
`;

const EnlargeCloud = styled(P2)`
  color: #3F51B5;
  cursor: pointer;
`;

const CloudInfo = styled.div`
  @media screen and (max-width: ${size.mobile}) {
    text-align: center;
    padding-top: 10px;
  }
  `;

const CloudInfoText = styled(P2)``;

const CloudIconWrapper = styled.div`
  margin-right: 30px;
  @media screen and (max-width: ${size.mobile}) {
    margin: 0 auto;
  }
`;

const ClouInfoWrapper = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${size.mobile}) {
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  }
`;

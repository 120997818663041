import * as React from 'react';

export const HeartIcon = ((props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            fill="none"
            viewBox="0 0 22 18"
            {...props}
        >
            <path
                d="M12.35 17.307c-.76.69-1.93.69-2.69-.01l-.11-.1C4.3 12.447.87 9.337 1 5.457c.06-1.7.93-3.33 2.34-4.29 2.64-1.8 5.9-.96 7.66 1.1 1.76-2.06 5.02-2.91 7.66-1.1 1.41.96 2.28 2.59 2.34 4.29.14 3.88-3.3 6.99-8.55 11.76l-.1.09z"
                className="fill"
            />
            <path
                className="border"
                fill="#000"
                fillRule="evenodd"
                d="M11 2.267c1.76-2.06 5.02-2.9 7.66-1.1 1.4.96 2.28 2.58 2.34 4.28.13 3.88-3.3 6.99-8.55 11.75l-.11.1c-.76.7-1.93.7-2.69.01l-.1-.09-.06-.054C4.274 12.423.86 9.323 1 5.457c.06-1.71.94-3.33 2.34-4.29 2.64-1.81 5.9-.96 7.66 1.1zm0 13.56l.1-.1c4.76-4.31 7.9-7.16 7.9-10.05 0-2-1.5-3.5-3.5-3.5-1.54 0-3.04.99-3.56 2.36h-1.87c-.53-1.37-2.03-2.36-3.57-2.36-2 0-3.5 1.5-3.5 3.5 0 2.89 3.14 5.74 7.9 10.05l.1.1z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
});


import { useState, useEffect } from 'react';

export const useMapped = (
  values: any,
  mapper = (_a?: any) => {},
  defaultState: any
) => {
  const [mappedData, setMappedData] = useState(defaultState);

  useEffect(() => {
    setMappedData(mapper(values));
  }, [values, setMappedData]);

  return [mappedData, setMappedData];
};

import * as React from 'react';

export const CartEmptyIcon = ((props: any) => {
    return (
        <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M142.114 136.745H7.88584C4.92041 136.745 2.5166 134.341 2.5166 131.376V18.624C2.5166 15.6586 4.92041 13.2548 7.88584 13.2548H142.114C145.08 13.2548 147.483 15.6586 147.483 18.624V131.376C147.483 134.341 145.08 136.745 142.114 136.745Z" fill="#E1F5FE"/>
            <path d="M74.9999 128.691C98.7222 128.691 117.953 109.46 117.953 85.7382C117.953 62.0159 98.7222 42.7852 74.9999 42.7852C51.2776 42.7852 32.0469 62.0159 32.0469 85.7382C32.0469 109.46 51.2776 128.691 74.9999 128.691Z" fill="white"/>
            <path d="M34.7314 13.2551H142.114C145.079 13.2551 147.483 15.6589 147.483 18.6243V23.9936H34.7314V13.2551Z" fill="#E1F5FE"/>
            <path d="M7.88579 13.2551H13.255V23.9933H2.51685V18.624C2.51685 15.6586 4.92036 13.2551 7.88579 13.2551Z" fill="#FF4081"/>
            <path d="M23.9933 13.2551H13.2551V23.9933H23.9933V13.2551Z" fill="#FFE289"/>
            <path d="M34.7313 13.2551H23.9932V23.9933H34.7313V13.2551Z" fill="#7CB342"/>
            <path d="M136.745 13.2551H142.114C145.08 13.2551 147.483 15.6589 147.483 18.6243V23.9936H136.745V13.2551H136.745Z" fill="#E1F5FE"/>
            <path d="M53.5235 117.953C56.4889 117.953 58.8928 115.549 58.8928 112.584C58.8928 109.618 56.4889 107.215 53.5235 107.215C50.5582 107.215 48.1543 109.618 48.1543 112.584C48.1543 115.549 50.5582 117.953 53.5235 117.953Z" fill="black"/>
            <path d="M99.161 117.953C102.126 117.953 104.53 115.549 104.53 112.584C104.53 109.618 102.126 107.215 99.161 107.215C96.1956 107.215 93.7917 109.618 93.7917 112.584C93.7917 115.549 96.1956 117.953 99.161 117.953Z" fill="black"/>
            <path d="M49.4968 66.9463L60.0891 95.1929C60.9619 97.5196 63.3167 98.9435 65.7824 98.6355L99.8271 94.3799C102.514 94.0438 104.53 91.7599 104.53 89.0522V66.9463H49.4968Z" fill="#E1F5FE"/>
            <path d="M142.114 10.7382H7.88584C3.5376 10.7382 0 14.2758 0 18.624V131.376C0 135.724 3.5376 139.262 7.88584 139.262H142.114C146.462 139.262 150 135.724 150 131.376V18.624C150 14.2758 146.462 10.7382 142.114 10.7382ZM144.967 18.624V21.4764H139.262V15.7717H142.114C143.687 15.7717 144.967 17.0514 144.967 18.624ZM37.2483 21.4764V15.7717H134.228V21.4764H37.2483ZM26.5102 21.4764V15.7717H32.2148V21.4764H26.5102ZM15.7717 21.4764V15.7717H21.4764V21.4764H15.7717ZM7.88584 15.7717H10.7382V21.4764H5.0335V18.624C5.0335 17.0514 6.31318 15.7717 7.88584 15.7717ZM142.114 134.228H7.88584C6.31289 134.228 5.0335 132.949 5.0335 131.376V26.5102H144.967V131.376C144.967 132.949 143.687 134.228 142.114 134.228Z" fill="black"/>
            <path d="M136.745 32.2148H13.2552C11.8653 32.2148 10.7383 33.3416 10.7383 34.7317C10.7383 36.1216 11.865 37.2486 13.2552 37.2486H136.745C138.135 37.2486 139.262 36.1219 139.262 34.7317C139.262 33.3416 138.135 32.2148 136.745 32.2148Z" fill="black"/>
            <path d="M104.53 64.4294H88.4227C87.0328 64.4294 85.9058 65.5562 85.9058 66.9463C85.9058 68.3361 87.0325 69.4632 88.4227 69.4632H102.013V89.0522C102.013 90.4878 100.939 91.7045 99.5145 91.8823L65.4697 96.138C64.1537 96.3023 62.9106 95.5503 62.4451 94.309L53.1275 69.4629H66.9457C68.3355 69.4629 69.4626 68.3361 69.4626 66.946C69.4626 65.5562 68.3358 64.4291 66.9457 64.4291H51.2399L49.132 58.8079C47.9845 55.7473 45.017 53.6909 41.7483 53.6909H37.4153C36.0255 53.6909 34.8984 54.8177 34.8984 56.2078C34.8984 57.5977 36.0252 58.7247 37.4153 58.7247H41.7483C42.9305 58.7247 44.0036 59.4686 44.4187 60.5757L57.7315 96.0768C58.0576 96.9466 58.5258 97.7253 59.0968 98.4021L55.2091 104.882C54.6653 104.763 54.1014 104.698 53.5225 104.698C49.1742 104.698 45.6366 108.236 45.6366 112.584C45.6366 116.932 49.1742 120.47 53.5225 120.47C56.9909 120.47 59.9411 118.218 60.9943 115.1H91.6887C92.7416 118.218 95.6921 120.47 99.1605 120.47C103.509 120.47 107.046 116.932 107.046 112.584C107.046 108.236 103.509 104.698 99.1605 104.698C95.6921 104.698 92.7419 106.95 91.6887 110.067H60.9943C60.6703 109.107 60.1664 108.23 59.5228 107.475L63.4034 101.007C63.9542 101.129 64.5217 101.195 65.1 101.195C65.4281 101.195 65.7604 101.174 66.0932 101.133L100.138 96.8772C104.077 96.385 107.046 93.0208 107.046 89.0519V66.9463C107.047 65.5564 105.92 64.4294 104.53 64.4294ZM53.5236 115.436C51.9507 115.436 50.6713 114.157 50.6713 112.584C50.6713 111.011 51.9507 109.731 53.5236 109.731C55.0966 109.731 56.376 111.011 56.376 112.584C56.376 114.157 55.0963 115.436 53.5236 115.436ZM99.1611 109.731C100.734 109.731 102.013 111.011 102.013 112.584C102.013 114.157 100.734 115.436 99.1611 115.436C97.5882 115.436 96.3088 114.157 96.3088 112.584C96.3088 111.011 97.5882 109.731 99.1611 109.731Z" fill="black"/>
            <path d="M77.6844 48.3223C76.2946 48.3223 75.1675 49.449 75.1675 50.8392V79.6626L71.4102 75.9053C70.4273 74.9224 68.8338 74.9224 67.8512 75.9053C66.8683 76.8882 66.8683 78.4813 67.8512 79.4643L75.9049 87.518C76.3962 88.0093 77.0405 88.2551 77.6844 88.2551C78.3283 88.2551 78.9726 88.0096 79.4639 87.518L87.5176 79.4643C88.5005 78.4813 88.5005 76.8882 87.5176 75.9053C86.5347 74.9224 84.9412 74.9224 83.9586 75.9053L80.2013 79.6626V50.8389C80.2013 49.449 79.0745 48.3223 77.6844 48.3223Z" fill="black"/>
        </svg>
    )
});
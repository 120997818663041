import * as React from 'react';
import styled from 'styled-components';

import { size } from '../Variables/sizes';
import { Checkbox } from '../Forms/checkbox';
import { P2 } from '../Text/P2';

type CheckboxCardProps = {
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  hasCheckbox?: boolean;
  title?: string;
  url?: string;
  label?: string;
};

export const CheckboxCard = ({
  checked,
  title,
  url,
  onChange,
  label,
  hasCheckbox,
  ...props
}: CheckboxCardProps) => {
  return (
    <Container checked={checked || !hasCheckbox} {...props}>
      {url && <Background src={url} />}
      {!url && <Title>{title}</Title>}
      {hasCheckbox && onChange && <CheckboxStyled checked={checked} onChange={onChange} />}
    </Container>
  );
};

CheckboxCard.defaultProps = {
  hasCheckbox: true,
};

const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border: none;
  object-fit: cover;
`;

const Container = styled.div<{
  checked?: boolean;
}>`
  background-color: ${props => props.theme.colors.button};
  display: flex;
  flex-wrap: wrap;
  width: calc(33.333333333333333% - 14.33px);
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5px;
  margin-bottom: 20px;
  opacity: ${props => (props.checked ? 1 : 0.7)};
  cursor: pointer;
  overflow: hidden;
  &:hover {
    opacity: 1;
  }
  &:nth-child(3n - 1) {
    margin-right: 20px;
    margin-left: 20px;
  }
  &:before {
    content: '';
    float: left;
    padding-top: 100%;
  }
  @media screen and (max-width: ${size.mobile}) {
    width: calc(50% - 10px);
    &:nth-child(odd) {
      margin-left: 0;
      margin-right: 10px;
    }
    &:nth-child(even) {
      margin-left: 10px;
      margin-right: 0;
    }
  }
`;

const CheckboxStyled = styled(Checkbox)`
  margin: 10px;
  position: static;
  width: 30px;
  height: 30px;
  z-index: 2;
`;

const Title = styled(P2)`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 60px 10px 10px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

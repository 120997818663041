import * as React from 'react';

export const HouseIcon = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0L0 6V18H5V11H11V18H16V6L8 0Z"
                fill={props.fill || '#FFFFFF'}
            />
        </svg>
    );
};


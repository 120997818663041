import React from "react";
import styled from "styled-components";
import { size } from '../Variables/sizes';
import { CardComponent } from '../Cards/ElementCard';
import { P, PrimaryRoundButton } from "..";
import { EditIcon, StarIcon } from "../assets/icons";

type EmptyFeedbacksCardType = {
  cardText: string;
  cardTitle: string;
  buttonText: string;
  onButtonClick: (a: any) => void;
}

export const EmptyFeedbacksCard = ({
  cardText,
  cardTitle,
  buttonText,
  onButtonClick,
}: EmptyFeedbacksCardType) => {
  return (
    <EmptyCardContainer data-testid='empty-feedback-card'>
      <CardComponentStyled loading={false} backgroundOpacity="0">
        <IconWrapper>
          <StarIcon />
        </IconWrapper>
        <CardContent>
          <CardTitle>
            {cardTitle}
          </CardTitle>
          <DescriptionGoodText>
            {cardText}
          </DescriptionGoodText>
          <PrimaryRoundButton Icon={<EditIcon/>} load={false} onClick={onButtonClick}>
            {buttonText}
          </PrimaryRoundButton>
        </CardContent>
      </CardComponentStyled>
    </EmptyCardContainer>
  )
}

const EmptyCardContainer = styled.div`
  width: 100%;
`;

const CardComponentStyled = styled(CardComponent)`
  display: flex;
  flex-direction: row;
  width: 600px;
  background-color: white;
  padding: 40px;
  margin: 0 auto;
  margin-top: 30px;
  @media screen and (max-width: ${size.mobile}) {
    flex-direction: column;
    padding: 20px;
  }
`

const IconWrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: ${size.mobile}) {
    padding: 0;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  @media screen and (max-width: ${size.mobile}) {
    margin: 0 auto;
  }
`

const DescriptionGoodText = styled(P)`
  font-size: 14px;
  margin: 20px 0;
  @media screen and (max-width: ${size.mobile}) {
    font-size: 12px;
    margin: 20px 0;
  }
`;

const CardTitle = styled.div`
  font-family: "Montserrat",sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
  @media screen and (max-width: ${size.mobile}) {
    text-align: center;
    margin-top: 20px;
  }
`
import * as React from 'react';

export const ArrowLeftIcon = ({ ...props }) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.625577 7.29289C0.235052 7.68342 0.235052 8.31658 0.625577 8.70711L6.98954 15.0711C7.38006 15.4616 8.01323 15.4616 8.40375 15.0711C8.79428 14.6805 8.79428 14.0474 8.40375 13.6569L2.7469 8L8.40375 2.34315C8.79428 1.95262 8.79428 1.31946 8.40375 0.928932C8.01323 0.538408 7.38006 0.538408 6.98954 0.928932L0.625577 7.29289ZM14.666 7L1.33268 7V9L14.666 9V7Z"
        fill={props.fill || '#FFFFFF'}
      />
    </svg>
  );
};

import * as React from 'react';

type Props = {
  fill?: any;
  opacity?: any;
};

export const CheckboxIcon = ({ fill, opacity, ...props }: Props) => {
  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 10 9"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={opacity || 0.25}
        d="M1 5.55L3.58065 8L9 1"
        stroke={fill || 'black'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

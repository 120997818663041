import * as React from 'react';

export const SettingsMenuIcon = (props?: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5018 10C17.5018 10.34 17.4718 10.66 17.4318 10.98L19.5418 12.63C19.7318 12.78 19.7818 13.05 19.6618 13.27L17.6618 16.73C17.5418 16.95 17.2818 17.04 17.0518 16.95L14.5618 15.95C14.0418 16.34 13.4818 16.68 12.8718 16.93L12.4918 19.58C12.4618 19.82 12.2518 20 12.0018 20H8.00179C7.75179 20 7.54179 19.82 7.51179 19.58L7.13179 16.93C6.52179 16.68 5.96179 16.35 5.44179 15.95L2.95179 16.95C2.73179 17.03 2.46179 16.95 2.34179 16.73L0.341793 13.27C0.221793 13.05 0.271793 12.78 0.461793 12.63L2.57179 10.98C2.53179 10.66 2.50179 10.33 2.50179 10C2.50179 9.67 2.53179 9.34 2.57179 9.02L0.461793 7.37C0.271793 7.22 0.211793 6.95 0.341793 6.73L2.34179 3.27C2.46179 3.05 2.72179 2.96 2.95179 3.05L5.44179 4.05C5.96179 3.66 6.52179 3.32 7.13179 3.07L7.51179 0.42C7.54179 0.18 7.75179 0 8.00179 0H12.0018C12.2518 0 12.4618 0.18 12.4918 0.42L12.8718 3.07C13.4818 3.32 14.0418 3.65 14.5618 4.05L17.0518 3.05C17.2718 2.97 17.5418 3.05 17.6618 3.27L19.6618 6.73C19.7818 6.95 19.7318 7.22 19.5418 7.37L17.4318 9.02C17.4718 9.34 17.5018 9.66 17.5018 10ZM6.50179 10C6.50179 11.93 8.07179 13.5 10.0018 13.5C11.9318 13.5 13.5018 11.93 13.5018 10C13.5018 8.07 11.9318 6.5 10.0018 6.5C8.07179 6.5 6.50179 8.07 6.50179 10Z"
      fill={props.fill || '#FFFFFF'}
    />
  </svg>
);

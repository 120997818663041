import * as React from 'react';
import styled from 'styled-components';

import { PopupContainer, PopupBackground } from './Popup';
import { PopupScrollContainer } from './PopupScrollContainer';
import { size } from '../Variables/sizes';

import { CrossIcon } from '../assets/icons/cross.icon';
import { usePreventDefaultCallback } from '../hooks/preventDefault';

type Props = {
  noPadding?: boolean;
  noCross?: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

export const PopupWide: React.FC<Props> = React.memo(
  ({ children, noPadding, onClose, noCross }) => {
    const handleCrossClick = usePreventDefaultCallback(onClose, [onClose]);
    return (
      <PopupScrollContainer fullWidth hasScroll>
        <PopupBackground onClick={onClose} />
        <StyledPopupContainer wide noPadding={noPadding} hasScroll>
          {!noCross && (
            <Cross onClick={handleCrossClick}>
              <CrossIcon />
            </Cross>
          )}
          {children}
        </StyledPopupContainer>
      </PopupScrollContainer>
    );
  }
);

const StyledPopupContainer = styled(PopupContainer)<{ noPadding?: boolean }>`
  color: ${props => props.theme.colors.dark};
  text-align: left;
  padding: ${props => (props.noPadding ? '0' : '30px')};
  @media screen and (max-width: ${size.mobile}) {
    border-radius: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow: auto;
  }
`;

const Cross = styled.a`
  cursor: pointer;
  width: 80px;
  height: 80px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  top: 0;
  align-items: center;
`;

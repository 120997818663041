import React from "react";

export const OrderFormIcon = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="100"
            fill="none"
            viewBox="0 0 101 100"
            {...props}
        >
            <g clipPath="url(#clip0_13187_134942)">
                <path
                    fill="#E6E6E6"
                    d="M59.092 9.68H1.945l24.847 80.64h57.147L59.092 9.68z"
                ></path>
                <path
                    fill="#FF4081"
                    d="M82.553 43.563c-6.8 6.8-13.986 11.824-19.886 14.293l-4.431-4.432-6.587-6.586c2.469-5.9 7.492-13.085 14.294-19.886 10.146-10.145 21.15-16.34 27.457-15.988 1.534.083 2.792.556 3.69 1.452 4.584 4.588-1.924 18.533-14.537 31.146z"
                ></path>
                <path
                    fill="#000"
                    d="M93.4 10.964s-33.28 21.828-35.165 42.46l-6.586-6.586c2.469-5.9 7.492-13.086 14.293-19.887C76.09 16.807 87.094 10.611 93.4 10.964z"
                    opacity="0.25"
                ></path>
                <path
                    fill="#fff"
                    d="M62.667 57.856l-4.532 1.751a9.917 9.917 0 00-5.256 4.737l-1.95 3.815-10.542.959.959-10.542 3.815-1.95a9.916 9.916 0 004.737-5.256l1.75-4.532"
                ></path>
                <path
                    fill="#000"
                    d="M98.286 11.22c-2.76-2.76-7.901-2.59-14.477.479-.26.12-.523.252-.786.38l-5.538-1.466c-4.916-1.302-10.12 1.063-12.372 5.624l-1.506 3.05a15.177 15.177 0 01-11.757 8.364 1.694 1.694 0 00.42 3.362 18.555 18.555 0 0014.375-10.226l1.506-3.05a7.367 7.367 0 018.466-3.85l2.142.568c-4.633 2.867-9.465 6.749-14.014 11.298-6.676 6.676-12.019 14.122-14.66 20.43-.001.006-.002.011-.005.017l-.011.027-1.751 4.532a8.213 8.213 0 01-3.928 4.358l-3.815 1.95a1.694 1.694 0 00-.916 1.355L38.7 68.964a1.693 1.693 0 001.84 1.84l10.542-.958a1.694 1.694 0 001.356-.916l1.95-3.815a8.213 8.213 0 014.358-3.928l4.532-1.751.022-.01.022-.008c6.308-2.64 13.754-7.983 20.43-14.658 1.579-1.58 3.1-3.216 4.52-4.864a1.694 1.694 0 00-2.567-2.212 86.265 86.265 0 01-4.349 4.68c-6.008 6.008-12.616 10.863-18.309 13.475l-9.381-9.381c2.612-5.693 7.467-12.3 13.475-18.309 5.16-5.16 10.665-9.408 15.752-12.186l10.65 10.65c-1.12 2.049-2.501 4.2-4.117 6.404a1.694 1.694 0 002.732 2.004c3.568-4.866 6.091-9.56 7.296-13.573 1.729-5.76.319-8.742-1.168-10.229zM59.63 57.213l-2.104.813c-2.668 1.03-4.853 3-6.155 5.546l-1.524 2.984-4.952.45 1.387-1.387a2.927 2.927 0 002.572-.814 2.94 2.94 0 000-4.153 2.917 2.917 0 00-2.077-.86 2.933 2.933 0 00-2.89 3.432l-1.388 1.387.45-4.952 2.984-1.525a11.597 11.597 0 005.546-6.154l.813-2.105 7.338 7.338zm36.58-36.74a26.87 26.87 0 01-1.084 2.93l-9.022-9.022c4.628-2.007 8.252-2.302 9.787-.767 1.18 1.179 1.292 3.615.318 6.86z"
                ></path>
                <path
                    fill="#000"
                    d="M9.688 34.812a1.694 1.694 0 001.12-2.118l-6.57-21.32h53.603l1.77 5.749a1.694 1.694 0 103.239-.998l-2.14-6.943a1.694 1.694 0 00-1.619-1.195H1.944A1.694 1.694 0 00.325 10.18L7.57 33.69a1.695 1.695 0 002.118 1.12zM61.773 88.625H28.042L12.48 38.121a1.694 1.694 0 00-3.238.998l15.93 51.699c.219.71.875 1.195 1.619 1.195h34.982a1.694 1.694 0 100-3.388zM75.524 57.261a1.694 1.694 0 10-3.238.998l9.357 30.366H67.42a1.694 1.694 0 000 3.388h16.52a1.694 1.694 0 001.618-2.193L75.524 57.261z"
                ></path>
                <path
                    fill="#000"
                    d="M18.743 22.556c0-.936-.759-1.694-1.694-1.694h-2.013a1.694 1.694 0 000 3.388h2.013c.935 0 1.694-.759 1.694-1.694zM55.629 22.556c0-.936-.758-1.694-1.694-1.694h-31.24a1.694 1.694 0 000 3.388h31.24c.935 0 1.694-.759 1.694-1.694zM17.487 32.208h11.082a1.694 1.694 0 000-3.389H17.487a1.694 1.694 0 000 3.389zM46.413 32.208a1.694 1.694 0 000-3.389H34.215a1.694 1.694 0 000 3.389h12.198zM50.818 38.469c0-.936-.759-1.694-1.695-1.694H19.94a1.694 1.694 0 000 3.388h29.183c.936 0 1.695-.758 1.695-1.694zM36.361 46.427c0-.936-.758-1.695-1.694-1.695H22.391a1.694 1.694 0 000 3.389h12.276c.936 0 1.694-.759 1.694-1.694zM29.024 73.29l3.05 2.71a1.69 1.69 0 002.3-.045l2.936-2.823a1.694 1.694 0 10-2.349-2.443l-1.807 1.738-1.879-1.67a1.694 1.694 0 00-2.251 2.532z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_13187_134942">
                    <path
                        fill="#fff"
                        d="M0 0H100V100H0z"
                        transform="translate(.25)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
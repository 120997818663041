import * as React from 'react';

export const AccountIcon = (props?: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 3C11.66 3 13 4.34 13 6C13 7.66 11.66 9 10 9C8.34 9 7 7.66 7 6C7 4.34 8.34 3 10 3ZM4 13.98C5.29 15.92 7.5 17.2 10 17.2C12.5 17.2 14.71 15.92 16 13.98C15.97 11.99 11.99 10.9 10 10.9C8 10.9 4.03 11.99 4 13.98Z"
      fill={props.fill || '#FFFFFF'}
    />
  </svg>
);

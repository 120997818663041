import * as React from "react";

export const FeedbacksStarIcon = ({large, color}: {large?: boolean, color: string}) => (
  <React.Fragment>
    {!large
      ? <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.99999 13.3917L14.15 16.5001L12.7833 10.6417L17.3333 6.70008L11.3417 6.19175L8.99999 0.666748L6.65832 6.19175L0.666656 6.70008L5.21666 10.6417L3.84999 16.5001L8.99999 13.3917Z"
          fill={color} />
      </svg>
      : <svg
        width="28"
        height="26"
        viewBox="0 0 28 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.9998 21.0267L22.2398 26.0001L20.0532 16.6267L27.3332 10.3201L17.7465 9.50675L13.9998 0.666748L10.2532 9.50675L0.666504 10.3201L7.9465 16.6267L5.75984 26.0001L13.9998 21.0267Z"
          fill={color} />
      </svg>
    }
  </React.Fragment>
);

import * as React from "react";

export const SoldIcon = () => (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56.2249 43.6154C54.497 43.6154 53.0959 42.2147 53.0959 40.4843V27.9689C53.0959 26.2386 54.4967 24.8379 56.2249 24.8379C57.9526 24.8379 59.3536 26.2386 59.3536 27.9689V40.4843C59.3536 42.2147 57.9526 43.6154 56.2249 43.6154Z" fill="#434A54"/>
    <path d="M125.066 43.6154C123.34 43.6154 121.939 42.2147 121.939 40.4843V27.9689C121.939 26.2386 123.34 24.8379 125.066 24.8379C126.792 24.8379 128.192 26.2386 128.192 27.9689V40.4843C128.193 42.2147 126.791 43.6154 125.066 43.6154Z" fill="#434A54"/>
    <path d="M31.1922 150H18.6771C16.9491 150 15.5481 148.599 15.5481 146.874V3.13105C15.5481 1.40541 16.9488 0 18.6771 0H31.1922C32.9202 0 34.3235 1.40541 34.3235 3.13105V146.874C34.3233 148.599 32.9202 150 31.1922 150Z" fill="#F6BB42"/>
    <path d="M143.838 12.3232H6.16162C4.43129 12.3232 3.03027 13.724 3.03027 15.4543V27.9694C3.03027 29.695 4.431 31.0958 6.16162 31.0958H143.839C145.569 31.0958 146.97 29.695 146.97 27.9694V15.4543C146.969 13.724 145.569 12.3232 143.838 12.3232Z" fill="#FFCE54"/>
    <path d="M27.1479 19.4958C28.3699 20.7178 28.3699 22.7001 27.1479 23.9224C25.9256 25.1447 23.9433 25.1447 22.7234 23.9224C21.5011 22.7001 21.5011 20.7178 22.7234 19.4958C23.9433 18.2735 25.9256 18.2735 27.1479 19.4958Z" fill="#434A54"/>
    <path d="M140.709 37.3525H40.5811V124.967H140.709V37.3525Z" fill="#ED5564"/>
    <path d="M64.0319 78.9368C63.2903 78.7399 60.2736 78.3189 60.2736 77.2293C60.2736 76.5382 60.9166 76.1398 62.0543 76.1398C63.4392 76.1398 64.2791 76.7577 64.3043 77.8244H69.3743C69.3239 74.1897 66.7284 72.1113 62.2283 72.1113C57.9754 72.1113 55.1054 74.3133 55.1054 77.6275C55.1054 80.6443 56.9365 81.6331 59.4585 82.4983C60.6694 82.9149 63.7114 83.6611 64.4277 84.2514C64.6498 84.4483 64.7734 84.6727 64.7734 84.9701C64.7734 85.8581 63.9334 86.4303 62.5737 86.4303C60.9922 86.4303 59.9278 85.8581 59.8294 84.155H54.4143C54.4647 88.0827 57.4791 90.481 62.4 90.481C67.0991 90.481 70.0904 88.1606 70.0904 84.4984C70.0904 80.8412 66.9754 79.7012 64.0319 78.9368Z" fill="#434A54"/>
    <path d="M81.5508 72.0107C76.0874 72.0107 72.5005 75.673 72.5005 81.2624C72.5005 86.8243 76.1123 90.509 81.5005 90.509C86.7922 90.509 90.3534 86.8017 90.3534 81.2855C90.3531 75.7462 86.8195 72.0107 81.5508 72.0107ZM81.5005 86.1331C79.2756 86.1331 77.9915 84.3249 77.9915 81.1845C77.9915 78.1677 79.3008 76.3412 81.4773 76.3412C83.6286 76.3412 84.8876 78.0944 84.8876 81.038C84.8876 84.3753 83.7019 86.1331 81.5005 86.1331Z" fill="#434A54"/>
    <path d="M99.2889 72.4824H93.8782V90.0877H107.103V85.4877H99.2889V72.4824Z" fill="#434A54"/>
    <path d="M118.002 72.4824H110.137V90.0877H117.901C123.66 90.0877 126.873 87.0944 126.873 81.2348C126.874 75.7462 123.39 72.4824 118.002 72.4824ZM117.553 85.6386H115.429V76.9087H117.036C119.805 76.9087 121.508 78.3417 121.508 81.3353C121.508 83.9815 120.199 85.6386 117.553 85.6386Z" fill="#434A54"/>
    <path d="M109.479 59.2577H71.8164C70.0884 59.2577 68.6853 57.857 68.6853 56.131C68.6853 54.4007 70.0881 53 71.8164 53H109.479C111.205 53 112.606 54.4007 112.606 56.131C112.605 57.857 111.204 59.2577 109.479 59.2577Z" fill="#DA4453"/>
    <path d="M109.479 109.323H71.8164C70.0884 109.323 68.6853 107.923 68.6853 106.197C68.6853 104.466 70.0881 103.065 71.8164 103.065H109.479C111.205 103.065 112.606 104.466 112.606 106.197C112.605 107.923 111.204 109.323 109.479 109.323Z" fill="#DA4453"/>
    </svg>
  );

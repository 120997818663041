import * as React from 'react';

export const CopyIcon = (props?: any) => {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
      >
        <path
            fill="#E1F5FE"
            fillRule="evenodd"
            d="M8.5 1h11c1.1 0 2 .9 2 2v11c0 .55-.45 1-1 1s-1-.45-1-1V4c0-.55-.45-1-1-1h-10c-.55 0-1-.45-1-1s.45-1 1-1zm-4 4h11c1.1 0 2 .75 2 1.667v11.666c0 .917-.9 1.667-2 1.667h-11c-1.1 0-2-.75-2-1.667V6.667C2.5 5.75 3.4 5 4.5 5zm10 13h-9c-.55 0-1-.354-1-.786V7.786c0-.432.45-.786 1-.786h9c.55 0 1 .354 1 .786v9.428c0 .432-.45.786-1 .786z"
            clipRule="evenodd"
        ></path>
      </svg>
  );
};

import { Button } from './index';
import styled from 'styled-components';

export const AddGoodButton = styled(Button)`
  border-radius: 50px;
  font-size: 18px;
  height: 40px;
  width: fit-content;
  padding: 8px 20px;
  flex: 0;
`;

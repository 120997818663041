import { keyframes } from 'styled-components';

export const Shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const fullView = keyframes`
  100% {
    width: 100%;
  }
`;

/*export const NewShimmer = keyframes`
  0% {
    background-color: ${props => props.theme.colors.button};
  }
  25% {
    background-color: ${props => props.theme.colors.button};
  }
  50% {
    background-color: ${props => props.theme.colors.secondaryButton};
  }
  75% {
    background-color: ${props => props.theme.colors.button};
  }
  100% {
    background-color: ${props => props.theme.colors.button};
  }
`;*/
export const NewOddShimmer = keyframes`
  0% {
    background-color: #e5e5e5;
  }
  25% {
    background-color: #e5e5e5;
  }
  50% {
    background-color: #f2f2f2;
  }
  75% {
    background-color: #e5e5e5;
  }
  100% {
    background-color: #e5e5e5;
  }
`;
export const NewEvenShimmer = keyframes`
  0% {
    background-color: #f2f2f2;
  }
  25% {
    background-color: #f2f2f2;
  }
  50% {
    background-color: #e5e5e5;
  }
  75% {
    background-color: #f2f2f2;
  }
  100% {
    background-color: #f2f2f2;
  }
`;

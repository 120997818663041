import * as React from "react";
import styled from "styled-components";
import { ThemeProviderMock } from "../utils/ThemeMockProvider";
import { DeleteIcon } from "../assets/icons/delete.icon";
import { fileSize } from '../Variables/imgFormats';
import { newLoading } from "..";

type Props = {
  addFileTextTranslation: string;
  showNotification: Function;
  customFileSize?: number;
  setFieldValue: Function;
  deleteFile: Function;
  dataTestId: string;
  fieldName: string;
  fileTitle: string;
  disabled: boolean;
  loading?: boolean;
  error: string | boolean;
}

export function InputDigitalFile( {
  addFileTextTranslation,
  showNotification,
  customFileSize,
  setFieldValue,
  deleteFile,
  dataTestId,
  fieldName,
  fileTitle,
  disabled,   
  loading,
  error,
}: Props) {
  
  const digitalFileRef = React.useRef<HTMLInputElement | null>(null);
  const fileSizeLimit = React.useMemo(() => customFileSize ? customFileSize : fileSize, [customFileSize] );

  const clickOnChooseFile = React.useCallback(() => {
    if(!digitalFileRef.current || disabled) return
    digitalFileRef.current.click();
  }, [disabled])

  const choseFile = React.useCallback((e:React.ChangeEvent<HTMLInputElement>) =>{
    if (!e.currentTarget || !e.currentTarget.files) return
    if (e.currentTarget.files[0].size > fileSizeLimit) {
      showNotification();
      return
    }
    setFieldValue(fieldName, e.currentTarget.files[0]);
  }, [fieldName, setFieldValue, showNotification, fileSizeLimit])

  const deletFile = React.useCallback(() => {
    deleteFile(fieldName);
  }, [deleteFile, fieldName])

  return (
    <ThemeProviderMock>
      {fileTitle
        ? (
          <div>
            <BreacksItem>
              <p>{fileTitle}</p>
              <DeleteIcon onClick={deletFile} />
            </BreacksItem>
          </div>
        )
        : ( !loading 
        ? <AddBreacksButton disabled={disabled} error={Boolean((error))} onClick={() => clickOnChooseFile()}>
            <span>+</span>
            <label>{addFileTextTranslation}</label>
            <StyledInput ref={digitalFileRef} data-testid={dataTestId} type="file"
              onChange={(e) => choseFile(e)} />
          </AddBreacksButton>
          : <LoadingShimmer></LoadingShimmer>
        )}
    </ThemeProviderMock>
  );
}

const LoadingShimmer = styled.div`
  height: 50px;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: block;
  ${newLoading}

`;

const AddBreacksButton = styled.div<{error: boolean; disabled: boolean}>`
  padding: 15px 20px;
  display: flex;
  ${props => props.disabled && "opacity: 0.5;"}
  ${props => props.error && `background: #FF4081;`}
span {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.54);
    line-height: 15px;
    cursor: pointer;
}
label {
  cursor: pointer;
  font-family: "Montserrat",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  margin-left: 14px;
  color: #000000;
}
`;

const BreacksItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  margin-bottom: 1px;
  p {
      font-family: "Montserrat",sans-serif;
      line-height: 140%;
      font-size: 14px;
      color: #000000;
  }
  svg {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  width: 0;
  height: 0;
`;

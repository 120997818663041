import React, { useMemo } from "react";
import styled from "styled-components";
import { Score, AverageScoreComponent } from "./Score";
import { size } from "../Variables/sizes"
import { PrimaryRoundButton } from "..";
import { EditIcon } from "../assets/icons/edit.icon";

type FeedbacksStatisticsCardPropsType = {
  count: {
    rating: number;
    count: number
  }[];
  maxScore: number;
  totalCount: number;
  avarageRating: number;
  isModerationMode?: boolean;
  writeFeedbackTitle?: string;
  feedbacksCountTitle: string;
  redirectToFeedbackForm?: (a?: any) => any;
}

export const FeedbacksStatisticsCard = ({
  count,
  maxScore,
  totalCount,
  avarageRating,
  isModerationMode,
  writeFeedbackTitle,
  feedbacksCountTitle,
  redirectToFeedbackForm,
}: FeedbacksStatisticsCardPropsType) => {

  const scoreElements = useMemo(() => {
    const score = [];
    for (let i = 0; i < maxScore; i++) {
      const currentCount = count.find(item => item.rating === (maxScore - i))
      score.push(<Score starsNumber={maxScore} unpaintedStars={i} isCount={true} count={currentCount ? currentCount.count : 0} />)
    }
    return score;
  }, [maxScore, count])

  return (
    <FeedbackStatisticsContainer
      isModerationMode={isModerationMode}
      data-testid="feedbacks-statistic-card"
    >
      <AverageScore>
        <ScoreContainer>
          <StyledSpan noView={true}>
            {avarageRating}
          </StyledSpan>
        </ScoreContainer>
        <AverageScoreContainer>
          <AverageScoreComponent maxScore={maxScore} score={avarageRating} />
          <FedbacksCount>
            {isModerationMode
              ? `${totalCount} ${feedbacksCountTitle}`
              : feedbacksCountTitle
            }
          </FedbacksCount>
        </AverageScoreContainer>
      </AverageScore>
      {!isModerationMode
        && <StyledPrimaryRoundButton
          load={false}
          Icon={<EditIcon />}
          onClick={redirectToFeedbackForm}
          data-test-id='write-feedbac-statistic-card-button'
        >
          {writeFeedbackTitle}
        </StyledPrimaryRoundButton>
      }
      {scoreElements}
    </FeedbackStatisticsContainer>
  )
}

const StyledSpan = styled.span<{ noView?: boolean; }>`
  ${props => props.noView && 'display: none;'}
`

const StyledPrimaryRoundButton = styled(PrimaryRoundButton)`
  margin: 20px 0;
  width: 100%;
  & :first-child{
    margin-right: 5px;
  }
`

const FeedbackStatisticsContainer = styled.div<{ isModerationMode?: boolean }>`
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  max-height:${props => props.isModerationMode ? '290px;' : "360px"};
  @media screen and (max-width: ${size.mobile}) {
    display: none;  
  }
`
const FedbacksCount = styled.div`
  color: #000000;
  opacity: 0.5;
  height: 20px;
`

const AverageScore = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
`

const AverageScoreContainer = styled.div`
  display: flex;
  flex-direction: column;

`

const ScoreContainer = styled.div`
font-weight: 600;
font-size: 50px;
margin-right: 10px;
`

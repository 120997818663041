import styled, { css } from 'styled-components';

export const p2Styles = css`
  font-family: ${props => props.theme.typo.primaryFont};
  line-height: ${props => props.theme.typo.lineHeight};
  font-size: 14px;
`;

export const P2 = styled.p`
  ${p2Styles};
`;

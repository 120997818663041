import * as React from 'react';

type Props = {
    active?: boolean;
    fill?: any;
};

export const PalleteIcon = ({active, fill}: Props) => {
    return (
        <svg
            width={22}
            height={22}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 0a9 9 0 0 0 0 18c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H13c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8ZM3.5 9C2.67 9 2 8.33 2 7.5S2.67 6 3.5 6 5 6.67 5 7.5 4.33 9 3.5 9ZM5 3.5C5 4.33 5.67 5 6.5 5S8 4.33 8 3.5 7.33 2 6.5 2 5 2.67 5 3.5ZM11.5 5c-.83 0-1.5-.67-1.5-1.5S10.67 2 11.5 2s1.5.67 1.5 1.5S12.33 5 11.5 5ZM13 7.5c0 .83.67 1.5 1.5 1.5S16 8.33 16 7.5 15.33 6 14.5 6 13 6.67 13 7.5Z"
                fill={active ? '#3F51B5' : 'black' || fill ? fill : 'black'}
            />
        </svg>
    );
};

import styled, { css } from 'styled-components';

export const h2Styles = css`
  font-family: ${props => props.theme.typo.primaryFont};
  font-weight: ${props => props.theme.typo.regular};
  line-height: ${props => props.theme.typo.lineHeight};
  font-size: 24px;
`;

export const H2 = styled.h2`
  ${h2Styles}
`;

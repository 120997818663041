import * as React from 'react';
import { memo, FC } from 'react';
import styled from 'styled-components';

type Props = {
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
}

export const FullA: FC<Props> = memo(({ children, ...props }) => {
  return (
    <A {...props}>
      <span>{children}</span>
    </A>
  );
});

export const A = styled.a`
  font-size: 14px;
  font-family: ${props => props.theme.typo.primaryFont};
  color: ${props => props.theme.colors.gradientStart};
  width: 100%;
  cursor: pointer;
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

type HeaderProps = {
  children?: React.ReactNode;
  isForm?: boolean;
};

const Header: FC<HeaderProps> = ({ children, ...rest }) => (
  <HeaderContainer {...rest}>
    <HeaderContent>{children}</HeaderContent>
  </HeaderContainer>
);

const HeaderContainer = styled.div<{isForm?: boolean}>`
  display: flex;
  justify-content: center;
  height: 70px;
  width: 100%;
  background: ${props => !props.isForm ? props.theme.colors.headerBackground : props.theme.colors.headerFormBackground};
`;
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export default Header;

import * as React from 'react';

export const DoneIcon = ({...props}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="15"
            fill="none"
            viewBox="0 0 20 15"
            {...props}
        >
            <path
                stroke={props.stroke || '#E1F5FE'}
                strokeLinecap="round"
                strokeWidth="2"
                d="M1 8l5.5 5.5L19 1"
                fill={props.fill || 'none'}
            ></path>
        </svg>
    );
};

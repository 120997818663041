import { useEffect, useState, useCallback } from 'react';

let resizeTimer: number;
export const useScroll = (deps: any[] = [], element?: HTMLElement) => {
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const callback = useCallback(() => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      const targetElement = element ? element : document.body
      if (targetElement.scrollHeight > targetElement.clientHeight) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    }, 150);
  }, [element]);
  useEffect(() => {
    window.removeEventListener('resize', callback);
    window.addEventListener('resize', callback);
    callback();
    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [callback]);

  useEffect(() => {
    callback();
  }, [deps, callback]);

  return [hasScroll, setHasScroll];
};

export default useScroll;

import * as React from 'react';

export const BellIcon = (props?: any) => (
  <svg
    {...props}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.62 15.0744H16.8596V13.4876H19.4629C19.9011 13.4876 20.2315 13.1621 20.2315 12.724V12.4489C20.2315 7.29456 16.2646 3.05129 11.2067 2.6182V1.58679C11.7026 1.58679 12.0166 1.23155 12.0166 0.793393C12.0166 0.355242 11.678 0 11.2398 0H9.65302C9.21487 0 8.84306 0.355242 8.84306 0.793393C8.84306 1.23155 9.2232 1.58679 9.6199 1.58679V2.60858C4.56201 2.98614 0.495871 7.25529 0.495871 12.449V12.724C0.495871 13.1622 0.875906 13.4877 1.31406 13.4877H3.76862V15.0745H2.25621C1.02586 15.0745 0 16.0421 0 17.2725V19.008C0 19.4462 0.380035 19.8348 0.818187 19.8348H20.058C20.4961 19.8348 20.8266 19.4462 20.8266 19.008V17.2725C20.8266 16.042 19.8503 15.0744 18.62 15.0744ZM9.51328 5.57676C9.94836 5.52023 10.3459 5.82718 10.4021 6.26156C10.4584 6.69614 10.1519 7.09413 9.71728 7.15036C8.03271 7.36864 6.4853 8.42614 5.67891 9.91028C5.53511 10.175 5.26258 10.3251 4.98112 10.3251C4.85319 10.3251 4.72337 10.294 4.60297 10.2286C4.21798 10.0195 4.07546 9.53778 4.28462 9.15269C5.32952 7.2295 7.33304 5.85931 9.51328 5.57676ZM5.35541 13.4876H15.2728V15.0744H5.35541V13.4876Z"
      fill={props.fill || '#FFFFFF'}
    />
  </svg>
);

import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

import { P2 } from '../Text/P2';
import { B } from '../Text/B';
import { size } from '../Variables/sizes';
import { PopupContainer, PopupTitle } from './Popup';
import { LoadingBar } from '../Loading/LoadingBar';
// import LoadingBar from '../Loading/LoadingBar'

type Props = {
  title?: string;
  text: React.ReactNode;
};

export const PopupImportLoading: React.FC<Props> = React.memo(
  ({ title, text }) => {
    return (
      <Fragment>
        <StyledPopupContainer>
          {title && (
            <PopupTitle>
              <B>{title}</B>
            </PopupTitle>
          )}
          {text && typeof text === 'string' ? <Text>{text}</Text> : text}
          <LoadingBarContainer>
            <LoadingBar />
          </LoadingBarContainer>
        </StyledPopupContainer>
      </Fragment>
    );
  }
);

PopupImportLoading.propTypes = {
  title: string,
  text: string,
};

const LoadingBarContainer = styled.div`
  margin: 20px 0;
  padding: 0 20px;
`;

const StyledPopupContainer = styled(PopupContainer)`
  padding-bottom: 20px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  @media screen and (min-width: ${size.mobile}) {
    transform: none;
    left: inherit;
    top: inherit;
    padding-bottom: 0;
  }
`;

const Text = styled(P2)`
  color: ${props => props.theme.colors.dark};
  padding: 0 20px;
`;

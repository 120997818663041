import * as React from "react";
import styled from "styled-components";
import {H3, P2, PrimaryRoundButton} from "..";
import { SoldIcon } from "./cardIcons/soldIcon.icon";

type SoldOutCardProps = {
    className?: string;
    handleGoToCatalog: (a?: any) => void;
    title: string;
    text: string;
    buttonText: string;
};

export const SoldOutCard: React.FC<SoldOutCardProps> = React.memo(({ handleGoToCatalog, title, text, buttonText, className }) => {
    return (
        <RightColServiceItem className={className || ''}>
            <IconWrapper>
                <SoldIcon />
            </IconWrapper>
            <TitleWrapper>
                {title}
            </TitleWrapper>

            <TextWrapper>
                {text}
            </TextWrapper>
            <ButtonWrapper>
                <PrimaryRoundButton load={false} onClick={handleGoToCatalog}>
                    {buttonText}
                </PrimaryRoundButton>
            </ButtonWrapper>
        </RightColServiceItem>
    );
}
);

export const RightColServiceItem = styled.div`
   background: ${(props) => props.theme.colors.light};
   padding: 0 0px;
   border-radius: 10px;
   padding-bottom: 20px;
   margin-bottom: 10px;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const TitleWrapper = styled(H3)`
    text-align: center;
    padding: 20px 0;
`

export const TextWrapper = styled(P2)`
    text-align: center;
    padding: 0 40px 20px 40px;
`

export const IconWrapper = styled.div`
    text-align: center;
    padding-top: 40px;
`

export const ButtonWrapper = styled.div`
    margin: 0 auto;
    width:177px;
`

import * as React from 'react';
import styled, { css } from 'styled-components';
import { P2 } from '../Text/P2';
import { H3 } from '../Text/H3';
import { ContentCard } from '../Cards/ContentCard';
import { SecondaryButton } from '../Buttons/secondary';
import { DefaultButton } from '../Buttons/primary';

import { size } from '../Variables/sizes';
import { Divider } from '../Menus/divider';

export const ProfileInputContainer = styled.div<{
  hasTopPadding?: boolean;
  children?: React.ReactNode;
}>`
  display: flex;
  align-items: flex-start;
  padding: ${props => (props.hasTopPadding ? '14px 0 0 20px' : '0 0 0 20px')};

  flex: 3;
  box-sizing: border-box;
  max-width: 400px;
  @media screen and (max-width: ${size.mobile}) {
    align-items: flex-start;
    padding: 0;
    max-width: 100%;
    flex: 1;
    width: 100%;
  }
`;

export const ProfileHeader = styled(H3)<{
  normal?: boolean;
}>`
  ${props =>
    props.normal &&
    css`
      font-size: ${props => props.theme.typo.p2Size};
      font-weight: ${props => props.theme.typo.regular};
    `}
  padding: 20px 30px 10px 30px;
  margin: 0;
  @media screen and (max-width: ${size.mobile}) {
    padding: 20px 20px 10px 20px;
  }
`;

export const ProfileCard = styled(ContentCard)<{
  noPadding?: boolean;
  mobileSharp?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}>`
  padding: 0;
  position: relative;
  overflow: hidden;
  & ${Divider} {
    margin: 15px 0;
  }
`;

/*const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;*/

export const ProfilePrimary = styled(DefaultButton)`
  /* width: auto; */
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }
  @media screen and (max-width: ${size.mobile}) {
    margin-right: 0;
    margin-bottom: 20px;
    align-items: center;
  }
`;

export const ProfileSecondary = styled(SecondaryButton)`
  /* width: auto; */
`;

export const ProfileText = styled(P2)`
  font-weight: ${props => props.theme.typo.fontMedium};
  flex: 1;
`;

// export const shimmerStyles = (props) => {
//   return css`
//   animation : ${Shimmer} 3s infinite linear;
//   background: ${getShimmer};
//   background-size: 1000px 100%;
//   `

export const ProfileLabelText = styled(P2)<{
  dark?: boolean;
}>`
  font-size: 14px;
  color: ${props => props.theme.colors.dark};
  opacity: ${props => (props.dark ? 1 : '0.7')};
  font-family: ${props => props.theme.typo.primaryFont};
  line-height: ${props => props.theme.typo.lineHeight};
  margin: 0;
`;

export const profileLabelContainerStyle = css<{
  center?: boolean;
}>`
  flex: 1;
  max-width: 200px;
  box-sizing: border-box;
  align-self: ${props => props.center && 'center'};
  padding: ${props => !props.center && '12px 0 0 0'};
  @media screen and (max-width: ${size.mobile}) {
    max-width: 100%;
    align-self: flex-start;
    padding: 0 0 15px 0;
  }
`;

export const ProfileLabelContainer = styled.div`
  ${profileLabelContainerStyle};
`;

export const ProfileLineItem = styled.div<{
  mobileHorizontal?: boolean;
}>`
  display: flex;
  padding: 15px 30px;
  @media screen and (max-width: ${size.mobile}) {
    padding: 10px 20px;
    align-items: ${props => (props.mobileHorizontal ? 'center' : 'flex-start')};
    flex-flow: ${props => (props.mobileHorizontal ? 'row' : 'column')};
    ${props =>
      props.mobileHorizontal &&
      css`
        & ${ProfileLabelText} {
          padding: 0;
        }
        & ${ProfileLabelContainer} {
          align-self: center;
          max-width: 180px;
          padding: 0;
        }
      `}
  }
`;

export const ProfileButtonContainer = styled(ProfileLineItem)`
  background: ${props => props.theme.colors.profileBottomBackground};
  display: flex;
  margin-top: 15px;
  & ${ProfileLabelContainer} {
    padding-top: 0;
  }
  & ${ProfileInputContainer} {
    padding-top: 0;
  }
  @media screen and (max-width: ${size.mobile}) {
    flex-direction: column;
    & ${ProfileInputContainer} {
      flex-direction: column;
    }
  }
  /* justify-content: center; */
`;

export const ProfileLinkStyle = () => {
  return css`
    text-decoration: none;
    color: ${props => props.theme.colors.gradientStart};
    width: 20%;
    font-size: 14px;
  `;
};

type ProfileLineContainerProps = {
  center?: boolean;
  mobileHorizontal?: boolean;
  hasTopPadding?: boolean;
  label?: Node | string;
  labelDark?: boolean;
  children?: React.ReactNode;
};

export const ProfileLineContainer: React.FC<ProfileLineContainerProps> = ({
  children,
  center,
  mobileHorizontal,
  hasTopPadding,
  label,
  labelDark,
}) => {
  return (
    <ProfileLineItem mobileHorizontal={mobileHorizontal}>
      {label && typeof label === 'string' && (
        <ProfileLabelContainer center={center}>
          <ProfileLabelText dark={labelDark}>{label}</ProfileLabelText>
        </ProfileLabelContainer>
      )}
      {label && typeof label === 'object' && (
        {label}
      )}
      <ProfileInputContainer hasTopPadding={hasTopPadding}>
        {children}
      </ProfileInputContainer>
    </ProfileLineItem>
  );
};

// export const ProfileCard = ({ children, loading,  ...props }) => {
//   return (
//     <ProfileStyleCard>
//       <LoaderContainer>
//         {loading && <LoadingBar transparentBackground />}
//       </LoaderContainer>
//       {children}
//     </ProfileStyleCard>
//   )
// }

import { css } from 'styled-components';
import { Shimmer, NewOddShimmer, NewEvenShimmer } from '../Keyframes/Shimmer';
import { size } from './sizes';
import { StyledPropsTheme } from 'Variables';
//inputs

export const textInput = (props: StyledPropsTheme) => `
  font-family: ${props.theme.typo.primaryFont};
  font-size: 16px;
`;

export const textParagraph = (props: StyledPropsTheme) => `
  font-family: ${props.theme.typo.primaryFont};
  font-size: 14px;
`;

export const textList = (props: StyledPropsTheme) => `
  font-family: ${props.theme.typo.primaryFont};
  line-height:  ${(props: StyledPropsTheme) => props.theme.typo.lineHeight};
  font-size: 18px;
`;

export const textButton = (props: StyledPropsTheme) => `
  font-family: ${props.theme.typo.primaryFont};
  font-weight: ${props.theme.typo.fontMedium};
  font-size:  ${props.theme.typo.fontSize};
  line-height:  ${props.theme.typo.lineHeight};
  background: none;
  border: none;
  box-shadow: none;
  appearance: none;
`;

export const textRoundButton = (props: StyledPropsTheme) => `
  font-family: ${props.theme.typo.primaryFont};
  font-weight: ${props.theme.typo.fontMedium};
  font-size:  ${props.theme.typo.fontSize};
  line-height:  ${props.theme.typo.lineHeight};
  box-shadow: none;
  appearance: none;
`;

export const gradientBackground = (props: StyledPropsTheme) => `
  background: linear-gradient(138.21deg, ${props.theme.colors.gradientStart} -0.42%, ${props.theme.colors.gradientFinish} 100%);
  color: ${props.theme.typo.textColor};
`;

export const shadowBackground = (props: StyledPropsTheme) => `
  background: linear-gradient(180deg, transparent 0%, ${props.theme.colors.headerItem} 100%);
`;

export const fullHeight = () => `
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const formUnitWidth = (
  props: StyledPropsTheme & {
    size: 'medium' | 'big';
  }
) => {
  switch (props.size) {
    case 'medium':
      return 'width: 280px';
    case 'big':
      return 'width: 400px';
    default:
      return 'width: 220px';
  }
};

export const inputNumberAppearance = () => {
  return css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & {
      -moz-appearance: textfield;
    }
  `;
};

export const buttonAppearanceReset = () => {
  return css`
    border: none;
    appearance: none;
    background: none;
  `;
};

const getShimmer = (
  props: StyledPropsTheme & {
    type?: 'inst';
  }
) => {
  switch (props.type) {
    case 'inst':
      return props.theme.colors.shimmerInst;
    default:
      return props.theme.colors.shimmer;
  }
};

export const shimmerStyles = () => {
  return css`
    animation: ${Shimmer} 3s
      ${(props: { static?: boolean }) => !props.static && 'infinite'} linear;
    background: ${getShimmer};
    background-size: 1000px 100%;
  `;
};

export const equalGrid = () => {
  return css`
    display: flex;
    flex-wrap: wrap;
  `;
};

export const menuItemStyle = () => {
  return css`
    ${textButton};
    font-size: 14px;
    cursor: pointer;
    color: ${(
      props: StyledPropsTheme & {
        active: boolean;
      }
    ) =>
      props.active
        ? props.theme.colors.gradientStart
        : props.theme.colors.dark};
    max-width: 220px;
    height: 40px;
    background: ${(
      props: StyledPropsTheme & {
        active: boolean;
      }
    ) => (props.active ? props.theme.colors.sideMenuActive : 'none')};
    &:hover {
      background: ${(
        props: StyledPropsTheme & {
          active: boolean;
        }
      ) =>
        props.active
          ? props.theme.colors.sideMenuActive
          : props.theme.colors.sideMenuHover};
    }
    font-weight: ${(
      props: StyledPropsTheme & {
        active: boolean;
      }
    ) => (props.active ? props.theme.typo.fontSemiBold : 'normal')};
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    @media screen and (max-width: ${size.mobile}) {
      width: 100%;
      box-sizing: border-box;
      max-width: 100%;
    }
  `;
};

export type ContentCardStylesProps = {
  noPadding?: boolean;
  mobileSharp?: boolean;
};

export const contentCardStyles = () => {
  return css`
    ${textList};
    font-size: 14px;
    color: ${props => props.theme.colors.dark};
    appearance: none;
    border: none;
    width: 100%;
    border-radius: 10px;
    padding: ${(props: ContentCardStylesProps & StyledPropsTheme) =>
      props.noPadding ? '0' : '18px 20px'};
    background: ${props => props.theme.colors.light};
    box-shadow: ${props => props.theme.colors.cardShadow};
    text-align: left;
    outline: none;
    margin-bottom: 20px;
    @media screen and (max-width: ${size.mobile}) {
      border-radius: ${(props: ContentCardStylesProps & StyledPropsTheme) =>
        props.mobileSharp ? '0' : '10px'};
    }
  `;
};

export const statefullIconStylesHover = css`
  &:hover {
    .fill {
      fill: ${props => props.theme.colors.iconsFillHoverBackground};
    }
  }
`;

export type StatefullIconStylesProps = {
  light?: boolean;
  active?: boolean;
  filled?: boolean;
};

export const statefullIconStyles = () => {
  return css`
    .fill {
      fill: none;
    }
    .border {
      fill: ${(props: StatefullIconStylesProps & StyledPropsTheme) =>
        props.light ? props.theme.typo.textColor : 'black'};
      opacity: ${(props: StatefullIconStylesProps & StyledPropsTheme) =>
        props.active ? '0.3' : '1'};
    }
    ${statefullIconStylesHover};
    ${(props: any) =>
      props.active &&
      css`
    &&& {
      .fill {
        fill: ${props => props.theme.colors.gradientFinish};
      }
      .border {
        /* fill: ${props => props.theme.colors.gradientEnd}; */
      }
    }
  `}
    ${(props: StatefullIconStylesProps & StyledPropsTheme) =>
      props.filled &&
      css`
        &&& {
          .fill {
            fill: black;
          }
        }
      `}
  .first {
      stop-color: ${props => props.theme.colors.gradientStart};
    }
    .second {
      stop-color: ${props => props.theme.colors.gradientFinish};
    }
  `;
};

export const newLoading = css`
  animation: ${NewOddShimmer} 1400ms linear infinite;
`;
export const newEvenLoading = css`
  animation: ${NewEvenShimmer} 1400ms linear infinite;
`;

export const LoadingAnimation = css`
  position: relative;
  overflow: hidden;
  &:nth-of-type(odd) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      ${newLoading}
    }
  }
  &:nth-of-type(even) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      ${newEvenLoading}
    }
  }
`;

export const responsiveButtonContainer = css`
  display: flex;
  margin-top: 20px;
  button {
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  @media screen and (max-width: ${size.mobile}) {
    flex-flow: column;
    align-items: center;
    && {
      button {
        margin-right: 0;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const textDescription = (props: any) => `
  font-family: ${props.theme.typo.primaryFont};
  font-size: 12px;
`

import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { textInput } from '../Variables/mixins';

export const Notification: FC<{children?: React.ReactNode;}> = ({ children, ...props }) => (
  <Alert {...props}>{children}</Alert>
);

const Alert = styled.div`
  background: ${props => props.theme.colors.dark};
  ${textInput};
  color: ${props => props.theme.colors.light};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px;
  z-index: 100;
`;

import * as React from 'react';
import styled from 'styled-components';

type Props = {
  options: {
    key: string | number;
    value: string;
  }[];
  value: string | number;
};

export const BorderedSelect = ({ options, value, ...props }: Props) => {
  return (
    <SelectContainer>
      <StyledSelect {...props}>
        {options.map(option => (
          <option selected={option.key === value} value={option.key}>
            {option.value}
          </option>
        ))}
      </StyledSelect>
      <SelectArrow>↓</SelectArrow>
    </SelectContainer>
  );
};

const SelectContainer = styled('div')`
  font-family: ${props => props.theme.typo.primaryFont};
  width: 100%;
  position: relative;
  font-size: ${props => props.theme.typo.pSize};
  max-width: 400px;
  min-height: 42px;
`;

const SelectArrow = styled('div')`
  position: absolute;
  right: 15px;
  font-size: 22px;
  top: 7px;
  color: ${props => props.theme.colors.dark};
  z-index: 1;
`

const StyledSelect = styled('select')`
  appearance: none;
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.button};
  border-radius: 8px;
  padding: 10px 15px 12px;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  z-index: 2;
  position: absolute;
`;

import * as React from 'react';

export const MastercardIcon = (props: any) => (
  <svg
    {...props}
    width="33"
    height="20"
    viewBox="0 0 33 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5516 2.13843H11.8027V17.8615H20.5516V2.13843Z"
      fill="#FF5F00"
    />
    <path
      d="M12.3581 10.0026C12.3577 8.48835 12.7013 6.99379 13.3628 5.6317C14.0244 4.26961 14.9867 3.07557 16.1771 2.13968C14.702 0.980442 12.9306 0.259579 11.0652 0.0594816C9.19983 -0.140616 7.3158 0.188126 5.62842 1.00813C3.94105 1.82814 2.51842 3.10633 1.52311 4.69662C0.527813 6.28691 3.84353e-08 8.12514 3.84353e-08 10.0012C3.84353e-08 11.8773 0.527813 13.7155 1.52311 15.3058C2.51842 16.8961 3.94105 18.1743 5.62842 18.9943C7.3158 19.8143 9.19983 20.143 11.0652 19.9429C12.9306 19.7428 14.702 19.022 16.1771 17.8627C14.9861 16.928 14.0234 15.7345 13.3618 14.3728C12.7001 13.011 12.3569 11.5166 12.3581 10.0026Z"
      fill="#EB001B"
    />
    <path
      d="M32.3563 10.0013C32.3562 11.8775 31.8283 13.7159 30.8328 15.3062C29.8373 16.8965 28.4145 18.1747 26.7269 18.9946C25.0393 19.8145 23.1551 20.143 21.2896 19.9426C19.4241 19.7423 17.6527 19.021 16.1777 17.8614C17.3682 16.9258 18.3307 15.7321 18.9925 14.3703C19.6543 13.0084 19.9982 11.5141 19.9982 9.99992C19.9982 8.48579 19.6543 6.99143 18.9925 5.62958C18.3307 4.26774 17.3682 3.07399 16.1777 2.1384C17.6529 0.978648 19.4246 0.257402 21.2903 0.057122C23.156 -0.143158 25.0405 0.185611 26.7282 1.00584C28.4158 1.82607 29.8387 3.10465 30.8339 4.6954C31.8292 6.28615 32.3568 8.12486 32.3563 10.0013ZM31.4008 16.195V15.8728H31.5314V15.8089H31.2009V15.8728H31.3425V16.195H31.4008ZM32.0424 16.195V15.8089H31.9424L31.8258 16.0867L31.7091 15.8089H31.6203V16.195H31.6925V15.9172L31.8008 16.17H31.8758L31.9841 15.9172V16.195H32.0424Z"
      fill="#F79E1B"
    />
  </svg>
);

import styled from 'styled-components';

import { size } from '../Variables/sizes';

export const H1 = styled.h1`
  font-family: ${props => props.theme.typo.primaryFont};
  font-weight: ${props => props.theme.typo.regular};
  font-size: 36px;
  line-height: ${props => props.theme.typo.lineHeight};
  @media screen and (max-width: ${size.mobile}) {
    font-size: 24px;
  }
`;

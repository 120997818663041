import * as React from 'react';

export const SendIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M2.51 21.5L23.5 12.5L2.51 3.5L2.5 10.5L17.5 12.5L2.5 14.5L2.51 21.5Z"
        fill="white" />
    </svg>
  )
};
import * as React from 'react';
import { memo, useMemo, FC } from 'react';
import styled from 'styled-components';

import { usePreventDefaultCallback } from '../../hooks/preventDefault';
import { BracketIcon } from '../../assets/icons/bracket.icon';
import { P1 } from '../../Text/P1';
import { Category } from 'utils/flatten';

type Props = {
  isAllCategories?: boolean;
  onItemClick: (id: string) => void;
  onParentClick: (id: string) => void;
  nested?: Category[];
  id: string;
};

export const PopupCategoriesSimpleListItem: FC<Props> = memo(
  ({
    onItemClick,
    onParentClick,
    nested,
    children,
    id,
    isAllCategories,
  }) => {

    const hasChildren = useMemo(() => {
      return nested && nested.length > 0;
    }, [nested]);

    const handleItemClick = usePreventDefaultCallback(() => {
      if (hasChildren) {
        onParentClick(id);
      } else {
        onItemClick(id);
      }
    }, [id, onItemClick, hasChildren, onParentClick]);

    const showBracket = useMemo(() => {
      return hasChildren;
    }, [hasChildren]);

    return (
      <PopupCategoryItemContainer onClick={handleItemClick}>
        <PopupCategoryItemLink href="#">
          <PopupCategoryItemText
            bold={false}
            isAllCategories={isAllCategories}
          >
            {children}
          </PopupCategoryItemText>
          {showBracket && <PopupCategoryItemBracket />}
        </PopupCategoryItemLink>
      </PopupCategoryItemContainer>
    );
  }
);

export const PopupCategoryItemContainer = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  &:hover {
    background: ${props => props.theme.colors.mildBackground};
  }
`;

export const PopupCategoryItemLink = styled.a`
  flex: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 12px 0;
  color: ${props => props.theme.colors.dark};
`;

export const PopupCategoryItemText = styled(P1) <{
  bold?: boolean;
  isAllCategories?: boolean;
}>`
  margin: 0;
  text-align: left;
  font-weight: ${props =>
    props.bold || props.isAllCategories
      ? props.theme.typo.fontSemiBold
      : props.theme.typo.regular};
 `;

export const PopupCategoryItemBracket = styled(BracketIcon)`
  transform: rotate(180deg);
  margin-left: auto;
`;

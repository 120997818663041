import * as React from 'react';

export const SafeIcon = (props: any) => {
    return (
        <svg
            width={150}
            height={150}
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#a)">
                <path d="M144.898 2.641H10.223v134.676h134.675V2.641Z" fill="#2DC6DB"/>
                <path
                    d="M111.811 2.641 11.581 137.316h-1.36V96.674L80.205 2.64h31.607ZM136.925 2.641 36.691 137.316H23.103L123.329 2.641h13.596ZM26.958 30.107H5.102V56.69h21.856V30.107Z"
                    fill="#E1F5FE"
                />
                <path
                    d="M26.958 83.268H5.102v26.582h21.856V83.268ZM78.694 96.251c14.523 0 26.297-11.774 26.297-26.297 0-14.524-11.774-26.297-26.297-26.297-14.524 0-26.297 11.773-26.297 26.297 0 14.523 11.773 26.297 26.297 26.297Z"
                    fill="#E1F5FE"
                />
                <path
                    d="M51.274 137.316H23.512v10.043h27.762v-10.043ZM131.609 137.316h-27.762v10.043h27.762v-10.043Z"
                    fill="#2DC6DB"
                />
                <path
                    d="M144.898 0H41.858a2.641 2.641 0 0 0 0 5.282h100.399v129.394H12.864v-22.184h6.532v13.011a2.641 2.641 0 0 0 2.641 2.641h15.218a2.64 2.64 0 1 0 0-5.282H24.678v-10.37h2.28a2.642 2.642 0 0 0 2.64-2.642V83.27a2.641 2.641 0 0 0-2.64-2.641h-2.28v-21.3h2.28a2.641 2.641 0 0 0 2.64-2.64V30.108a2.641 2.641 0 0 0-2.64-2.642h-2.28v-10.37h105.765v14.267a2.64 2.64 0 1 0 5.282 0V14.455a2.64 2.64 0 0 0-2.641-2.64H22.037a2.641 2.641 0 0 0-2.641 2.64v13.011h-6.532V5.282h19.788a2.641 2.641 0 0 0 0-5.282h-22.43a2.641 2.641 0 0 0-2.64 2.641v24.826h-2.48a2.641 2.641 0 0 0-2.641 2.64V56.69A2.641 2.641 0 0 0 5.1 59.33h2.48V80.63h-2.48a2.641 2.641 0 0 0-2.64 2.641v26.581a2.641 2.641 0 0 0 2.64 2.641h2.48v24.825a2.642 2.642 0 0 0 2.642 2.642h10.649v7.401a2.641 2.641 0 0 0 2.64 2.641h27.763a2.64 2.64 0 0 0 2.641-2.641v-7.401h47.289v7.401a2.642 2.642 0 0 0 2.641 2.641h27.763a2.641 2.641 0 0 0 2.641-2.641v-7.401h10.649a2.642 2.642 0 0 0 2.641-2.642V2.641A2.642 2.642 0 0 0 144.898 0ZM7.743 85.91h16.573v21.299H7.743V85.91Zm0-31.863V32.75h16.573v21.299H7.743Zm5.12 5.282h6.533v21.3h-6.532v-21.3Zm35.771 85.389h-22.48v-4.761h22.48v4.761Zm80.334 0h-22.481v-4.761h22.481v4.761Z"
                    fill="#000"
                />
                <path
                    d="M43.82 125.502a2.642 2.642 0 0 0 2.641 2.642h86.623a2.642 2.642 0 0 0 2.641-2.642V40.57a2.64 2.64 0 1 0-5.282 0v82.292H46.461a2.64 2.64 0 0 0-2.64 2.64Z"
                    fill="#000"
                />
                <path
                    d="M107.632 69.956c0-15.957-12.982-28.939-28.939-28.939-15.956 0-28.938 12.982-28.938 28.939s12.981 28.939 28.938 28.939c15.957 0 28.939-12.983 28.939-28.94Zm-37.522 0c0-4.733 3.85-8.584 8.583-8.584 4.733 0 8.584 3.85 8.584 8.584 0 4.733-3.85 8.583-8.584 8.583-4.733 0-8.583-3.85-8.583-8.583Zm11.224 13.61a13.796 13.796 0 0 0 5.114-2.121l6.99 6.99a23.538 23.538 0 0 1-12.103 5.026v-9.895Zm8.849-5.856a13.793 13.793 0 0 0 2.12-5.113h9.896a23.538 23.538 0 0 1-5.026 12.104l-6.99-6.99Zm2.12-10.395a13.785 13.785 0 0 0-2.12-5.113l6.99-6.99a23.535 23.535 0 0 1 5.026 12.103h-9.895Zm-5.855-8.848a13.793 13.793 0 0 0-5.113-2.121V46.45a23.54 23.54 0 0 1 12.103 5.026l-6.99 6.99Zm-10.395-2.121a13.789 13.789 0 0 0-5.114 2.12l-6.99-6.99a23.538 23.538 0 0 1 12.104-5.026v9.895ZM67.204 62.2a13.792 13.792 0 0 0-2.121 5.114h-9.896a23.54 23.54 0 0 1 5.026-12.104l6.99 6.99Zm-2.122 10.396a13.789 13.789 0 0 0 2.121 5.113l-6.99 6.99a23.538 23.538 0 0 1-5.026-12.104h9.895Zm5.856 8.848a13.793 13.793 0 0 0 5.113 2.12v9.896a23.54 23.54 0 0 1-12.104-5.026l6.991-6.99Z"
                    fill="#000"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h150v150H0z"/>
                </clipPath>
            </defs>
        </svg>
    )
};

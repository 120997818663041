import * as React from 'react';
import {FC, useCallback} from 'react';
import styled, {css} from "styled-components";
import {textList} from "../Variables/mixins";
import {P1} from "../Text/P1";

type Props = {
    items: {
        counter?: number
        title: string;
        active?: boolean;
    }[];
    position: 'top' | 'bottom';
    open?: boolean;
    handleClick: (index: number) => void;
};

export const SubMenu: FC<Props> = ({items, open, position, handleClick, ...rest}) => {
    const handleItemClick = useCallback(
        (e, index) => {
            e.preventDefault();
            handleClick(index);
        },
        [handleClick]
    );
    return (
        <DropDownContainer open={open} position={position} {...rest}>
            {
                items.map((item, index) => (
                    <DropDownItem
                        onClick={e => handleItemClick(e, index)}
                        key={index}
                    >
                        {item.title}
                        {item.counter && <CounterContainer>{item.counter}</CounterContainer>}
                    </DropDownItem>
                ))
            }
        </DropDownContainer>
    )
}

const getPosition = (props: { position: 'top' | 'bottom' }) => {
    if (props.position === 'top') {
        return css`
          top: 0;
        `
    } else {
        return css`
          bottom: 0;
        `
    }
}

const DropDownContainer = styled.div<{
    open?: boolean;
    position: 'top' | 'bottom';
}>`
  background: #F2ECFF;
  color: #000000;
  position: absolute;
  ${getPosition};
  left: 0;
  width: 100%;
  padding: 0 30px;
  //padding: 20px 30px;
  display: ${props =>
          props.open
                  ? 'block'
                  : 'none'};
  border-radius: ${props =>
          props.position === 'top'
                  ? '0px 0px 40px 40px'
                  : '40px 40px 0px 0px'};
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
`
const DropDownItem = styled(P1)`
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 0 0 25px 0;
  &:first-child{
    margin-top: 15px;
  }
`
const CounterContainer = styled.span`
  background: #FF4081;
  padding: 2px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  ${textList};
  font-size: 16px;
`


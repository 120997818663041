import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

type Props = {
  fullWidth?: boolean;
  hasScroll?: boolean;
  children?: React.ReactNode;
};

export const PopupScrollContainer: React.FC<Props> = React.memo(
  ({ children, fullWidth, hasScroll }) => {
    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = '';
      };
    }, []);
    return (
      <Container fullWidth={fullWidth} hasScroll={hasScroll}>
        {children}
      </Container>
    );
  }
);

/*const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 30px);
  position: absolute;
  overflow: auto;
`;*/

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
  align-items: center;
  justify-content: center;
  padding: ${(props: Props) => (props.fullWidth ? 0 : '20px 0')};
  display: ${(props: Props) => (props.hasScroll ? 'block' : 'flex')};
`;

import * as React from 'react'

export const RefreshIcon = (props: any) => (
    <svg
        width={53}
        height={51}
        viewBox="0 0 53 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M47.63 16.648C44.217 7.508 35.405 1 25.074 1 11.778 1 1 11.778 1 25.073 1 38.37 11.778 49.147 25.073 49.147c10.201 0 18.92-6.345 22.426-15.304"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="m40.867 14.339 7.891 3.91.574-8.789"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
);

import { FC } from 'react';
import { Button, ButtonProps } from './index';
import styled, { css } from 'styled-components';

export const PrimaryBlackButton: FC<ButtonProps> = styled(Button)`
  color: ${props => props.theme.colors.white};
  ${props =>
    !props.load &&
    css`
      background-color: ${props => props.theme.colors.dark};
      &:hover {
        background:${props => props.theme.colors.roundButtonPrimaryHover};
        border: 1px solid ${props => props.theme.colors.dark};
        box-shadow: 0px 4px 4px ${props => props.theme.colors.buttonActive},
          inset 0px -3px 0px ${props => props.theme.colors.buttonPushed};
      }
      &:active {
        background: ${props => props.theme.colors.dark};
        box-shadow: 0px 0px 4px ${props => props.theme.colors.buttonActive};
      }
    `}
  ${props =>
    props.disabled &&
    !props.load &&
    css`
      background: ${props => props.theme.colors.dark};
      opacity: 0.15;
      border: transparent;
      &:hover,
      &:active {
        background: ${props => props.theme.colors.dark};
        border: transparent;
        opacity: 0.15;
      }
    `}
`;
export const typo = {
  primaryFont: '"Montserrat", sans-serif',
  regular: 400,
  fontMedium: 500,
  fontSemiBold: 600,
  textColor: '#E1F5FE',
  fontSize: '18px',
  lineHeight: '140%',
  p2Size: '14px',
  h1Size: '36px',
  pSize: '16px',
  smallSize: '12px'
};

export const colors = {
  mildBackground: '#ECEFF1',
  handlerGray: '#DADADA',
  noteAccent: '#FFE289',
  designBackground: '#C4C4C4',
  nightBlue: "#181E44",
  dark: '#000',
  light: '#fff',
  error: '#FF4081',
  gradientStart: '#3F51B5',
  basePriceColor: "#5D5E5F",
  headerLabelColor: '#767879',
  gradientFinish: '#7A3FB5',
  vk: 'rgba(63, 81, 181, 0.5)',
  vkHover: 'rgba(56, 80, 213, 0.75)',
  vkPushed: '#3346F1',
  shimmerVk:
    'linear-gradient(97.5deg, rgba(63, 81, 181, 0.1) 0%, rgba(63, 81, 181, 0.5) 51.13%, rgba(63, 81, 181, 0.1) 56.51%);',
  padDisabledBackground: 'rgba(255, 64, 129, 0.1)',
  padNormalBackground:
    'linear-gradient(159.86deg, rgba(63, 81, 181, 0.1) -0.42%, rgba(122, 63, 181, 0.1) 100%)',
  inst: 'rgba(255, 64, 129, 0.5)',
  instHover: 'rgba(255, 64, 129, 0.75)',
  instPushed: '#FF4081',
  shimmerInst:
    'linear-gradient(97.5deg, rgba(255, 64, 129, 0.1) 0%, rgba(255, 64, 129, 0.5) 51.13%, rgba(255, 64, 129, 0.1) 56.51%);',
  headerItem: 'rgba(0, 0, 0, 0.75)',
  button: 'rgba(0, 0, 0, 0.1)',
  buttonDisabled: 'rgba(0, 0, 0, 0.2)',
  buttonActive: '#DADADA',
  buttonPushed: 'rgba(0,0,0,0.5)',
  compactButton: 'rgba(0, 0, 0, 0.05)',
  compactButtonDisabled: 'rgba(0, 0, 0, 0.2)',
  compactButtonIconFill: '#FF4081',
  compactButtonPushed: 'rgba(255, 64, 129, 0.2)',
  flatButtonHoverBackground: 'rgba(0,0,0,0.03)',
  profileBottomBackground: 'rgba(0, 0, 0, 0.02)',
  secondaryButton: 'rgba(0, 0, 0, 0.05)', 
  secondaryButtonPushed: 'rgba(0,0,0,1.0)',
  primaryButtonHover: `linear-gradient(144.16deg, #3F51B5 -0.42%, #7A3FB5 100%);`,
  primaryButtonActive: `linear-gradient(107.37deg, #714CA8 0%, #9940A8 100%);`,
  primaryDisabled: 'rgba(63, 81, 181, 0.25)',
  buttonBorder: 'rgba(0, 0, 0, 0.15)',
  cartButtonHover: '#2B2747',
  inputFocused: '#2DC6DB',
  yellowInputFocused: '#ffe289',
  shimmer:
    'linear-gradient(97.5deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 51.13%, rgba(0, 0, 0, 0.1) 56.51%);',
  inputLoading: 'rgba(255,255,255,0.5)',
  cardHover: '#E1F5FE',
  cardShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  sideMenuActive: 'rgba(63, 81, 181, 0.1)',
  sideMenuHover: 'rgba(63, 81, 181, 0.05)',
  addButtonGradient: 'linear-gradient(107.47deg, #3F51B5 -0.42%, #7A3FB5 100%)',
  addButtonShadow: '0px 0px 10px rgba(63, 81, 181, 0.5)',
  orderActiveShadow: `0px 0px 25px rgba(80, 76, 181, 0.5)`,
  // cartButtonGradient:  `linear-gradient(122.27deg, #d9dcf0 -0.42%, #d3d6e7 100%);`,
  greyBlurGradient: `linear-gradient(168.77deg, rgba(0, 0, 0, 0.05) -0.42%, rgba(0, 0, 0, 0.05) 100%);`,
  // cartButtonGradient: `linear-gradient(107.47deg, #3F51B5 -0.42%, #7A3FB5 100%);`,
  cartButtonHoverGradient:
    'linear-gradient(94.45deg, #bcb8de 0%, #bfafd6 100%);',
  cartButtonDisabled: 'rgba(0, 0, 0, 0.1);',
  ordersGreyBack: '#D5D8D9',
  orderCardShadow: '0px 0px 25px rgba(80, 76, 181, 0.5);',
  itemCardShadow:
    '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -3px 0px rgba(0, 0, 0, 0.1)',
  smallButtonShadow: 'inset 0px -3px 0px rgba(0, 0, 0, 0.1)',
  smallButtonPressedShadow: 'inset 0px 3px 0px rgba(0, 0, 0, 0.1)',
  loadingBarBackground: '#e6e6e6',
  loadingBar: 'linear-gradient(172.81deg, #3F51B5 -0.42%, #7A3FB5 100%)',
  searchOpenedShadow: '0px 0px 75px rgba(63, 81, 181, 0.75)',
  wavesLoadingBackground:
    'linear-gradient(60deg, rgba(63,81,181,1) 0%, rgba(122,63,181,1) 100%)',
  iconsFillHoverBackground: 'rgba(0, 0, 0, 0.12)',
  flatButtonContainerShadow: 'rgba(255,255,255, 0.2)',
  pillShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  pillDisabled: '#F2F2F2',
  paymentCardBackground:
    'linear-gradient(223.95deg, #D650D3 -0.37%, #9B51E0 96%);',
  paymentEllipseBackground:
    'radial-gradient(50.27% 44.86% at 49.73% 50%, #C31AFF 0%, #833ACB 50.52%, rgba(63, 81, 181, 0) 100%)',
  radioDescription: 'rgba(0,0,0,0.65)',
  okru: 'linear-gradient(211.65deg, rgba(235, 0, 27, 0.7) -8.32%, rgba(247, 158, 27, 0.7) 108.85%), rgba(255, 255, 255, 0.5);',
  okruHover: 'linear-gradient(211.65deg, #EA6816 -8.32%, rgba(247, 158, 27, 0.7) 108.85%), rgba(255, 255, 255, 0.5)',
  okruPushed: 'linear-gradient(211.65deg, #FE8639 -8.32%, rgba(247, 158, 27, 0.7) 108.85%), rgba(255, 255, 255, 0.5)',
  cartButtonBackground: `rgba(255,255,255,0.8)`,
  searchGradient: `radial-gradient(50% 50% at 50% 50%, rgba(63, 81, 181, 0.25) 0%, rgba(63, 81, 181, 0) 100%)`,
  transparentSecondaryButtonHover: '#f4f4f4',
  transparentSecondaryButtonActive: '#d2d4d6',
  emailDeliveryItemCard: 'rgba(152, 185, 156, 0.1)',
  emailDeliveryCart: 'rgba(152, 185, 156, 0.05)'
};

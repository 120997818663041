import * as React from 'react';

export const ProfileIcon = (props?: any) => (
    <svg
        width={70}
        height={70}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            x={0.5}
            y={0.5}
            width={69}
            height={69}
            rx={34.5}
            fill="#2DC6DB"
            stroke="#26A8BA"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.143 10.143c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25Zm0 7.5c4.15 0 7.5 3.35 7.5 7.5s-3.35 7.5-7.5 7.5a7.49 7.49 0 0 1-7.5-7.5c0-4.15 3.35-7.5 7.5-7.5Zm-15 27.45c3.225 4.85 8.75 8.05 15 8.05s11.775-3.2 15-8.05c-.075-4.975-10.025-7.7-15-7.7-5 0-14.925 2.725-15 7.7Z"
            fill="#E1F5FE"
        />
    </svg>
)


import * as React from "react";

export const ImageIcon = (props?: any) => (
    <svg
        {...props}
        width="22"
        height="12"
        viewBox="0 0 22 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M13 0L9.25 5L12.1 8.8L10.5 10C8.81 7.75 6 4 6 4L0 12H22L13 0Z" fill="#000"/>
    </svg>
);
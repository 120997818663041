import * as React from 'react';
import styled, { css } from 'styled-components';

import { statefullIconStylesHover } from '../Variables/mixins';
import { size } from '../Variables/sizes';
import { StyledPropsTheme } from '../Variables';

type Props = {
  small?: boolean;
};

export const FlatButton: React.FC<Props> = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>;
};

export const flatButtonStyles = css`
  background-color: transparent;
  color: ${(props: Props & StyledPropsTheme) => props.theme.colors.dark};
  font-size: ${props => props.theme.typo.fontSize};
  font-family: ${props => props.theme.typo.primaryFont};
  font-weight: ${props => props.theme.typo.regular};
  appearance: none;
  border: none;
  padding: ${(props: Props & StyledPropsTheme) =>
    props.small ? '18px 20px' : '30px 20px'};
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:hover {
    background: ${props => props.theme.colors.flatButtonHoverBackground};
  }
  ${statefullIconStylesHover};
  @media screen and (max-width: ${size.mobile}) {
    font-size: ${props => props.theme.typo.p2Size};
    padding: 18px 20px;
  }
`;

const Button = styled.button`
  ${flatButtonStyles};
  outline: none;
`;

import * as React from 'react';

export const DecreaseIcon = (props?: any) => {
  return (
    <svg
      width="14"
      height="2"
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 2H1C0.45 2 0 1.55 0 1C0 0.45 0.45 0 1 0H13C13.55 0 14 0.45 14 1C14 1.55 13.55 2 13 2Z"
        fill={props.fill || 'black'}
        fillOpacity="1"
      />
    </svg>    
  );
};

import * as React from 'react';

type Props = {
  className?: string;
};

export const CartIcon = ({ className }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
    >
      <path
          fill="url(#paint0_linear)"
          fillRule="evenodd"
          d="M1.996 3c0 .55.45 1 1 1h1l3.6 7.59-1.35 2.44c-.73 1.34.23 2.97 1.75 2.97h11c.55 0 1-.45 1-1s-.45-1-1-1h-11l1.1-2h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49a.996.996 0 00-.87-1.48h-14.8l-.67-1.43a.993.993 0 00-.9-.57h-1.64c-.55 0-1 .45-1 1zm6 15c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm8.01 2c0-1.1.89-2 1.99-2s2 .9 2 2-.9 2-2 2-1.99-.9-1.99-2z"
          clipRule="evenodd"
      ></path>
      <path
          fill="#000"
          fillRule="evenodd"
          d="M2.996 4c-.55 0-1-.45-1-1s.45-1 1-1h1.64c.38 0 .74.22.9.57L6.206 4h14.8c.76 0 1.24.82.87 1.48l-3.58 6.49c-.34.62-1 1.03-1.75 1.03h-7.45l-1.1 2h11c.55 0 1 .45 1 1s-.45 1-1 1h-11c-1.52 0-2.48-1.63-1.75-2.97l1.35-2.44L3.996 4h-1zm3.01 16c0-1.1.89-2 1.99-2s2 .9 2 2-.9 2-2 2-1.99-.9-1.99-2zm11.99-2c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zM7.724 7.447l1.5 3a1 1 0 00.894.553h5.861a1 1 0 00.82-.427l2.1-3A1 1 0 0018.079 6H8.618a1 1 0 00-.894 1.447z"
          clipRule="evenodd"
      ></path>
    </svg>
);

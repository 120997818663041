import * as React from 'react';

export const SettingsIcon = (props?: any) => (
    <svg
        {...props}
        width={70}
        height={70}
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x={0.5}
            y={0.5}
            width={69}
            height={69}
            rx={34.5}
            fill="#2DC6DB"
            stroke="#26A8BA"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.506 35c0 .793-.07 1.54-.163 2.287l4.923 3.85c.443.35.56.98.28 1.493l-4.667 8.073c-.28.514-.886.724-1.423.514l-5.81-2.334c-1.213.91-2.52 1.704-3.943 2.287l-.887 6.183c-.07.56-.56.98-1.143.98h-9.334c-.583 0-1.073-.42-1.143-.98l-.887-6.183a17.047 17.047 0 0 1-3.943-2.287l-5.81 2.334c-.513.186-1.143 0-1.423-.514l-4.667-8.073c-.28-.513-.163-1.143.28-1.493l4.923-3.85A18.512 18.512 0 0 1 17.506 35c0-.77.07-1.54.163-2.287l-4.923-3.85a1.15 1.15 0 0 1-.28-1.493l4.667-8.073c.28-.514.886-.724 1.423-.514l5.81 2.334c1.213-.91 2.52-1.704 3.943-2.287l.887-6.183c.07-.56.56-.98 1.143-.98h9.334c.583 0 1.073.42 1.143.98l.887 6.183a17.051 17.051 0 0 1 3.943 2.287l5.81-2.334c.513-.186 1.143 0 1.423.514l4.667 8.073c.28.513.163 1.143-.28 1.493l-4.923 3.85c.093.747.163 1.494.163 2.287Zm-25.668 0c0 4.503 3.664 8.167 8.167 8.167s8.167-3.664 8.167-8.167-3.663-8.167-8.167-8.167c-4.503 0-8.167 3.664-8.167 8.167Z"
            fill={props.fill || "#E1F5FE"}
        />
    </svg>
);
import * as React from 'react';
import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { Button } from './index';
import { EditIcon } from '../assets/icons/edit.icon';
import { size } from '../Variables/sizes';
import { shimmerStyles } from '../Variables/mixins';

import {
  VK as VKIcon,
  Mail as MailIcon,
  Inst as InstIcon,
  Arrow as ArrowIcon,
  OkIcon,
} from '../assets/icons';
import { MoreIcon } from '../assets/icons/more.icon';
import { AddGoodIcon } from '../assets/icons/addGood.icon';
import { StyledPropsTheme } from '../Variables';

type Icons =
  | 'vk'
  | 'inst'
  | 'mail'
  | 'arrow'
  | 'edit'
  | 'addGood'
  | 'ok'
  | 'more';

const getIcon = (type: Icons) => {
  switch (type) {
    case 'vk':
      return <VK type={type} />;
    case 'mail':
      return <Mail type={type} />;
    case 'inst':
      return <Inst type={type} />;
    case 'arrow':
      return <Arrow type={type} />;
    case 'ok':
      return <Ok type={type} />;
    case 'edit':
      return <EditIcon />;
    case 'addGood':
      return <AddGoodIcon />;
    case 'more':
      return <MoreIcon />;
    default:
      return null;
  }
};

export type IconButtonProps = {
  iconType: Icons;
  icon?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  load?: boolean;
  className?: string;
  square?: boolean;
  small?: boolean;
  transparent?: boolean;
  mobileCornered?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  filled?: boolean;
  children?: React.ReactNode;
};

export const IconButton: React.FC<IconButtonProps> = React.memo(
  ({ children, iconType, className, icon, ...rest }) => (
    <ButtonContainer iconType={iconType} className={className} {...rest}>
      <React.Fragment>
        {icon ? icon : getIcon(iconType)}
        {!rest.square && children}
      </React.Fragment>
    </ButtonContainer>
  )
);

IconButton.defaultProps = {
  iconType: 'vk',
  square: false,
  transparent: false,
};

const getBackground = (props: IconButtonProps & StyledPropsTheme) => {
  switch (props.iconType) {
    case 'vk':
      return css`
        background: ${(props: IconButtonProps & StyledPropsTheme) =>
          props.active
            ? props.theme.colors.gradientStart
            : props.theme.colors.vk};
        &:hover,
        &:focus {
          background: ${props.theme.colors.vkHover};
        }
        &:active {
          background: ${props.theme.colors.vkPushed};
        }
      `;
    case 'inst': {
      return css`
        background: ${(props: IconButtonProps & StyledPropsTheme) =>
          props.active ? props.theme.colors.error : props.theme.colors.inst};
        &:hover,
        &:focus {
          background: ${props.theme.colors.instHover};
        }
        &:active {
          background: ${props.theme.colors.instPushed};
        }
      `;
    }
    case 'ok': {
      return css`
        background: ${props.theme.colors.okru};
        &:hover,
        &:focus {
          background: ${props.theme.colors.okruHover};
        }
        &:active {
          background: ${props.theme.colors.okruPushed};
        }
      `;
    }
    case 'more': {
      return css`
        background: ${props.theme.colors.light};
        border: transparent;
        &:hover,
        &:focus {
          background: ${props.theme.colors.light};
          box-shadow: ${props.theme.colors.addButtonShadow};
        }
      `;
    }
    default:
      return null;
  }
};

const ButtonContainer = styled(Button)<StyledPropsTheme & IconButtonProps>`
  justify-content: flex-start;
  min-height: 50px;
  ${(props: IconButtonProps & StyledPropsTheme) =>
    props.load ? shimmerStyles : getBackground};

  ${props =>
    props.square &&
    css`
      border-radius: 50%;
      padding: 0;
      width: ${props => (props.small ? '40px' : '60px')};
      height: ${props => (props.small ? '40px' : '60px')};
      min-height: 0;
      align-content: center;
      justify-content: center;
      flex: none;
      min-width: 0;
      svg {
        width: auto;
        max-width: ${props => (props.small ? '24px' : 'none')};
        height: auto;
        max-height: ${props => (props.small ? '24px' : 'none')};
        margin-right: 0;
      }
      ${(props: StyledPropsTheme & IconButtonProps) =>
        props.transparent &&
        css`
          background: none;
          box-shadow: none;
          border: none;
        `}
      ${props =>
        props.mobileCornered &&
        css`
          @media screen and (max-width: ${size.mobile}) {
            width: 172px;
            height: 172px;
            position: absolute;
            justify-content: flex-end;
            padding-top: 60px;
            left: -90px;
            top: -90px;
            padding-right: 40px;
          }
        `}
    `}
`;

const commonIcon = (props: IconButtonProps & StyledPropsTheme) => `
  align-self: center;
  margin-right: ${props.iconType === 'arrow' ? 0 : '20px'};
`;

const VK = styled(VKIcon)`
  ${commonIcon};
`;

const Mail = styled(MailIcon)`
  ${commonIcon};
`;

const Inst = styled(InstIcon)`
  ${commonIcon}
`;

const Arrow = styled(ArrowIcon)`
  ${commonIcon}
`;

const Ok = styled(OkIcon)`
  ${commonIcon}
`;

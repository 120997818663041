import * as React from 'react';
import styled, { css } from 'styled-components';
import { StyledPropsTheme } from 'Variables';

import { CheckboxIcon } from '../assets/icons';
import { RadioLabel, RadioDescription, RadioLabelContainer } from './radio';

type CheckboxProps = {
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
  description?: React.ReactNode;
  name?: string;
  black?: boolean;
  width100?: boolean;
};

export const Checkbox = ({
  checked,
  onChange,
  label,
  description,
  name,
  black,
  width100,
  ...props
}: CheckboxProps) => {
  return (
    <Container width100={width100}>
      <BackgroundContainer checked={checked} black={black} {...props}>
        <Input
          checked={checked}
          onChange={onChange}
          name={name}
          type="checkbox"
        />
        <Background checked={checked} black={black}>
          <StyledCheckboxIcon checked={checked} black={black} />
        </Background>
      </BackgroundContainer>
      <RadioLabelContainer>
        {label && <RadioLabel checked={checked}>{label}</RadioLabel>}
        {description && <RadioDescription>{description}</RadioDescription>}
      </RadioLabelContainer>
    </Container>
  );
};

type UiBooleanProps = {
  checked?: boolean;
  black?: boolean;
};

type UiProps = StyledPropsTheme & UiBooleanProps;

const getOuterContainerBackground = (props: UiProps) => {
  if (props.black) {
    return css`
      background: ${props.checked
        ? props.theme.colors.dark
        : props.theme.colors.buttonActive};
      border: ${props.checked
        ? "none"
        : "1px solid " + props.theme.colors.buttonBorder};
    `;
  } else {
    return css`
      background: ${props.checked
        ? props.theme.colors.dark
        : props.theme.colors.buttonActive};
      border: 1px solid ${props => props.theme.colors.buttonBorder};
    `;
  }
};

const getInerContainerBackground = (props: UiProps) => {
  if (props.black) {
    return css`
      background: none;
    `;
  } else {
    return css`
      background: ${props.checked ? props.theme.colors.dark : null};
      /* background: ${props.checked ? props.theme.colors.inputFocused : null}; */
    `;
  }
};

const getIconColor = (props: UiProps) => {
  if (props.black) {
    return css`
      & > path {
        stroke: ${props.checked
        ? props.theme.colors.light
        : props.theme.colors.dark};
        opacity: ${props.checked ? '1' : '0.25'};
      }
    `;
  } else {
    return css`
      & > path {
        stroke: ${props.checked
        ? props.theme.colors.light
        : props.theme.colors.dark};
        opacity: ${props.checked ? '1' : '0.25'};
      }
    `;
  }
};

const StyledCheckboxIcon = styled(CheckboxIcon) <UiProps>`
  ${getIconColor}
`;

const Container = styled.label<{ width100?: boolean }>`
  display: flex;
  padding: 7px 0;
  width: ${(props) => props.width100 ? '100%' : 'auto'};
`;

const BackgroundContainer = styled.div<UiProps>`
  position: relative;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  ${getOuterContainerBackground}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const Background = styled.div<UiProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  ${getInerContainerBackground}
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 9px;
  }
`;

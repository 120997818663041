import styled, { css } from 'styled-components';
import { PrimaryRoundButton } from './RoundButton/roundButtonPrimary';
import { SmallButtonsStyles } from './smallButton';

export const PrimaryCartButton = styled(PrimaryRoundButton) <{
  buttonColor?: string;
  buttonColorHover?: string;
  rounding?: number;
}>`
  ${SmallButtonsStyles};
  // TODO: add fallback color
  ${(props) =>
    props.buttonColor &&
    css`
      background-color: ${props.buttonColor};
    `}
  color: ${(props) => props.theme.colors.light};
  ${(props) =>
    props.buttonColorHover &&
    css`
      &:hover {
        background-color: ${props.buttonColorHover};
      }
    `}
  border-radius: ${(props) => props.rounding}px;
  width: 100%;
`;
import React from "react";

export const MoreSectionsIcon = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="100"
            fill="none"
            viewBox="0 0 101 100"
            {...props}
        >
            <g clipPath="url(#clip0_13187_135258)">
                <path
                    fill="#EEE"
                    d="M88.516 1.596L67.239 98.404h31.915V1.596H88.516z"
                ></path>
                <path
                    fill="#DCDCDC"
                    d="M77.878 1.596L56.6 98.404h31.915V1.596H77.878z"
                ></path>
                <path
                    fill="#EEE"
                    d="M77.878 1.596v75.532L63.18 87.766l8.314-86.17h6.383z"
                ></path>
                <path
                    fill="#fff"
                    d="M71.495 1.596v75.532L56.6 98.404H2.346V1.596h69.149z"
                ></path>
                <path
                    fill="#2DC6DB"
                    d="M77.878 77.128L56.6 98.404V77.128h21.277z"
                ></path>
                <path
                    fill="#000"
                    d="M99.154 0H94.9a1.596 1.596 0 100 3.191h2.66V96.81h-2.66a1.596 1.596 0 100 3.191h4.255c.882 0 1.596-.715 1.596-1.596V1.596c0-.882-.714-1.596-1.596-1.596zM79.01 78.252c.29-.3.462-.69.463-1.12V1.597C79.473.714 78.76 0 77.878 0H2.346C1.464 0 .75.714.75 1.596v96.808c0 .605.341 1.157.882 1.427.337.169.337.169 2.77.169h45.816a1.596 1.596 0 100-3.191H3.941V3.19h72.34v72.34h-19.68c-.882 0-1.596.715-1.596 1.596v21.277a1.595 1.595 0 002.724 1.129l21.28-21.28zm-20.813 16.3V78.723h15.828L58.197 94.552z"
                ></path>
                <path
                    fill="#000"
                    d="M88.516 0h-4.255a1.596 1.596 0 100 3.191h2.66V96.81H65.49a1.596 1.596 0 100 3.191h23.026c.881 0 1.596-.715 1.596-1.596V1.596C90.112.714 89.397 0 88.516 0zM19.26 43.085h41.703a1.596 1.596 0 100-3.191H19.26a1.596 1.596 0 100 3.191zM19.26 34.575h41.703a1.596 1.596 0 100-3.192H19.26a1.596 1.596 0 100 3.192zM19.26 26.064h41.703a1.596 1.596 0 100-3.191H19.26a1.596 1.596 0 100 3.191zM62.559 50c0-.881-.715-1.596-1.596-1.596H19.26a1.596 1.596 0 100 3.192h41.703c.881 0 1.596-.715 1.596-1.596zM19.26 56.915a1.596 1.596 0 100 3.191h25.745a1.596 1.596 0 100-3.191H19.26z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_13187_135258">
                    <path
                        fill="#fff"
                        d="M0 0H100V100H0z"
                        transform="translate(.75)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
import * as React from 'react';
import styled, { css } from 'styled-components';
import DatepickerComponent from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isDate, clone } from 'lodash-es';
import { NextIcon, FlagIcon } from '../assets/icons';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import { SecondaryRoundButton } from "../Buttons/RoundButton/roundButtonSecondary";
import { createPortal } from 'react-dom';

type DatePickerProps = {
  innerProps: any;
  headerStyle?: 'select' | null;
  localizedMonths?: string[];
  localizedWeekDays?: { [key: string]: string };
  localizedNames: {
    month: string;
    year: string;
  };
  selectionMode?: 'oneDay' | 'multiDay' | 'range';
  selectedDate?: Date | null;
  selectedDates?: Date[] | null;
  includeDates?: Date[] | null;
  includeDatesAutoChange?: boolean;
  onChange?: (data: Date | (Date | null)[]) => void;
  uploadDays: Function;
  isMobil?: boolean;
  ignoreRangeEndSelection?: boolean;
};

export const DatepickerComponentUi = ({
  innerProps,
  headerStyle,
  localizedMonths,
  localizedWeekDays,
  localizedNames,
  selectionMode = 'oneDay',
  selectedDate,
  selectedDates,
  includeDates,
  includeDatesAutoChange,
  ignoreRangeEndSelection,
  onChange,
  uploadDays,
  isMobil = false,
}: DatePickerProps) => {
  const [pickedDate, setPickedDate] = React.useState<Date | null>(
    (selectionMode === 'multiDay' || selectionMode === 'oneDay') && selectedDate ? selectedDate : null
  );
  const [startDate, setStartDate] = React.useState<Date | null>(
    selectionMode === 'range' && selectedDates && selectedDates.length > 1
      ? selectedDates[0]
      : null
  );
  const [endDate, setEndDate] = React.useState<Date | null>(
    selectionMode === 'range' && selectedDates && selectedDates.length > 1
      ? selectedDates[1]
      : null
  );

  const autoWindowButton = () => {
    if (window.innerWidth < 465) {
      let remainsWindow = (465 - window.innerWidth) / 14
      return `${5 - remainsWindow}`
    }
    return '5'
  }

  const [highlitedDates, setHighlitedDays] = React.useState<Date[]>(
    selectionMode === 'multiDay' && selectedDates ? selectedDates : []
  );

  const [isOpen, setIsOpen] = React.useState<any>(true);
  const [controllerSelect, setControllerSelect] = React.useState<Element>()
  const years = range(1990, getYear(new Date()) + 6, 1);

  const months = localizedMonths || [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const names = localizedNames || {
    month: "Month",
    year: "Year",
  };

  const startDatepicker = () => {
    setIsOpen(!isOpen);
    setPickedDate(new Date())
  };

  React.useEffect(() => {
    if (isOpen === false) {
      setIsOpen(true);
    }
  }, [isOpen]);

  const [effectHandleAutoClick, setEffectHandleAutoClick] = React.useState(true)

  React.useEffect(() => {
    if (effectHandleAutoClick && onChange && includeDates && (includeDates?.length > 0) && includeDatesAutoChange) {
      setPickedDate(includeDates[0]);
      onChange(includeDates[0]);
      setEffectHandleAutoClick(false)
    }
  }, [includeDates?.length])

  const onDateSelect = (date: Date | [Date | null, Date | null] | null) => {
    if (isDate(date)) {
      if (selectionMode === 'multiDay') {
        const dates = clone(highlitedDates);
        let dateIndex = -1;
        dates.forEach((arrDate, index) => {
          if (arrDate.getTime() === date.getTime()) {
            dateIndex = index;
          }
        });
        if (dateIndex >= 0) {
          dates.splice(dateIndex, 1);
          setHighlitedDays(dates);
          if (onChange) {
            onChange(dates);
          }
        } else {
          dates.push(date);
          setHighlitedDays(dates);
          if (onChange) {
            onChange(dates);
          }
        }
      }
      if (selectionMode === 'oneDay') {
        setPickedDate(date);
        if (onChange) {
          onChange(date);
        }
      }
    }
  };

  const handleUploadDays = () => {
    uploadDays();
  }

  const onRangeSelect = (dates: Date | [Date | null, Date | null] | null) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      if (start && onChange && ( end ||  ignoreRangeEndSelection)) {
        onChange([start, end]);
      }
    }
  };

  const weekDays: { [key: string]: string } = localizedWeekDays || {
    monday: "Mo",
    tuesday: "Tu",
    wednesday: "We",
    thursday: "Th",
    friday: "Fr",
    saturday: "Sa",
    sunday: "Su"
  }

  const buttonDayCalendar = (day: number | undefined) => {
    return (
      <SecondaryRoundButton load={false} round={true} size={isMobil ? 'small' : 'large'}>
        {day}
      </SecondaryRoundButton>
    )
  }

  React.useEffect(() => {
    const _controllerSelect = document.querySelector("#controllerSelect")
    if (_controllerSelect) setControllerSelect(_controllerSelect)
  }, [])

  return (
    <>
      {isOpen && (
        <Styles selectionMode={selectionMode} headerStyle={headerStyle} isMobil={isMobil} autoWindowButton={autoWindowButton}>
          <DatepickerComponent
            selected={pickedDate}
            startDate={startDate}
            endDate={endDate}
            highlightDates={highlitedDates}
            formatWeekDay={day => weekDays[day.toLowerCase()]}
            renderDayContents={buttonDayCalendar}
            onChange={(date, e) => {
              e?.preventDefault();
              e?.stopPropagation();
              return selectionMode === 'range'
                ? onRangeSelect(date)
                : onDateSelect(date)
            }}
            selectsRange={selectionMode === 'range'}
            minDate={new Date()}
            inline
            locale="ru-RU"
            calendarStartDay={1}
            includeDates={includeDates}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) =>
              headerStyle === 'select' ? (
                <>
                  <ButtonSelectLeft
                    type="button"
                    onClick={() => { decreaseMonth() }}
                    disabled={prevMonthButtonDisabled}
                  >
                    <ButtonSelectLeftContainer>
                      <NextIcon />
                    </ButtonSelectLeftContainer>
                  </ButtonSelectLeft>
                  <ButtonSelectRight
                    type="button"
                    onClick={() => { increaseMonth(); handleUploadDays() }}
                    disabled={nextMonthButtonDisabled}
                  >
                    <ButtonSelectLeftContainer>
                      <NextIcon />
                    </ButtonSelectLeftContainer>
                  </ButtonSelectRight>

                  {controllerSelect &&
                    createPortal(
                      <>
                        {!isMobil &&
                          <ButtonSelectButton onClick={() => startDatepicker()}>
                            <FlagIcon />
                          </ButtonSelectButton>
                        }
                        {isMobil &&
                          <ContainerButtonControler>
                            <ContainerButtonControlerLoyaut>
                              <ButtonSelectLeftContainerMobile onClick={() => { decreaseMonth() }}>
                                <NextIcon />
                              </ButtonSelectLeftContainerMobile>
                              <ButtonSelectButtonMobile onClick={() => startDatepicker()}>
                                <FlagIcon />
                              </ButtonSelectButtonMobile>
                              <ButtonSelectLeftContainerMobile onClick={() => { increaseMonth(); handleUploadDays() }}>
                                <NextIcon />
                              </ButtonSelectLeftContainerMobile>
                            </ContainerButtonControlerLoyaut>
                          </ContainerButtonControler>
                        }
                      </>,
                      controllerSelect
                    )}

                  <HeaderContainer>
                    <HeaderSelect
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <SelectContainer>
                        <SelectTitle>{names.month}</SelectTitle>
                        <SelectLoyaut>
                          <Select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </Select>
                          <SelectArrow>↓</SelectArrow>
                        </SelectLoyaut>
                      </SelectContainer>
                      <SelectContainer>
                        <SelectTitle>{names.year}</SelectTitle>
                        <SelectLoyaut>
                          <Select
                            value={getYear(date)}
                            onChange={({ target: { value } }) =>
                              changeYear(parseInt(value))
                            }
                          >
                            {years.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </Select>
                          <SelectArrow>↓</SelectArrow>
                        </SelectLoyaut>
                      </SelectContainer>
                    </HeaderSelect>
                  </HeaderContainer>
                </>
              ) : (
                <HeaderContainerSlider>
                  <HeaderSlider>
                    <ArrowIconDown onClick={() => { decreaseMonth() }}>
                      <NextIcon />
                    </ArrowIconDown>
                    <DateSliderHeader>
                      <Month>
                        <span>{months[getMonth(date)]}</span>
                      </Month>
                      <Year>
                        <span>{getYear(date)}</span>
                      </Year>
                    </DateSliderHeader>
                    <ArrowIconNext onClick={() => { increaseMonth(); handleUploadDays() }}>
                      <NextIcon />
                    </ArrowIconNext>
                  </HeaderSlider>
                </HeaderContainerSlider>
              )
            }
            {...innerProps}
          />
          <div id="controllerSelect">
          </div>
        </Styles>
      )}
    </>
  );
};

const SelectLoyaut = styled.div`
    position: relative;
    padding-right: 11px;
    display: inline-block;

`

const SelectArrow = styled('div')`
  right: 11px;
  margin-top: 9px;
  font-family: system-ui;
  position: absolute;
  font-size: 10px;
  top: 7px;
  color: ${props => props.theme.colors.dark};
  z-index: 1;
`

const Styles = styled.div<{
  selectionMode: 'oneDay' | 'multiDay' | 'range';
  headerStyle?: null | 'select';
  isMobil: boolean;
  autoWindowButton: Function;
}>`
  font-family: ${props => props.theme.typo.primaryFont};
  font-weight: ${props => props.theme.typo.regular};

  ${props =>
    props.headerStyle === 'select' &&
    css`
      margin: 0 78px;
      
      ${!props.isMobil && `
      width: 500px;
      height: 541px;
      `}
    `}

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  & > div:nth-child(1),
  .react-datepicker {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: inherit;
  }
  .react-datepicker__month-container {
    width: 100%;
    background: white;
  }
  .react-datepicker__week {
    ${props =>
    props.isMobil &&
    css`
        display: flex;
        justify-content: center;
      `}
  }
  .react-datepicker__day {
    margin: ${props => props.autoWindowButton()}px;
    width: unset;
    ${props =>
    props.isMobil &&
    css`
        flex-grow: 1;
        display: flex;
      `}

    & > button {
      ${props =>
    props.isMobil &&
    css`
          flex-grow: 1;
          width: 40px;
          background: #FFF0;
        `}
    }
  }

  .react-datepicker__day:hover {
    background: unset;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: unset;
    ${props =>
    props.selectionMode !== 'range' &&
    css`
        background: white;
        color: black;
      `}
  }

  .react-datepicker__day--disabled {
    background: #ffffff;
    opacity: 0.15;
    pointer-events: none;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--highlighted {
    background: transparent;
    & > button {
      border: 2px solid #000000;
    }
  }

  .react-datepicker__header {
    background: none;
    border: none;
    padding: 0;
  }

  .react-datepicker__day-names {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props =>
    props.isMobil &&
    css`
        padding: 10px 0;
        margin: 0 0.4rem;
      `}
    & div:nth-last-child(1),& div:nth-last-child(2) {
      color: #ff4081;
    }
  }

  .react-datepicker__day-name {
    font-family: ${props => props.theme.typo.primaryFont};
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: 0 5px;
    font-size: 14px;
    ${props =>
    props.isMobil &&
    css`
        flex-grow: 1;
        display: flex;
        justify-content: center;
      `}
  }

  .react-datepicker__day--in-range {
    background: transparent;
    & > button {
      border: 2px solid #000000;
    }
  }

  .react-datepicker__day--in-selecting-range {
    background-color: transparent;
    & > button {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        inset 0px 0px 2px rgba(0, 0, 0, 0.25),
        inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    }
  }
`;

const HeaderContainer = styled.div`
  height: 89px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonSelect = css`
  height: 541px;
  position: absolute;
  width: 68px;
  background: rgba(159, 159, 159, 0.15);
  backdrop-filter: blur(10px);
  border: none;
  cursor: pointer;
  svg {
    height: 16px;
  }
  &:disabled {
    cursor: no-drop;
    opacity: 0.4;
  }
`;

const ButtonSelectLeftContainer = styled.div`
  background: rgb(255 255 255 / 32%);
  border: 1px solid rgb(255 255 255);
  box-sizing: border-box;
  margin: 5px;
  padding: 13px 0;
  padding-bottom: 11px;
  border-radius: 100px;
`;

const ButtonSelectRight = styled.button`
  ${ButtonSelect};
  right: -78px;
  border-radius: 8px 80px 80px 8px;
  svg {
    transform: rotate(180deg);
  }
`;

const ButtonSelectButton = styled.div`
  cursor: pointer;
  position: absolute;
  margin: 25px 0;
  left: 50%;
  transform: translateX(-39px);

    background: rgba(159, 159, 159, 0.15);
    backdrop-filter: blur(5px);
    border-radius: 99px;
    padding: 17px 17px 14px 17px;

`;

const ButtonSelectLeft = styled.button`
  ${ButtonSelect};
  left: -78px;
  border-radius: 80px 8px 8px 80px;
`;

const HeaderContainerSlider = styled.div`
  height: 89px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 46px;
`;

const ArrowIconNext = styled.div`
  transform: rotate(180deg);
  height: 16px;
  cursor: pointer;
  margin-top: 5px;
`;

const ArrowIconDown = styled.div`
  height: 16px;
  cursor: pointer;
  margin-top: 5px;
`;

const HeaderSelect = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
`;

const Select = styled.select`
  position: relative;
  z-index: 2;
  background: #fff0;
  padding-right: 12px;
  //width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-family: ${props => props.theme.typo.primaryFont};
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  flex-grow: 0;
  margin: 10px 0px;
  /* margin-left: -3px; */
  font-weight: 300;
`;

const SelectTitle = styled.span`
  font-family: ${props => props.theme.typo.primaryFont};
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: flex-end;
  color: #000000;
  opacity: 0.75;
  font-weight: 300;
`;

const SelectContainer = styled.div`
  padding: 10px;
  text-align: left;
`;

const HeaderSlider = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Year = styled.div``;
const Month = styled.div`
  margin-right: 7px;
  span {
  }
`;

const DateSliderHeader = styled.div`
  display: flex;
  span {
    font-family: ${props => props.theme.typo.primaryFont};
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
`;

const ContainerButtonControler = styled.div`
  position: absolute;
  height: fit-content;
  left: 50%;
  transform: translateX(-114px);
  margin-top: 25px;
`;

const ButtonSelectLeftContainerMobile = styled(ButtonSelectLeftContainer)`
  background: #fff;
  padding: 13px;
  height: 43px;
`;


const ContainerButtonControlerLoyaut = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(159, 159, 159, 0.15);
  backdrop-filter: blur(5px);
  border-radius: 80px;
  padding: 14px;
  gap: 19px;
  >div:nth-child(3) {
    transform: rotate(180deg);
  }
`;

const ButtonSelectButtonMobile = styled.div`
  cursor: pointer;
  /* margin: 25px 0; */
  height: fit-content;
`;
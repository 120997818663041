export type Category = {
  id: string;
  name: string;
  parent_id: string | null;
  children: Category[];
  position_index: number;
};

export const flattenCategories = (
  categories: Category[],
  flatCategories: Record<string, Category> = {}
) => {
  categories.forEach(category => {
    flatCategories[category.id] = category;
    if (category.children && category.children.length > 0) {
      flattenCategories(category.children, flatCategories);
    }
  });
  return flatCategories;
};

export const filterCategoryByParent = (
  categories: Record<string, Category>,
  parentId: string | null
) => {
  const newCategories = Object.values(categories)
    .filter(category => category.parent_id === parentId)
    .sort((a, b) => {
      return a.position_index - b.position_index;
    });
  return newCategories;
};

// TODO: add position
export const filterBySearchValue = (
  categories: Record<string, Category>,
  searchValue: string
) => {
  return Object.values(categories).filter(category => {
    const searchLowerCase = searchValue.toLowerCase();
    const nameLowerCased = category.name.toLowerCase();
    return nameLowerCased.indexOf(searchLowerCase) >= 0;
  });
};

export const flattenCategoriesBreadcrumb = (
  id: string,
  flatCategories: Record<string, Category> = {},
  breadcrumbs: {
    name: string;
    id: string;
  }[] = []
) => {
  const activeCategory = flatCategories[id];
  if (activeCategory) {
    breadcrumbs.unshift({
      name: activeCategory.name,
      id: activeCategory.id,
    });
    if (activeCategory.parent_id) {
      flattenCategoriesBreadcrumb(
        activeCategory.parent_id,
        flatCategories,
        breadcrumbs
      );
    }
  }
  return breadcrumbs;
};

import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';

import {
  PopupContainer,
  PopupButton,
  PopupTitle,
  PopupButtonsContainer,
  BlankButton,
  PopupBackground,
} from './Popup';
import { B } from '../Text/B';
import { PopupScrollContainer } from './PopupScrollContainer';

type Props = {
  items: {
    name: string;
    value: any;
  }[];
  title?: string;
  onClick: (props: any) => void;
  onClose: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleButtons: (index: number) => void;
  buttons: (string | undefined)[];
};

export const PopupList: React.FC<Props> = React.memo(
  ({ items, title, onClick, buttons, handleButtons, onClose }) => {
    const handleClick = useCallback(
      (e, index) => {
        e.preventDefault();
        onClick(items[index].value);
      },
      [onClick]
    );

    const handleButtonClick = useCallback(
      e => {
        e.preventDefault();
        const index = buttons.indexOf(e.target.innerText);
        handleButtons(index);
      },
      [handleButtons]
    );

    return (
      <PopupScrollContainer>
        <PopupBackground onClick={onClose} />
        <PopupContainer data-testid="popupList">
          {title && (
            <PopupTitle>
              <B>{title}</B>
            </PopupTitle>
          )}
          <ListContainer>
            {items.map((item, index) => (
              <PopupLink
                onClick={e => handleClick(e, index)}
                key={index}
                data-testid="popupLink"
              >
                {item.name}
              </PopupLink>
            ))}
          </ListContainer>
          {buttons && (
            <PopupButtonsContainer>
              {buttons.map((button, index) =>
                button ? (
                  <PopupButton
                    onClick={handleButtonClick}
                    key={button + index}
                    disabled={!button}
                  >
                    {button}
                  </PopupButton>
                ) : (
                  <BlankButton key={index} />
                )
              )}
            </PopupButtonsContainer>
          )}
        </PopupContainer>
      </PopupScrollContainer>
    );
  }
);

const ListContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

const PopupLink = styled(PopupButton)`
  text-align: left;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  color: ${props => props.theme.colors.dark};
  &:hover {
    background: ${props => props.theme.colors.cardHover};
  }
`;

PopupList.defaultProps = {
  items: [],
};

import * as React from 'react';
import { memo, useCallback, MouseEvent, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { size } from '../Variables/sizes';

import { P1 } from '../Text/P1';
import { P2 } from '../Text/P2';
import { B } from '../Text/B';
import { Divider } from '../Menus/divider';
import { textButton } from '../Variables/mixins';
import { PopupScrollContainer } from './PopupScrollContainer';

type ButtonProps = {
  disabled?: boolean;
  type?: 'error';
  name?: string;
};

type Props = {
  title?: string;
  text?: React.ReactNode;
  wide?: boolean;
  customBackground?: string;
  hasScroll?: boolean;
  handleButtons: (index: number) => void;
  onClose: (e: MouseEvent<HTMLDivElement>) => void;
  buttons?: (string | ButtonProps | undefined)[];
  img?: string;
  hits?: boolean;
};

export const Popup: React.FC<Props> = memo(
  ({ title, text, buttons, handleButtons, onClose, wide, hasScroll, customBackground, img, hits}) => {
    const handleButtonClick = useCallback(
      (e, index) => {
        e.preventDefault();
        handleButtons(index);
      },
      [handleButtons]
    );
    return (
      <PopupScrollContainer hasScroll={hasScroll}>
        <PopupBackground onClick={onClose} />
        <PopupContainer customBackground={customBackground} data-testid="popup" wide={wide} hasScroll={hasScroll} hits={hits}>
          <Fragment>
            {img && <StyledImg src={img} hits={hits}/>
            }
            {title && (
              <PopupTitle hits={hits}>
                <B>{title}</B>
              </PopupTitle>
            )}
            {text && typeof text === 'string' ? <Text hits={hits}>{text}</Text> : text}
            {buttons && <StyledDivider hits={hits}/>}
            {buttons && (
              <PopupButtonsContainer>
                {buttons.map((button, index) => {
                  return button ? (
                    typeof button == 'string' ? (
                      <PopupButton
                        data-testid="popup-button"
                        onClick={e => handleButtonClick(e, index)}
                        key={index}
                      >
                        {button}
                      </PopupButton>
                    ) : (
                      <PopupButton
                        data-testid="popup-button"
                        onClick={e => handleButtonClick(e, index)}
                        key={index}
                        customType={button.type}
                        disabled={button.disabled}
                      >
                        {button.name}
                      </PopupButton>
                    )
                  ) : (
                    <BlankButton />
                  );
                })}
              </PopupButtonsContainer>
            )}
          
          </Fragment>
        </PopupContainer>
      </PopupScrollContainer>
    );
  }
);

export const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: ${props => props.theme.colors.buttonPushed};
  z-index: 10;
  width: 100%;
`;

export const PopupContainer = styled.div<{
  wide?: boolean;
  hasScroll?: boolean;
  customBackground?: string;
  hits?: boolean;
}>`
  padding-top: ${props => props.hits ? "0" : "20px"};
  background: ${props => props.customBackground ? props.customBackground : props.theme.colors.light};
  box-shadow: ${props => props.theme.colors.cardShadow};
  border-radius: 10px;
  max-width: ${props => !props.hits ? (props.wide ? '620px' : '380px') : '300px'};
  width: calc(100% - 20px);
  text-align: center;
  position: relative;
  z-index: 11;
  max-height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  ${props =>
    props.hasScroll &&
    css`
      max-height: none;
      margin: 120px auto;
      @media screen and (max-width: ${size.mobile}) {
        margin: 0;
      }
    `}
`;

export const PopupTitle = styled(P1)<{
  hits?: boolean;
}>`
  color: ${props => props.theme.colors.dark};
  padding: 0 20px;
  margin: ${props => props.hits ? "20px 20px 0 20px;" : "10px 0"};
`;

const Text = styled(P2)<{
  hits?: boolean;
}>`
  color: ${props => props.theme.colors.dark};
  ${props => props.hits && "text-align: left;"};
  padding: ${props => props.hits ? "20px 20px 10px 20px" : '0 20px'};
`;

export const PopupButtonsContainer = styled.div`
  display: flex;
`;

export const PopupButton = styled.button<{
  customType?: 'error';
}>`
  ${textButton};
  flex: 1;
  padding: 15px 0;
  cursor: pointer;
  margin: 0;
  color: ${props =>
    props.customType === 'error'
      ? props.theme.colors.error
      : props.theme.colors.gradientStart};
  color: ${props => props.disabled && props.theme.colors.buttonActive};
  &:hover {
    background: ${props => props.theme.colors.cardHover};
  }
`;
export const BlankButton = styled.div`
  flex: 1;
`;

const StyledDivider = styled(Divider)<{
  hits?: boolean;
}>`
  margin-top: ${props => props.hits ? "10px" : "25px"};  
 
`;

const StyledImg = styled.img<{
  hits?: boolean;
}>`
  margin: ${props => props.hits ? "20px 20px 0 20px" : "20px"};
  border-radius: 10px;
`;
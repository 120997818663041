import styled, { css } from 'styled-components';

export const p1Styles = css`
  font-family: ${props => props.theme.typo.primaryFont};
  line-height: ${props => props.theme.typo.lineHeight};
  font-size: 16px;
`;

export const P1 = styled.p`
  ${p1Styles};
`;

import { FC } from 'react';
import { Button, ButtonProps } from './index';
import styled, { css } from 'styled-components';

export const DefaultButton: FC<ButtonProps> = styled(Button)`
  ${props =>
    !props.load &&
    css`
      background-color: ${props => props.theme.colors.gradientStart};
      &:hover {
        background: ${props => props.theme.colors.primaryButtonHover};
      }
      &:active {
        background: ${props => props.theme.colors.primaryButtonActive};
      }
    `}
  ${props =>
    props.disabled &&
    !props.load &&
    css`
      background: ${props => props.theme.colors.primaryDisabled};
      border: transparent;
      &:hover,
      &:active {
        background: ${props => props.theme.colors.primaryDisabled};
        border: transparent;
      }
    `}
`;

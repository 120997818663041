import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { Category } from 'utils/flatten';
import { Divider } from '../../Menus/divider';
import { PopupCategoriesSimpleListItem } from './PopupCategoriesSimpleListItem';
import { PrimaryRoundButton } from '../../Buttons/RoundButton/roundButtonPrimary';

type Props = {
  isMobile: boolean;
  isAdmin?: boolean;
  children: Category[];
  onAdminClick?: () => void;
  allCategoriesLabel: string;
  parent: "" | Category | null;
  editCategoriesLabel?: string;
  onItemClick: (id: string) => void;
  onParentClick: (id: string) => void;
};

export const PopupCategoriesSimpleList: React.FC<Props> = memo(
  ({
    parent,
    isAdmin,
    children,
    isMobile,
    onItemClick,
    onAdminClick,
    onParentClick,
    allCategoriesLabel,
    editCategoriesLabel,
  }) => {
    return (
      <Container isMobile={isMobile}>
        {children.map(category => (
          <PopupCategoriesSimpleListItem
            id={category.id}
            key={category.id}
            onItemClick={onItemClick}
            nested={category.children}
            onParentClick={onParentClick}
          >
            {category.name}
          </PopupCategoriesSimpleListItem>
        ))}
        {
          <PopupCategoriesSimpleListItem
            isAllCategories
            onItemClick={onItemClick}
            id={parent ? parent.id : ''}
            onParentClick={onParentClick}
            key={parent ? parent.id : undefined}
          >
            {allCategoriesLabel}
          </PopupCategoriesSimpleListItem>
        }
        {isAdmin
          && <>
            <Divider />
            <ButtonWrapper>
              <StyledPrimaryRoundButton
                load={false}
                onClick={onAdminClick}
              >
                {editCategoriesLabel}
              </StyledPrimaryRoundButton>
            </ButtonWrapper>
          </>
        }
      </Container>
    );
  }
);

const StyledPrimaryRoundButton = styled(PrimaryRoundButton)`
  width: 100%;
  margin: 0 auto;
`

const ButtonWrapper = styled.div`
  margin: 20px;
`

const Container = styled.div<{
  isMobile?: boolean;
}>`
  ${props => !props.isMobile && `
    width: 480px; 
    margin: 0 auto; 
    border-radius: 10px;
  `}
  background-color: ${props => props.theme.colors.headerFormBackground};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  overflow: auto;
`;

import React from "react";

export const BookingIcon = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="100"
            fill="none"
            viewBox="0 0 101 100"
            {...props}
        >
            <g clipPath="url(#clip0_13187_135093)">
                <path
                    fill="#2DC6DB"
                    d="M45.598 98.534c24.19 0 43.8-19.61 43.8-43.8 0-24.19-19.61-43.8-43.8-43.8-24.19 0-43.8 19.61-43.8 43.8 0 24.19 19.61 43.8 43.8 43.8z"
                ></path>
                <path
                    fill="#fff"
                    d="M45.598 89.148c19.007 0 34.414-15.408 34.414-34.414S64.605 20.32 45.598 20.32c-19.006 0-34.414 15.408-34.414 34.414s15.408 34.414 34.414 34.414z"
                ></path>
                <path
                    fill="#2DC6DB"
                    d="M45.598 79.762c13.823 0 25.029-11.205 25.029-25.028 0-13.823-11.206-25.029-25.029-25.029-13.822 0-25.028 11.206-25.028 25.029s11.206 25.028 25.028 25.028z"
                ></path>
                <path
                    fill="#fff"
                    d="M45.598 70.377c8.64 0 15.643-7.004 15.643-15.643 0-8.64-7.003-15.643-15.643-15.643-8.639 0-15.642 7.004-15.642 15.643 0 8.64 7.003 15.643 15.642 15.643z"
                ></path>
                <path
                    fill="#FF4081"
                    d="M45.536 60.999a6.195 6.195 0 100-12.39 6.195 6.195 0 000 12.39zM69.8 30.442l9.385-.017L98.7 10.835l-9.385.017-.018-9.385-19.516 19.59.017 9.385z"
                ></path>
                <path
                    fill="#000"
                    d="M83.68 33.032a1.467 1.467 0 00-.612 1.982c3.169 6.008 4.85 12.8 4.863 19.64.044 23.343-18.91 42.37-42.253 42.413h-.081c-23.305 0-42.288-18.938-42.332-42.253C3.221 31.47 22.176 12.445 45.52 12.4h.079c6.816 0 13.587 1.657 19.585 4.793a1.466 1.466 0 101.358-2.6c-6.416-3.354-13.656-5.126-20.944-5.126h-.084c-12.09.023-23.45 4.753-31.983 13.318C4.997 31.352.31 42.728.332 54.82.355 66.91 5.085 78.27 13.65 86.802 22.196 95.315 33.537 100 45.596 100h.087c12.091-.023 23.45-4.752 31.983-13.318 8.534-8.566 13.22-19.942 13.198-32.033-.014-7.313-1.813-14.576-5.202-21.003a1.466 1.466 0 00-1.981-.614z"
                ></path>
                <path
                    fill="#000"
                    d="M65.547 26.65a1.467 1.467 0 00-.431-2.029 35.74 35.74 0 00-19.517-5.767h-.068C25.747 18.89 9.681 35.017 9.718 54.8c.037 19.763 16.125 35.815 35.88 35.814h.068c9.584-.018 18.588-3.768 25.352-10.557 6.764-6.79 10.48-15.807 10.461-25.391a35.743 35.743 0 00-5.837-19.557 1.466 1.466 0 10-2.454 1.607 32.817 32.817 0 015.358 17.955c.034 18.168-14.718 32.976-32.885 33.01h-.063c-18.139 0-32.912-14.74-32.947-32.886-.034-18.167 14.719-32.975 32.886-33.009h.063c6.389 0 12.584 1.83 17.918 5.294.68.441 1.588.248 2.029-.431z"
                ></path>
                <path
                    fill="#000"
                    d="M60.073 34.26a1.467 1.467 0 00-.469-2.02 26.425 26.425 0 00-14.005-4h-.05c-14.61.027-26.473 11.935-26.445 26.544C19.13 69.377 31.01 81.23 45.598 81.23h.05c14.61-.028 26.473-11.935 26.445-26.544a26.427 26.427 0 00-4.053-14.04 1.467 1.467 0 00-2.483 1.56A23.497 23.497 0 0169.16 54.69c.025 12.992-10.525 23.582-23.517 23.606h-.045c-12.971 0-23.537-10.54-23.561-23.517-.024-12.992 10.526-23.582 23.517-23.606h.045c4.418 0 8.724 1.229 12.453 3.556a1.467 1.467 0 002.02-.468z"
                ></path>
                <path
                    fill="#000"
                    d="M53.723 41.283a1.467 1.467 0 00-.682-1.958 16.942 16.942 0 00-7.441-1.7h-.034c-9.434.018-17.095 7.707-17.077 17.141a16.998 16.998 0 005.034 12.089 16.995 16.995 0 0012.075 4.988h.033c9.434-.017 17.094-7.707 17.077-17.141a16.946 16.946 0 00-1.725-7.463 1.466 1.466 0 10-2.636 1.287 14.036 14.036 0 011.428 6.181c.014 7.817-6.333 14.189-14.15 14.203h-.027a14.084 14.084 0 01-10.005-4.133 14.084 14.084 0 01-4.17-10.016c-.015-7.817 6.332-14.189 14.149-14.203h.027c2.161 0 4.235.473 6.165 1.407a1.467 1.467 0 001.959-.682z"
                ></path>
                <path
                    fill="#000"
                    d="M44.587 50.05a1.466 1.466 0 10-.616-2.867c-3.54.76-6.103 3.941-6.096 7.566.008 4.254 3.471 7.709 7.724 7.709h.014c3.653-.007 6.83-2.607 7.556-6.183a1.466 1.466 0 10-2.875-.582 4.804 4.804 0 01-4.686 3.832h-.01a4.796 4.796 0 01-4.79-4.782 4.81 4.81 0 013.779-4.693z"
                ></path>
                <path
                    fill="#000"
                    d="M100.055 10.27a1.466 1.466 0 00-1.354-.902h-.003l-7.919.015-.015-7.919A1.466 1.466 0 0088.26.432l-19.516 19.59a1.467 1.467 0 00-.428 1.038l.016 8.778L44.56 53.699a1.467 1.467 0 002.078 2.07L70.41 31.908l8.778-.017c.39 0 .762-.156 1.036-.431L99.74 11.87c.419-.42.543-1.052.315-1.6zm-21.48 18.69l-5.238.01L85.67 16.588a1.467 1.467 0 00-2.078-2.07l-12.334 12.38-.01-5.237L87.84 5.01l.01 5.845c.001.277.08.534.213.755.257.424.722.709 1.254.709h.002l5.846-.012-16.59 16.652z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_13187_135093">
                    <path
                        fill="#fff"
                        d="M0 0H100V100H0z"
                        transform="translate(.25)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
import * as React from "react";

export const SelectionIcon = (props?: any) => (
    <svg
        {...props}
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M7 7.02H12V18H7V7.02ZM14 18H17C18.1 18 19 17.1 19 16V7H14V18ZM17 0H2C0.9 0 0 0.9 0 2V5H19V2C19 0.9 18.1 0 17 0ZM0 16C0 17.1 0.9 18 2 18H5V7H0V16Z" fill="#000"/>
    </svg>
);
import * as React from 'react';

export const VisaIcon = (props: any) => (
  <svg
    {...props}
    width="40"
    height="13"
    viewBox="0 0 40 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1275 0.185614L9.83751 12.7145H6.40366L3.89788 2.7842C3.80507 2.32017 3.52665 1.94894 3.15543 1.67052C2.13455 1.20649 1.02087 0.835261 0 0.649648L0.092807 0.278419H5.66121C6.40366 0.278419 6.9605 0.835261 7.14612 1.48491L8.53822 8.72383L11.8793 0.185614H15.1275ZM28.4917 8.72383C28.4917 5.38279 23.9441 5.19718 23.9441 3.71227C23.9441 3.24823 24.4082 2.7842 25.3362 2.69139C26.3571 2.59859 27.5636 2.7842 28.4917 3.24823L29.0485 0.556841C28.1204 0.185614 27.0067 0 25.9859 0C22.7376 0 20.5103 1.76333 20.5103 4.1763C20.5103 6.03244 22.1808 6.9605 23.3873 7.61015C24.5938 8.2598 25.1506 8.63102 25.1506 9.09506C25.1506 9.93032 24.1297 10.3015 23.2017 10.3015C22.088 10.3015 20.8815 10.0231 19.8606 9.55909L19.211 12.2505C20.3247 12.7145 21.624 12.9001 22.8304 12.9001C26.2643 12.9929 28.4917 11.2296 28.4917 8.72383ZM37.0299 12.8073H39.9997L37.3083 0.278419H34.5241C33.9673 0.278419 33.4104 0.649646 33.132 1.20649L28.306 12.9001H31.7399L32.3895 11.044H36.5658L37.0299 12.8073ZM33.4104 8.3526L35.1737 3.71227L36.1018 8.3526H33.4104ZM19.675 0.185614L16.9836 12.7145H13.7354L16.4268 0.185614H19.675Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-0.133085"
        y1="6.49647"
        x2="39.9922"
        y2="6.49647"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#241E5C" />
        <stop offset="1" stopColor="#004D9F" />
      </linearGradient>
    </defs>
  </svg>
);

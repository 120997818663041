import * as React from 'react';

export const SelectArrowIcon = (props: any) => (
  <svg
    {...props}
    width="10"
    height="5"
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 3L7 1L3 1L5 3Z"
      stroke="#3F51B5"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

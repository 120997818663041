import * as React from 'react';
import styled from 'styled-components';

import { DeleteIcon } from '../assets/icons/delete.icon';
import { buttonAppearanceReset } from '../Variables/mixins';
import { ButtonProps } from 'Buttons';

export const DeleteButton: React.FC<ButtonProps> = props => {
  return (
    <Button {...props}>
      <DeleteIcon />
    </Button>
  );
};

const Button = styled.button`
  ${buttonAppearanceReset};
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
  path {
    fill: ${props => props.theme.colors.dark};
  }
  &:hover {
    path {
      fill: ${props => props.theme.colors.error};
    }
  }
`;

import * as React from "react";

export const VideoIcon = (props?: any) => (
    <svg
        {...props}
        width="19"
        height="13"
        viewBox="0 0 19 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M14.6667 5V1.5C14.6667 0.95 14.2167 0.5 13.6667 0.5H1.66669C1.11669 0.5 0.666687 0.95 0.666687 1.5V11.5C0.666687 12.05 1.11669 12.5 1.66669 12.5H13.6667C14.2167 12.5 14.6667 12.05 14.6667 11.5V8L18.6667 12V1L14.6667 5Z" fill="#000"/>
    </svg>
);

import * as React from 'react';
import { memo } from 'react';
import styled, { keyframes } from 'styled-components';

type LoadingBarComponentProps = {
  transparentBackground?: boolean;
};

const LoadingBarComponent = ({
  transparentBackground,
}: LoadingBarComponentProps) => {
  return (
    <LoadingBarBackground transparent={transparentBackground}>
      <LoadingBarElement />
    </LoadingBarBackground>
  );
};

const animation = keyframes`
  0% {
    width: 0%;
    margin-left: 0;
  }

  50% {
    width: 100%;
    margin-left: 0;
  }

  100% {
    margin-left: 100%;
    width: 0%;
  }
`;

const LoadingBarBackground = styled.div<{
  transparent?: boolean;
}>`
  height: 5px;
  background: ${props =>
    props.transparent
      ? 'transparent'
      : props.theme.colors.loadingBarBackground};
  border-radius: 20px;
  width: 100%;
  display: flex;
`;

const LoadingBarElement = styled.div`
  height: 5px;
  background: ${props => props.theme.colors.loadingBar};
  border-radius: 20px;
  animation: ${animation} 2s ease-in-out infinite;
`;

export const LoadingBar = memo(LoadingBarComponent);

import * as React from 'react';

export const Plus18Icon = (props?: any) => (
    <svg
        width={47}
        height={37}
        viewBox="0 0 47 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path
          d="M40 10.75V6a5 5 0 0 0-5-5H6a5 5 0 0 0-5 5v25a5 5 0 0 0 5 5h29a5 5 0 0 0 5-5v-4.75"
          stroke="#fff"
          strokeWidth={2}
      />
      <path
          d="M14.864 10.2V27h-3.12V12.816H8.192V10.2h6.672Zm14.229 7.968c.88.4 1.552.944 2.016 1.632.48.672.72 1.464.72 2.376 0 1.024-.288 1.92-.864 2.688-.56.768-1.36 1.36-2.4 1.776-1.04.4-2.248.6-3.624.6s-2.584-.2-3.624-.6c-1.024-.416-1.816-1.008-2.376-1.776-.56-.768-.84-1.664-.84-2.688 0-.912.232-1.704.696-2.376.464-.688 1.128-1.232 1.992-1.632-.672-.368-1.192-.848-1.56-1.44-.352-.608-.528-1.304-.528-2.088 0-.944.256-1.768.768-2.472.528-.704 1.264-1.248 2.208-1.632.944-.384 2.032-.576 3.264-.576 1.248 0 2.344.192 3.288.576.944.384 1.68.928 2.208 1.632.528.704.792 1.528.792 2.472 0 .784-.184 1.472-.552 2.064-.368.592-.896 1.08-1.584 1.464Zm-4.152-5.76c-.976 0-1.752.208-2.328.624-.56.416-.84 1-.84 1.752 0 .72.28 1.296.84 1.728.576.416 1.352.624 2.328.624.992 0 1.776-.208 2.352-.624.576-.432.864-1.008.864-1.728 0-.752-.296-1.336-.888-1.752-.576-.416-1.352-.624-2.328-.624Zm0 12.384c1.152 0 2.064-.24 2.736-.72.672-.48 1.008-1.144 1.008-1.992 0-.832-.336-1.488-1.008-1.968-.672-.48-1.584-.72-2.736-.72-1.152 0-2.056.24-2.712.72-.656.48-.984 1.136-.984 1.968 0 .848.328 1.512.984 1.992.656.48 1.56.72 2.712.72ZM45.42 19.8h-4.248V24h-2.568v-4.2h-4.248v-2.4h4.248v-4.2h2.568v4.2h4.248v2.4Z"
          fill="#fff"
      />
    </svg>
);


import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

export const BorderedIcon: FC<{ icon?: string }> = ({
  children,
  icon,
  ...props
}) => (
  <Container {...props}>
    <Icon icon={icon} />
  </Container>
);
const Icon = styled.div<{
  icon?: string;
}>`
  background-image: ${props => 'url(' + props.icon + ')'};
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-position: center;
  background-size: contain;
  border-radius: inherit;
`;
const Container = styled.div`
  min-width: 60px;
  width: 60px;
  height: 60px;
  background: ${props => props.theme.colors.button};
  border: 1px solid ${props => props.theme.colors.buttonBorder};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default BorderedIcon;

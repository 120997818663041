import * as React from 'react';
import styled from 'styled-components';
import { P2 } from '../Text/P2';
import { getRequired, getError } from '../utils/errors';
import {
  UIInput,
  Label,
  LabelCotainer,
  RequiredSpan,
  getInputBackground,
} from './input';

type Props = {
  value?: string;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors: object;
  touched: object;
  name: string;
  disabled: boolean;
  type?: string;
  label?: string;
  placeholder?: string;
  dataTestId?: string;
  as?: 'textarea';
  max?: number;
  minNumber?: number;
};

export const InputPill: React.FC<Props> = React.memo(
  ({
    label,
    placeholder,
    value,
    onBlur,
    onChange,
    errors,
    touched,
    onClick,
    onFocus,
    name,
    disabled,
    type,
    dataTestId,
    as: inputAs,
    max,
    minNumber,
  }) => {
    const [charLeft, setCharLeft] = React.useState(() => {
      if (value) {
        return Number(max) - value.length
      }
      return Number(max)
    });
    const required = getRequired(name, errors, touched);
    const error = getError(name, errors, touched);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let currentCount = e.target.value.length;
      if (max && max > 0) {
        let charLeft = max - currentCount;
        if (charLeft >= 0) {
          setCharLeft(() => {
            return charLeft;
          });
          onChange(e);
        }
      }
    };

    return (
      <InputPillContainer
        disabled={disabled}
        required={required}
        error={error}
        inputAs={inputAs}
      >
        {label && (
          <LabelCotainer horizontal={false} unstyled>
            <Label unstyled>
              <LabelSpan>{label}</LabelSpan>
              <RequiredSpan required={required}> *</RequiredSpan>
            </Label>
            {max && <CharLeftSpan>{charLeft}</CharLeftSpan>}
          </LabelCotainer>
        )}
        <UIInput
          as={inputAs ? inputAs : 'input'}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={max ? handleChange : onChange}
          onFocus={onFocus}
          onClick={onClick}
          type={type}
          unstyled
          data-testid={dataTestId}
          name={name}
          error={error}
          min={minNumber}
        />
      </InputPillContainer>
    );
  }
);

const LabelSpan = styled.span``;

const CharLeftSpan = styled(P2)`
  margin: 0 0 0 auto;
  color: ${props => props.theme.colors.buttonActive};
`;

const InputPillContainer = styled.div<{
  error: boolean;
  required: boolean;
  disabled: boolean;
  inputAs?: string;
}>`
  background: ${props => getInputBackground(props)};
  &:focus-within {
    ${Label} ${LabelSpan} {
      //color: ${props => props.theme.colors.dark};
      //font-weight: ${props => props.theme.typo.fontSemiBold};
    }
  }
  & ${UIInput} {
    width: 100%;
    flex: 1;
    border: none;
    padding: ${props =>
      props.inputAs === 'textarea'
        ? '10px 20px 2px 20px'
        : '10px 20px 20px 20px'};
  }
`;

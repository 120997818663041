import * as React from "react";

export const FeedbacksHalfStarIcon = ({ props }: any) => (
  <svg
    {...props}
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3333 6.70008L11.3416 6.18341L8.99996 0.666748L6.65829 6.19175L0.666626 6.70008L5.21663 10.6417L3.84996 16.5001L8.99996 13.3917L14.15 16.5001L12.7916 10.6417L17.3333 6.70008ZM8.99996 11.8334V4.08341L10.425 7.45008L14.075 7.76675L11.3083 10.1667L12.1416 13.7334L8.99996 11.8334Z"
      fill="black" />

  </svg>
);

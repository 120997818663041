import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { CloseIcon } from '../assets/icons';
import { textInput } from '../Variables/mixins';
import { size } from '../Variables/sizes';

type PaymentNotificationProps = {
  onClick?: (a?: any) => any;
  onClose?: (a?: any) => any;
  text?: string;
};

export const PaymentNotification: FC<PaymentNotificationProps> = ({
  onClick,
  onClose,
  text,
  children,
  ...props
}) => {
  return (
    <Container onClick={onClick} {...props}>
      <Content>
        <Text>{children}</Text>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Arrow />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background: ${props => props.theme.colors.dark};
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const Content = styled.div`
  max-width: 960px;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 38px 0 10px;
  @media screen and (max-width: ${size.mobile}) {
    padding: 0 10px 0 10px;
  }
`;

const Text = styled.p`
  ${textInput}
  margin-left: auto;
`;

const CloseButton = styled.button`
  height: 100%;
  width: 70px;
  background: none;
  border: none;
  margin-left: auto;
  cursor: pointer;
`;

const Arrow = styled.div`
  @media screen and (max-width: ${size.mobile}) {
    display: none;
  }
  z-index: 3;
  position: relative;
  background: ${props => props.theme.colors.dark};
  width: 17px;
  height: 17px;
  top: 34px;
  right: 79px;
  transform: rotate(-45deg);
`;

export const typo = {
  primaryFont: '"Montserrat", sans-serif',
  regular: 400,
  fontMedium: 500,
  fontSemiBold: 600,
  textColor: '#E1F5FE',
  fontSize: '18px',
  lineHeight: '140%',
  p2Size: '14px',
  h1Size: '36px',
  pSize: '16px',
  smallSize: '12px'
};

export const colors = {
  roundButtonPrimary: '#000000',
  roundButtonPrimaryBoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -3px 0px rgba(0, 0, 0, 0.5);',
  roundButtonPrimaryPressed: '0px 0px 4px rgba(0, 0, 0, 0.25);',
  roundButtonPrimaryHover: '#2B2747',
  roundButtonPrimaryDisabled: '',
  roundButtonSecondary: '#FFFFFF',
  roundButtonSecondaryBoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 2px rgba(0, 0, 0, 0.25), inset 0px -3px 0px rgba(0, 0, 0, 0.1);',
  roundButtonSecondaryPressed: '#FFFFFF',
  roundButtonSecondaryFocus: '#FFFFFF',
  roundButtonSecondaryHover: '#FFFFFF',
  roundButtonSecondaryPushed: 'rgba(0,0,0,1.0)',
  inputPlaceholderColor: 'rgba(0,0,0,0.35)',
  white: '#FFFFFF',
  black: '#000000',
  headerBackground: '#000000',
  headerFormBackground: '#FFFFFF',
  cartButtonGradient: '#000000',
  checkboxGradientStart: '#000000',
  radioBackground: '#000000',
  adultMaskGradient: 'linear-gradient(180deg, #DB86B3 0%, #3F51B5 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);',
  containerHoverGradirnt: 'radial-gradient(61.11% 61.11% at 100% 0%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);'
};

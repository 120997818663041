import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { P2 } from '../Text/P2';
import { P1 } from '../Text/P1';

type Props = {
  options: {
    id: string;
    label: string;
  }[];
  selected: string;
  title: string;
  onItemClick: (value: string) => void;
  labels: boolean;
};

export const WhiteSelect: FunctionComponent<Props> = props => {
  const { options, selected, title, onItemClick, labels } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggling = () => setIsOpen(!isOpen);

  const handleOptionClick = (e: React.MouseEvent<HTMLUListElement>) => {
    const selected = (e.target as HTMLLIElement).dataset;
    if (!selected.label || !selected.id) return;

    setSelectedOption(selected.label);
    setIsOpen(false);
    onItemClick(selected.id);
  };

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  return (
    <Root>
      <Header isOpen={isOpen}>
        <PackagesTitle>{title}</PackagesTitle>
        <DropDownHeader onClick={toggling}>
          {selectedOption}
          <SelectArrow isOpen={isOpen}>↓</SelectArrow>
        </DropDownHeader>
      </Header>
      {isOpen && (
        <ListContainer>
        <DropDownList onClick={e => handleOptionClick(e)}>
          {options.map(option => (
            <ListItem
              key={Math.random()}
              data-label={option.label}
              data-id={option.id}
            >
              {labels && `${option.id} - `}{option.label}
            </ListItem>
          ))}
        </DropDownList>
        </ListContainer>
      )}
    </Root>
  );
};

const ListContainer = styled.div`
  position: relative;
  width: 100%;
`

const Root = styled('div')`
  margin: 0;
  width: 100%;
  font-family: ${props => props.theme.typo.primaryFont};
  box-sizing: border-box;
`;

const Header = styled('div')<{ isOpen: boolean }>`
  padding: 10px 15px;
  background: ${props => props.theme.colors.light};
  border-radius: ${({ isOpen }) => (isOpen ? '8px 8px 0 0' : '8px')};
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const DropDownHeader = styled(P1)`
  font-family: ${props => props.theme.typo.primaryFont};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`;

const DropDownList = styled('ul')`
  background: #ffffff;
  position: absolute;
  margin: 0;
  padding: 0;
  top: -1px;
  box-sizing: border-box;
  left: 0;
  width: inherit;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  border-top: 0;
  z-index: 3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

const ListItem = styled('li')`
  padding: 10px 15px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background: #e1f5fe;
  }
`;

const SelectArrow = styled('span')<{ isOpen: boolean }>`
  font-size: 11px;
  color: ${props => props.theme.colors.dark};
  margin-left: 5px;
  vertical-align: middle;
  display: inline-block;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const PackagesTitle = styled(P2)`
  font-family: ${props => props.theme.typo.primaryFont};
  margin: 0;
  overflow: hidden;
`;

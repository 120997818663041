import * as React from 'react';
import { FC } from 'react';
import styled, {css} from 'styled-components';

import { SelectArrowIcon } from '../assets/icons';
type Props = {
  name?: string;
  pillStyle?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: any;
  children?: React.ReactNode;
}

export const Select: FC<Props> = React.memo(({ children, pillStyle, ...props }) => {
  return (
    <CustonSelectContainer>
      <RelativeContainer>
        <CustomSelect pillStyle={pillStyle} {...props}>{children}</CustomSelect>
        <StyledArrow pillStyle={pillStyle} />
      </RelativeContainer>
    </CustonSelectContainer>
  );
});

const RelativeContainer = styled.div`
  position: relative;
  max-width: 100%;
`;

const CustonSelectContainer = styled.div`
  display: flex;
  overflow: hidden;
  /* position: relative; */
`;

const CustomSelect = styled.select<{
  pillStyle?: boolean;
}>`
  font-weight: normal;
  padding: ${props => props.pillStyle ? "0" : "5px"};
  padding-left: ${props => props.pillStyle ? "15px" : "20px"};
  z-index: 1;
  position: relative;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  height: ${props => props.pillStyle ? "20px" : "50px"};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  color: ${props => props.pillStyle ? props.theme.colors.dark : props.theme.colors.gradientStart};
  font-family: ${props => props.theme.typo.primaryFont};
  max-width: 100%;
`;
const StyledArrow = styled(SelectArrowIcon)`
  position: absolute;
  left: ${props => props.pillStyle ? "0" : "5px"};
  top: ${props => props.pillStyle ? "8px" : "20px"};
  ${props => props.pillStyle && css`& path {
    stroke: ${props.theme.colors.dark};
  }`}
`;

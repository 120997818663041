import * as React from 'react';

export const EnvelopIcon = (props: any) => {
  return ( 
    <svg {...props} width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.0137 78.3477L2.39238 124.468H147.605V25.5319L34.0137 78.3477Z" fill="#FFE777"/>
    <path d="M93.1521 75L53.1627 52.6597V78.3478L74.9988 101.065L96.835 78.3478L93.1521 75Z" fill="#FEDC3D"/>
    <path d="M53.1627 78.3477L2.39238 124.468V25.5319L53.1627 52.8158V78.3477Z" fill="#FFF1B1"/>
    <path d="M56.8488 75L53.1627 78.3477L2.39238 25.5319L56.8488 75Z" fill="#FFE777"/>
    <path d="M138.031 35.4926V115.771L96.835 78.3477L138.031 35.4926Z" fill="#FFF1B1"/>
    <path d="M2.39238 25.5319L74.9988 91.4903L147.605 25.5319H2.39238Z" fill="white"/>
    <path d="M147.606 23.1384H2.39355C1.07139 23.1384 0 24.21 0 25.5319V124.468C0 125.79 1.07139 126.862 2.39355 126.862H147.606C148.929 126.862 150 125.79 150 124.468V25.5319C150 24.21 148.929 23.1384 147.606 23.1384ZM145.213 122.074H4.78711V27.9255H145.213V122.074Z" fill="black"/>
    <path d="M11.1627 116.502C11.635 117.022 12.2842 117.287 12.9352 117.287C13.5097 117.287 14.0854 117.081 14.5439 116.665L56.8485 78.2338L73.3907 93.261C73.8472 93.6756 74.4234 93.883 75.0003 93.883C75.5769 93.883 76.1534 93.6759 76.6099 93.261L93.1523 78.2335L135.458 116.665C135.917 117.082 136.493 117.287 137.067 117.287C137.718 117.287 138.367 117.023 138.839 116.503C139.728 115.525 139.656 114.011 138.677 113.122L96.7113 75L138.676 36.878C139.655 35.9892 139.727 34.4754 138.838 33.4969C137.949 32.5184 136.436 32.4463 135.457 33.3349L75 88.2557L14.5436 33.3357C13.5656 32.4472 12.0516 32.5189 11.1621 33.4977C10.2732 34.4763 10.3456 35.99 11.3244 36.8789L53.2887 75L11.3247 113.121C10.3462 114.01 10.2735 115.524 11.1627 116.502Z" fill="black"/>
</svg>
  )
};
import * as React from 'react';

export const EnterPasswordIcon = (props: any) => {
  return ( 
    <svg {...props} width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M147.606 138.351V108.032H138.032L128.457 123.192L138.032 138.351H147.606Z" fill="#DCDCDC"/>
    <path d="M138.032 138.351V108.032H86.9681L75 79.3086L63.0319 108.032H2.39355V138.351H138.032Z" fill="#EEEEEE"/>
    <path d="M23.4032 127.979C26.0471 127.979 28.1903 125.835 28.1903 123.192C28.1903 120.548 26.0471 118.405 23.4032 118.405C20.7594 118.405 18.6161 120.548 18.6161 123.192C18.6161 125.835 20.7594 127.979 23.4032 127.979Z" fill="#969696"/>
    <path d="M49.2032 127.979C51.8471 127.979 53.9903 125.835 53.9903 123.192C53.9903 120.548 51.8471 118.405 49.2032 118.405C46.5594 118.405 44.4161 120.548 44.4161 123.192C44.4161 125.835 46.5594 127.979 49.2032 127.979Z" fill="#969696"/>
    <path d="M75 127.979C77.6438 127.979 79.7871 125.835 79.7871 123.192C79.7871 120.548 77.6438 118.405 75 118.405C72.3562 118.405 70.2129 120.548 70.2129 123.192C70.2129 125.835 72.3562 127.979 75 127.979Z" fill="#969696"/>
    <path d="M100.797 127.979C103.441 127.979 105.584 125.835 105.584 123.192C105.584 120.548 103.441 118.405 100.797 118.405C98.1529 118.405 96.0097 120.548 96.0097 123.192C96.0097 125.835 98.1529 127.979 100.797 127.979Z" fill="#969696"/>
    <path d="M126.597 127.979C129.241 127.979 131.384 125.835 131.384 123.192C131.384 120.548 129.241 118.405 126.597 118.405C123.953 118.405 121.81 120.548 121.81 123.192C121.81 125.835 123.953 127.979 126.597 127.979Z" fill="#969696"/>
    <path className="keyIconColor" d="M86.9663 53.4569V69.7336H96.5426V63.3507H106.117V69.7336H115.693V63.3504H128.456V47.074H110.902L86.9663 53.4569Z" fill="#81E3E2"/>
    <path className="keyIconColor" d="M86.9681 43.8823V53.4568H125.264L125.267 69.7336H134.839L134.84 43.8823H86.9681Z" fill="#42C8C6"/>
    <path className="keyIconColor" d="M141.224 34.3086H131.649V47.0744H141.224C144.75 47.0744 147.606 44.2147 147.606 40.6915C147.606 37.165 144.75 34.3086 141.224 34.3086Z" fill="#42C8C6"/>
    <path className="keyIconColor" d="M131.649 34.3086H59.7735C56.8661 21.3383 45.2842 11.6489 31.4364 11.6489C15.3961 11.6489 2.39384 24.6512 2.39384 40.6915C2.39384 56.7318 15.3961 69.7341 31.4364 69.7341C45.2842 69.7341 56.8661 60.0415 59.7735 47.0744H131.649C135.176 47.0744 138.032 44.2147 138.032 40.6915C138.032 37.165 135.176 34.3086 131.649 34.3086ZM31.4361 56.9683C22.4616 56.9683 15.1597 49.666 15.1597 40.6918C15.1597 31.7176 22.4619 24.4153 31.4361 24.4153C40.4104 24.4153 47.7126 31.7176 47.7126 40.6918C47.7126 49.666 40.4107 56.9683 31.4361 56.9683Z" fill="#81E3E2"/>
    <path d="M23.4041 116.011C19.4443 116.011 16.2231 119.232 16.2231 123.192C16.2231 127.151 19.4443 130.373 23.4041 130.373C27.3639 130.373 30.5851 127.151 30.5851 123.192C30.5851 119.232 27.3639 116.011 23.4041 116.011ZM23.4041 125.585C22.084 125.585 21.0105 124.512 21.0105 123.192C21.0105 121.872 22.0843 120.798 23.4041 120.798C24.7242 120.798 25.7977 121.872 25.7977 123.192C25.7977 124.512 24.7242 125.585 23.4041 125.585Z" fill="black"/>
    <path d="M49.2023 116.011C45.2426 116.011 42.0214 119.232 42.0214 123.192C42.0214 127.151 45.2426 130.373 49.2023 130.373C53.1621 130.373 56.3833 127.151 56.3833 123.192C56.383 119.232 53.1615 116.011 49.2023 116.011ZM49.2023 125.585C47.8822 125.585 46.8088 124.512 46.8088 123.192C46.8088 121.872 47.8825 120.798 49.2023 120.798C50.5222 120.798 51.5959 121.872 51.5959 123.192C51.5959 124.512 50.5219 125.585 49.2023 125.585Z" fill="black"/>
    <path d="M75 116.011C71.0402 116.011 67.819 119.232 67.819 123.192C67.819 127.151 71.0402 130.373 75 130.373C78.9598 130.373 82.181 127.151 82.181 123.192C82.181 119.232 78.9598 116.011 75 116.011ZM75 125.585C73.6799 125.585 72.6064 124.512 72.6064 123.192C72.6064 121.872 73.6802 120.798 75 120.798C76.3198 120.798 77.3936 121.872 77.3936 123.192C77.3936 124.512 76.3201 125.585 75 125.585Z" fill="black"/>
    <path d="M100.798 130.373C104.757 130.373 107.979 127.151 107.979 123.192C107.979 119.232 104.757 116.011 100.798 116.011C96.8379 116.011 93.6167 119.232 93.6167 123.192C93.617 127.151 96.8385 130.373 100.798 130.373ZM100.798 120.798C102.118 120.798 103.191 121.872 103.191 123.192C103.191 124.512 102.117 125.585 100.798 125.585C99.4778 125.585 98.4041 124.512 98.4041 123.192C98.4041 121.872 99.4781 120.798 100.798 120.798Z" fill="black"/>
    <path d="M126.596 130.373C130.556 130.373 133.777 127.151 133.777 123.192C133.777 119.232 130.556 116.011 126.596 116.011C122.636 116.011 119.415 119.232 119.415 123.192C119.415 127.151 122.636 130.373 126.596 130.373ZM126.596 120.798C127.916 120.798 128.989 121.872 128.989 123.192C128.989 124.512 127.916 125.585 126.596 125.585C125.276 125.585 124.202 124.512 124.202 123.192C124.202 121.872 125.276 120.798 126.596 120.798Z" fill="black"/>
    <path d="M147.606 105.639H88.5639L77.2096 78.3883C76.8378 77.4962 75.9665 76.9155 75 76.9155C74.0335 76.9155 73.1622 77.4965 72.7904 78.3883L61.4361 105.639H2.39355C1.07168 105.639 0 106.71 0 108.032V138.351C0 139.674 1.07168 140.745 2.39355 140.745H147.606C148.928 140.745 150 139.674 150 138.351V108.032C150 106.71 148.928 105.639 147.606 105.639ZM145.213 135.958H4.78711V110.426H63.0319C63.9984 110.426 64.8697 109.845 65.2415 108.953L75 85.5323L84.7585 108.953C85.1303 109.845 86.0016 110.426 86.9681 110.426H145.213V135.958Z" fill="black"/>
    <path d="M31.4361 59.3614C41.7311 59.3614 50.1064 50.986 50.1064 40.6911C50.1064 30.3961 41.7311 22.0208 31.4361 22.0208C21.1412 22.0208 12.7658 30.3961 12.7658 40.6911C12.7658 50.986 21.1415 59.3614 31.4361 59.3614ZM31.4361 26.8082C39.0911 26.8082 45.319 33.0361 45.319 40.6911C45.319 48.346 39.0911 54.574 31.4361 54.574C23.7812 54.574 17.5532 48.346 17.5532 40.6911C17.5532 33.0361 23.7812 26.8082 31.4361 26.8082Z" fill="black"/>
    <path d="M31.4361 72.1274C38.6191 72.1274 45.6431 69.6413 51.2136 65.1269C56.1984 61.0872 59.8479 55.5809 61.6274 49.4678H84.5745V69.7336C84.5745 71.0558 85.6462 72.1271 86.9681 72.1271H96.5426C97.8645 72.1271 98.9361 71.0558 98.9361 69.7336V65.7442H103.723V69.7336C103.723 71.0558 104.795 72.1271 106.117 72.1271H115.691C117.013 72.1271 118.085 71.0558 118.085 69.7336V65.7442H122.872V69.7336C122.872 71.0558 123.944 72.1271 125.266 72.1271H134.84C136.162 72.1271 137.234 71.0558 137.234 69.7336V49.4678H141.223C146.062 49.4678 149.999 45.5306 149.999 40.6913C149.999 35.8521 146.062 31.9148 141.223 31.9148H61.6274C59.8479 25.8018 56.1984 20.2957 51.2136 16.2557C45.6431 11.7413 38.6191 9.25488 31.4361 9.25488C14.1023 9.25488 0 23.3572 0 40.6913C0 58.0254 14.1023 72.1274 31.4361 72.1274ZM31.4361 14.0423C43.8006 14.0423 54.7359 22.7856 57.4374 34.8322C57.6826 35.9253 58.6529 36.7017 59.7729 36.7017H141.223C143.423 36.7017 145.213 38.4911 145.213 40.691C145.213 42.8909 143.423 44.6804 141.223 44.6804H96.5426C95.2207 44.6804 94.149 45.7518 94.149 47.0739C94.149 48.3961 95.2207 49.4675 96.5426 49.4675H132.447V67.3397H127.66V63.3504C127.66 62.0282 126.588 60.9568 125.266 60.9568H115.692C114.37 60.9568 113.298 62.0282 113.298 63.3504V67.3397H108.511V63.3504C108.511 62.0282 107.439 60.9568 106.117 60.9568H96.5429C95.221 60.9568 94.1493 62.0282 94.1493 63.3504V67.3397H89.3622V47.0742C89.3622 45.752 88.2905 44.6807 86.9687 44.6807H59.7741C58.6538 44.6807 57.6838 45.4576 57.4386 46.5501C54.7371 58.5967 43.8018 67.34 31.4373 67.34C16.7432 67.34 4.78828 55.3854 4.78828 40.691C4.78828 25.9966 16.742 14.0423 31.4361 14.0423Z" fill="black"/>
</svg>
  )
};

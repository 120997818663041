import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { AccountIcon, SettingsIcon, BellIcon, LogIcon } from '../assets/icons';
import { textList } from '../Variables/mixins';

const icon = (type?: string) => {
  switch (type) {
    case 'bell':
      return <Bell type={type} />;
    case 'settings':
      return <Settings type={type} />;
    case 'account':
      return <Account type={type} />;
    case 'booking':
      return <Log type={type} />;
    default:
      return null;
  }
};

type IconButtonProps = {
  type?: string;
};

export const IconButton: FC<IconButtonProps> = ({
  children,
  type,
  ...rest
}) => (
  <ButtonContainer {...rest} type={type}>
    {icon(type)}
  </ButtonContainer>
);

const ButtonContainer = styled.div<{
  active?: boolean;
  type?: string;
}>`
  display: flex;
  justify-content: center;
  width: 60px;
  height: 40px;
  border-radius: 50px;
  background: ${props =>
    props.active ? props.theme.colors.secondaryButton : 'none'};
  border: ${props =>
    props.active
      ? `1px solid ${props.theme.colors.buttonBorder}`
      : '1px solid transparent'};
  &:hover,
  &:focus {
  }
  &:active {
    background: ${props => props.theme.colors.secondaryButton};
    border-color: ${props => props.theme.colors.buttonBorder};
  }
`;

const commonIcon = () => `
  align-self: center;
`;

const Bell = styled(BellIcon)`
  ${commonIcon};
`;

const Settings = styled(SettingsIcon)`
  ${commonIcon};
`;

const Account = styled(AccountIcon)`
  ${commonIcon}
`;

const Log = styled(LogIcon)`
  ${commonIcon}
`;

export const IconsContainer = styled.div`
  display: flex;
`;

export const HeaderText = styled.span<{ isColorBlack?: boolean }>`
  ${textList}
  color:  ${props => !props.isColorBlack ? props.theme.colors.light : props.theme.colors.dark};
  font-weight: ${props => props.theme.typo.fontSemiBold};
`;

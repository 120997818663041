import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { ArrowRightIcon } from '../assets/icons/arrowRight.icon';
import { menuItemStyle } from '../Variables/mixins';
import { size } from '../Variables/sizes';
import { P2 } from '../Text/P2'

export const MenuItem: FC<{children?: React.ReactNode;}> = ({ children, ...rest }) => (
  <MenuItemContainer {...rest}>
    {children}
    <Arrow />
  </MenuItemContainer>
);


const MenuItemContainer:FC<{}> = styled.div`
  ${menuItemStyle};
`;

const Arrow = styled(ArrowRightIcon)`
  margin-left: auto;
  display: none;
  @media screen and (max-width: ${size.mobile}) {
    display: inline-flex;
  }
`;

export const MenuBlockHeader: FC<{children?: React.ReactNode;}> = ({ children, ...rest }) => (
  <MenuBlockHeaderContainer {...rest}>
    <MenuBlockHeaderText>
    {children}
    </MenuBlockHeaderText>
  </MenuBlockHeaderContainer>
);

const MenuBlockHeaderContainer = styled.div`
  height: 37px;
  margin-top: 20px;
  opacity: 0.5;
  padding: 0 20px;
  display: flex;
  align-items: center;
`

const MenuBlockHeaderText = styled(P2)`
  font-size: 12px;
  color: ${props => props.theme.colors.dark}
`

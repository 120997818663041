import * as React from 'react';
import { memo, useCallback } from 'react';
import styled from 'styled-components';

import { SubHeaderItem } from './SubHeaderItem';
import { size } from '../Variables/sizes';

type SubHeaderProps = {
  items: {
    icon: 'puzzle' | 'star' | 'pallete';
    title?: string;
    active?: boolean;
  }[];
  handleClick?: (a: any) => any;
};

const SubHeaderComponent = ({ items, ...props }: SubHeaderProps) => {
  const handleItemClick = useCallback(
    (e, index) => {
      e.preventDefault();
      console.log(index, 'index');
    },
    [items]
  );
  return (
    <Container {...props}>
      {items.map((item, index) => (
        <SubHeaderItem
          onClick={e => handleItemClick(e, index)}
          key={item.title}
          {...item}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.button};
  @media screen and (max-width: ${size.mobile}) {
    position: fixed;
    top: 0;
    width: 100%;
    justify-content: space-around;
  }
`;

export const SubHeader = memo(SubHeaderComponent);

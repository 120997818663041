import * as React from 'react';
import { memo, FC } from 'react';
import styled, { css } from 'styled-components';

import { textButton, shimmerStyles } from '../Variables/mixins';
import { StyledPropsTheme } from '../Variables';

export type ButtonProps = {
  disabled?: boolean;
  load?: boolean;
  className?: string;
  onClick?: (a?: any) => any;
  filled?: boolean;
  type?: "button" | "submit" | "reset" | undefined
  form?: string
  pressed?: boolean
  shrinked?: boolean
  children?: React.ReactNode
};

export const Button: FC<ButtonProps> = memo(({ children, ...rest }) => (
  <ButtonContainer {...rest}>{children}</ButtonContainer>
));

const getButtonBackground = (props: ButtonProps & StyledPropsTheme) => {
  if (props.disabled) {
    return css`
      background: ${props.theme.colors.buttonDisabled};
      cursor: not-allowed;
    `;
  } else {
    return props.filled
      ? css`
          background-color: ${props => props.theme.colors.gradientStart};
          &:hover {
            background: ${props => props.theme.colors.primaryButtonHover};
          }
          &:active {
            background: ${props => props.theme.colors.primaryButtonActive};
          }
        `
      : css`
          background: ${props.theme.colors.button};
          &:hover{
            background: ${(props: ButtonProps & StyledPropsTheme) =>
              props.disabled
                ? props.theme.colors.buttonDisabled
                : props.theme.colors.buttonActive};
          }
          &:active {
            background: ${(props: ButtonProps & StyledPropsTheme) =>
              props.theme.colors.buttonPushed};
          }
        `;
  }
};

const ButtonContainer = styled.button<ButtonProps & StyledPropsTheme>`
  ${textButton};
  appearance: none;
  border: ${(props) =>
    props.load
      ? '1px solid transparent'
      : '1px solid ' + props.theme.colors.buttonBorder};
  box-sizing: border-box;
  border-radius: 10px;
  color: ${props => props.theme.typo.textColor};
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  ${props => !props.shrinked && css`
    width: 100%;
    max-width: 400px;
    flex: 1;
  `}
  ${(props: ButtonProps & StyledPropsTheme) =>
    props.load ? shimmerStyles : getButtonBackground};
`;

Button.defaultProps = {
  load: false,
  disabled: false,
};
// Button.propTypes = {
//   children: oneOfType([string, node]),
//   disabled: bool,
//   load: bool
// }

import styled, { css } from 'styled-components';
import { p1Styles } from '../Text/P1';

const activeStyles = css`
background: ${props => props.theme.colors.dark};
    color: ${props => props.theme.colors.light};
`;

export const CatalogButton = styled('button')<{
  active?: boolean;
}>`
  ${p1Styles};
  appearance: none;
  padding: 8px 10px;
  border-radius: 10px;
  color: ${props => props.theme.colors.dark};
  background: ${props => props.theme.colors.light};
  border: none;
  display: flex;
  align-items: center;
  &:active {
    ${activeStyles}
  }
  ${props => props.active && activeStyles}
`;

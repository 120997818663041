import * as React from 'react';
import styled from 'styled-components';

import { AddGoodIcon } from '../assets/icons/addGood.icon';

type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AddButton: React.FC<Props> = ({ ...props }) => (
  <AddContainer {...props}>
    <AddGoodIcon />
  </AddContainer>
);

export const AddContainer = styled.button`
  height: 60px;
  width: 60px;
  box-shadow: ${props => props.theme.colors.addButtonShadow};
  background: ${props => props.theme.colors.addButtonGradient};
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
`;

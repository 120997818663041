import * as React from 'react'
import styled, { css } from 'styled-components'

import { MaestroIcon } from './cardIcons/Maestro.icon'
import { MastercardIcon } from './cardIcons/Mastercard.icon'
import { WorldIcon } from './cardIcons/World.icon'
import { VisaIcon } from './cardIcons/Visa.icon'

import { textParagraph } from '../Variables/mixins'

const CardContainer = styled.button`
  ${textParagraph}
  width: 220px;
  min-width: 220px;
  height: 70px;
  border-radius: 10px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  //display:inline-block;
  border: none;
  cursor: pointer;
`

export const SubscriptionAdd = styled(CardContainer)`
  background: ${props => props.theme.colors.primaryButtonHover};
  border: 1px solid ${props => props.theme.colors.buttonBorder};
  color: ${props => props.theme.colors.light}
`

export const SubscriptionCard = styled(CardContainer)<{
  active?: boolean;
}>`
  box-shadow: 0px 0px 10px ${props => props.theme.colors.button};
  margin-right: 20px;
  background: ${props => props.theme.colors.light};
  ${props => props.active && css`
    border: 5px solid ${props.theme.colors.gradientStart};
    width: 230px;
    min-width: 230px;
    height: 80px;
  `}
`

export const CardIcon = ({cardBrand, ...props}: {cardBrand?: string}) => {
  switch(cardBrand) {
    case 'Mastercard':
      return(<CardIconContainer><MastercardIcon {...props}/></CardIconContainer>)
    case 'Maestro':
      return(<CardIconContainer><MaestroIcon {...props}/></CardIconContainer>)
    case 'Visa':
      return(<CardIconContainer><VisaIcon {...props}/></CardIconContainer>)
    case 'Мир':
      return(<CardIconContainer><WorldIcon {...props}/></CardIconContainer>)
    case 'Card':
      return(<CardIconContainer><MaestroIcon {...props}/></CardIconContainer>)
    default: 
      return null
  }
}

const CardIconContainer = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
`

export const SubscriptionsList = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  white-space: nowrap;
  align-items: center;
`
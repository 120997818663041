import { defaultsDeep } from 'lodash-es';

import * as common from './themes/common';
import * as common2 from './themes2.0/common';

export type ThemeProps = typeof common;
export type ThemeProps2 = typeof common2;

export type StyledPropsTheme = {
  theme: ThemeProps;
};

export type StyledPropsTheme2 = {
  theme: ThemeProps2;
};

export type StyledTheme = Record<'light' | 'dark', ThemeProps>;
export type StyledTheme2 = Record<'light' | 'dark', ThemeProps2>;

const THEMES: Record<string, ThemeProps> = {};

// Recursivly returns vars depends on theme.
export function getStyleVars(theme = 'light') {
  const themeVars = THEMES[theme] || {};
  const commonTheme = {...common, colors: {...common.colors, ...common2.colors}}
  return defaultsDeep(themeVars, commonTheme);
}


import * as React from 'react';
import {useCallback, useState, useEffect, memo, useRef} from 'react';
import styled, { css } from 'styled-components';

import { A } from '../Text/A';
import { textInput, shimmerStyles } from '../Variables/mixins';
import { size } from '../Variables/sizes';
import { P2 } from '../Text/P2';
import { profileLabelContainerStyle } from '../Cards/ProfileCard';
import { getRequired, getError } from '../utils/errors';

type InputComponentProps = {
  textarea?: boolean;
  horizontal?: boolean;
  errors: object;
  touched: object;
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  load?: boolean;
  labelButton?: Node | string;
  labelAction?: (any?: any) => any;
  inputButton?: Node | string | Element;
  inputButtonAction?: (any?: any) => any;
  withDomain?: boolean;
  domain?: string;
  unstyled?: boolean;
  onChange: (any?: any) => any;
  maxLength?: number;
  onBlur?: (any?: any) => any;
  onFocus?: (any?: any) => any;
  id?: string;
  value?: string;
  type?: string;
  labelDescription?: string;
  withMaxLength?: boolean;
  handleBlur?: (any?: any) => any;
  step?: any;
  fullWidth?: boolean;
  required?: boolean;
  novalidate?: boolean;
  strikethrough?: boolean;
  isRequired?: boolean;
};

const InputComponent = ({
  label,
  placeholder,
  errors,
  touched,
  name,
  labelButton,
  labelAction,
  disabled,
  horizontal,
  load,
  inputButton,
  inputButtonAction,
  textarea,
  labelDescription,
  withDomain,
  domain,
  unstyled,
  onChange,
  maxLength,
  withMaxLength,
  handleBlur,
  id,
  value,
  type,
  step,
  // TODO: add input full width
  fullWidth,
  novalidate,
  strikethrough,
  isRequired,
  ...props
}: InputComponentProps) => {
  const [charactersLeft, setCharactersLeft] = useState(0);
  const domainRef = useRef<HTMLDivElement>(null)
  const [domainPadding, setDomainPadding] = useState<number>(0)

  useEffect(() => {
    if(domainRef && domainRef.current){
      setDomainPadding(domainRef.current.offsetWidth)
    }
  })

  const required = React.useMemo(() => {
    return getRequired(name, errors, touched)
  }, [
    name,
    errors,
    touched,
    isRequired
  ]);

  const error = React.useMemo(() => {
    return getError(name, errors, touched)
  }, [name, errors, touched])

  useEffect(() => {
    if (maxLength) {
      setCharactersLeft(maxLength);
    }
  }, [maxLength, setCharactersLeft]);
  const handleChangeWithMaxLength = useCallback(
    e => {
      if (maxLength) {
        e.preventDefault();
        if (maxLength && maxLength - e.target.value.length >= 0) {
          setCharactersLeft(maxLength - e.target.value.length);
          onChange(e);
        }
      } else {
        onChange(e);
      }
    },
    [setCharactersLeft, onChange, withMaxLength]
  );
  const handleInputClick = useCallback(
    e => {
      e.preventDefault();
      if (inputButtonAction) {
        inputButtonAction();
      }
    },
    [inputButtonAction]
  );
  return (
    <Container horizontal={horizontal} unstyled={unstyled} {...props}>
      {(label || labelButton || labelDescription || withMaxLength) && (
        <LabelCotainer
          horizontal={horizontal}
          withMaxLength={withMaxLength}
          unstyled={unstyled}
        >
          {label && (
            <Label
              horizontal={horizontal}
              required={required}
              unstyled={unstyled}
            >
              {label}
              <RequiredSpan required={required || isRequired}>
                {' '}
                *
              </RequiredSpan>
            </Label>
          )}
          {labelButton && typeof labelButton === 'string' && (
            <LabelLink onClick={labelAction}>
              <span>{labelButton}</span>
            </LabelLink>
          )}
          {labelButton && typeof labelButton === 'object' && (
            <LabelLink onClick={labelAction}>{labelButton}</LabelLink>
          )}
          {labelDescription && (
            <LabelDescription>{labelDescription}</LabelDescription>
          )}
          {withMaxLength && <CharactersLeft>{charactersLeft}</CharactersLeft>}
        </LabelCotainer>
      )}
      <InputContainer unstyled={unstyled} horizontal={horizontal}>
        {textarea ? (
          <Textarea
            novalidate={novalidate}
            disabled={disabled || load}
            load={load}
            placeholder={placeholder}
            required={required}
            error={error}
            onChange={handleChangeWithMaxLength}
            unstyled={unstyled}
            name={name}
            id={id}
            onBlur={handleBlur}
            value={value}
            type={type}
            step={step}
            strikethrough={strikethrough}
          />
        ) : (
          <UIInput
            as="input"
            novalidate={novalidate}
            disabled={disabled || load}
            load={load}
            placeholder={placeholder}
            required={required}
            error={error}
            withDomain={withDomain}
            domainPadding={domainPadding}
            onChange={handleChangeWithMaxLength}
            onBlur={handleBlur}
            unstyled={unstyled}
            name={name}
            id={id}
            value={value}
            type={type}
            step={step}
            strikethrough={strikethrough}
          />
        )}
        {withDomain && <Domain ref={domainRef}>{domain}</Domain>}
        {inputButton && (
          <InputButton href="#" onClick={handleInputClick}>
            {inputButton}
          </InputButton>
        )}
      </InputContainer>
    </Container>
  );
};

InputComponent.defaultProps = {
  type: 'text',
};

export const CharactersLeft = styled.span`
  opacity: 0.25;
  font-size: 14px;
`;

const Domain = styled.div`
  ${textInput};
  background: ${props => props.theme.colors.button};
  color: ${props => props.theme.colors.dark};
  position: absolute;
  right: 0;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 10px;
  display: flex;
  align-items: center;
`;

const Container = styled.div<{
  unstyled?: boolean;
  horizontal?: boolean;
}>`
  max-width: ${props => !props.unstyled && '400px'};
  width: 100%;
  ${props =>
    props.horizontal &&
    css`
      max-width: 100%;
      padding: 15px 30px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      @media screen and (max-width: ${size.mobile}) {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px;
      }
    `}
`;

export const LabelCotainer = styled.div<{
  unstyled?: boolean;
  horizontal?: boolean;
  withMaxLength?: boolean;
}>`
  align-items: center;
  display: flex;
  flex: 1;
  padding: 0 0 3px 0;
  ${props =>
    props.withMaxLength &&
    css`
      justify-content: space-between;
    `}
  padding: ${props => props.unstyled && '20px 20px 0 20px'};
  ${props =>
    props.horizontal &&
    css`
      ${profileLabelContainerStyle};
      max-width: 200px;
      flex-flow: column;
      align-items: flex-start;
    `}
  @media screen and (max-width: ${size.mobile}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const LabelDescription = styled(P2)`
  color: ${props => props.theme.colors.buttonPushed};
  font-size: 12px;
  margin: 0;
  padding-right: 20px;
`;

const InputContainer = styled.div<{
  unstyled?: boolean;
  horizontal?: boolean;
}>`
  display: flex;
  /* align-items: center; */
  position: relative;
  flex: ${props => (props.horizontal ? '3' : '2')};
  max-width: ${props => !props.unstyled && '400px'};
  padding-left: ${props => props.horizontal && '20px'};
  box-sizing: border-box;
  @media screen and (max-width: ${size.mobile}) {
    padding-left: 0;
    flex: 1;
    max-width: 100%;
    width: 100%;
  }
`;

const InputButton = styled(A)<{
  href?: string;
  onClick?: (a: any) => any;
}>`
  margin-left: 20px;
  cursor: pointer;
  color: ${props => props.theme.colors.gradientStart};
  align-self: center;
  flex: 1;
`;

const LabelLink = styled(A)<{
  onClick?: (a: any) => any;
}>`
  color: ${props => props.theme.colors.light};
  margin-left: auto;
  cursor: pointer;
  text-decoration: none;
  span {
    text-decoration: underline;
  }
  &:hover {
    span {
      text-decoration: none;
    }
  }
`;

export const Label = styled.label<{
  horizontal?: boolean;
  required?: boolean;
  unstyled?: boolean;
}>`
  font-family: ${props => props.theme.typo.primaryFont};
  line-height: ${props => props.theme.typo.lineHeight};
  font-size: 14px;
  color: ${props =>
    props.unstyled ? props.theme.colors.dark : props.theme.colors.light};
  ${props => props.unstyled && 'opacity: 0.75;'}
  ${props =>
    props.horizontal &&
    css`
      color: ${props => props.theme.colors.dark};
      opacity: 0.7;
    `}
`;

export const RequiredSpan = styled.span<{
  required?: boolean;
}>`
  display: ${props => (props.required ? 'inline' : 'none')};
  color: ${props => props.theme.colors.error};
`;

export const inputStyles = () => {
  return css<{
    unstyled?: boolean;
    horizontal?: boolean;
    withMaxLength?: boolean;
    load?: boolean;
    error?: boolean;
    withDomain?: boolean;
    domainPadding?: number;
    required?: boolean;
    strikethrough?: boolean;
  }>`
    appearance: none;
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid;
    border-color: ${props => getInputBorder(props)};
    background: ${props => getInputBackground(props)};
    box-sizing: border-box;
    padding: ${props => (props.unstyled ? '20px' : '15px 20px;')};
    max-width: ${props => !props.unstyled && '400px'};
    width: 100%;
    ${props => (props.withDomain && `padding-right: ${props.domainPadding}px`)};
    outline: none;
    min-width: 0;
    &:focus {
      outline: none;
      border-color: ${props =>
        props.error || props.required
          ? props.theme.colors.error
          : props.theme.colors.inputFocused};
    }
    ::placeholder {
      ${textInput};
      color: ${props => getInputPlaceholder(props)};
    }
    :-webkit-autofill {
      border-color: ${props => getInputBorderAutoFill(props)}!important;
    }
    ${props => props.load && shimmerStyles};
    ${props => props.strikethrough && 'text-decoration: line-through;'}
    color: ${props => props.load && props.theme.colors.inputLoading};
  `;
};

export const UIInput = styled.input<{
  load?: boolean;
  error?: any;
  unstyled?: boolean;
  type?: string;
  step?: number | string;
  withDomain?: boolean;
  domainPadding?: number;
  novalidate?: boolean;
  strikethrough?: boolean;
  as: string;
  min?: number;
}>`
  resize:vertical;
  ${textInput};
  ${inputStyles};
`;

export const Textarea = styled.textarea<{
  load?: boolean;
  error?: any;
  unstyled?: boolean;
  type?: string;
  step?: number | string;
  withDomain?: boolean;
  novalidate?: boolean;
  strikethrough?: boolean;
}>`
  ${textInput};
  ${inputStyles};
  min-height: 50px;
  max-height: 200px;
  resize: vertical;
`;

const getInputBorder = (props: any) => {
  if (props.required) {
    return props.theme.colors.error;
  }
  if (props.load) {
    return 'transparent';
  }
  if (props.unstyled) {
    return 'transparent';
  }
  return props.theme.colors.button;
};

const getInputPlaceholder = (props: any) => {
  if (props.error) {
    return props.theme.colors.dark;
  }
  if (props.load) {
    return props.theme.colors.inputLoading;
  }
    return props.theme.colors.inputPlaceholderColor;
};

export const getInputBackground = (props: any) => {
  if (props.required) {
    return props.theme.colors.light;
  }
  if (props.error) {
    return props.theme.colors.error;
  }
  if (props.disabled) {
    return props.theme.colors.button;
  }
  if (props.unstyled) {
    return 'transparent';
  }
  return props.theme.colors.light;
};

export const getInputBorderAutoFill = (props: any) => {
  if (props.required) {
    return props.theme.colors.error;
  }
  if (props.error) {
    return props.theme.colors.error;
  }
  if (props.load) {
    return 'transparent';
  }
  if (props.unstyled) {
    return 'transparent';
  }
  return props.theme.colors.button;
};

export const Input = memo(InputComponent);

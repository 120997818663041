import * as React from 'react';
import { useMemo, FC } from 'react';
import styled, { css } from 'styled-components';
import { textList } from '../Variables/mixins';
import { size } from '../Variables/sizes';
import {StyledPropsTheme, StyledPropsTheme2} from '../Variables';

type RadioProps = RadioButtonProps & {
  values: Array<any>;
  className?: string;
  name: string;
  description?: React.ReactNode;
};
// TODO: refactor this to be separate file
export const Radio: React.FC<RadioProps> = ({
  values,
  className,
  description,
  ...props
}) => {
  const single = useMemo(() => {
    return values.length === 1;
  }, [values.length]);
  return (
    <Container className={className}>
      {values.map((value, index) => (
        <RadioButton {...description} single={single} key={index} {...props} {...value} />
      ))}
    </Container>
  );
};

type RadioButtonProps = {
  defaultChecked?: string;
  name?: string;
  value?: string;
  onChange?: any;
  label?: string;
  checked?: boolean;
  title?: string;
  description?: React.ReactNode;
  single?: boolean;
  highlighted?: boolean;
  fullWidth?: boolean;
  black?: boolean;
  price?: string;
};

export const RadioButton: FC<RadioButtonProps> = ({
  defaultChecked,
  name,
  value,
  onChange,
  label,
  checked,
  title,
  description,
  single,
  highlighted,
  black,
  price,
  ...props
}) => {
  const checkedComputed = checked || defaultChecked === value;
  return (
    <RadioContainer
      {...props}
      key={value}
      checked={checkedComputed}
      highlighted={highlighted}
      data-testid="radio-container"
    >
      <Input
        onChange={onChange}
        type="radio"
        name={name}
        value={value}
        checked={checkedComputed}
      />
      <RadioButtonContainer checked={checkedComputed} single={single} black={black}>
        <RadioMiddle checked={checkedComputed} black={black}>
          <RadioInner checked={checkedComputed} />
        </RadioMiddle>
      </RadioButtonContainer>
      <RadioLabelContainer>
        <RadioLabel checked={checkedComputed}>{title}</RadioLabel>
        <RadioDescription>{(checkedComputed && description) && description}</RadioDescription>
        {price && <PriceDescription checked={checkedComputed}>{price}</PriceDescription>}
      </RadioLabelContainer>
    </RadioContainer>
  );
};

type UiBooleanProps = {
  checked?: boolean;
  black?: boolean;
  single?: boolean;
};

type UiProps = StyledPropsTheme & StyledPropsTheme2 & UiBooleanProps;

const getOuterContainerBackground = (props: UiProps) => {
    return css`
      background: ${props.checked
        ? props.theme.colors.radioBackground
        : props.theme.colors.buttonActive};
    `;
};

const getMiddleContainerBackground = (props: UiProps) => {
  return css`
      background: ${props.checked
      ? props.theme.colors.radioBackground
      : props.theme.colors.buttonActive};
    `;
};

const Container = styled.div`
  width: 100%;
`;
export const RadioLabelContainer = styled.div`
  max-width: 100%;
  overflow: hidden;
  padding-left: 10px;
  cursor: pointer;
  align-self: auto;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
  flex: 1;
`;

export const RadioLabel = styled.span<{
  checked?: boolean;
}>`
  ${textList};
  font-size: 14px;
  opacity: 0.75;
  ${props =>
    props.checked &&
    css`
      opacity: 1;
    `};
`;
export const RadioDescription = styled.span`
  ${textList};
  font-size: 12px;
  position: relative;
  z-index: 1;
  margin-top: 13px;
  color: ${props => props.theme.colors.radioDescription};
`;

export const PriceDescription = styled.span<{
  checked?: boolean;
}>`
  ${textList};
  font-size: ${props => props.theme.typo.p2Size};
  margin-top: 10px;
  color: ${props => props.theme.colors.dark};
  ${props =>
    props.checked &&
    css`
      font-weight: ${props => props.theme.typo.fontSemiBold};
    `}
`;

const RadioContainer = styled.div<{
  checked?: boolean;
  fullWidth?: boolean;
  highlighted?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  max-width: ${props => (props.fullWidth ? '100%' : '340px')};
  &:last-of-type {
    margin-bottom: 0;
  }
  ${props =>
    props.highlighted &&
    css<{
      checked?: boolean;
    }>`
      background: ${props => props.checked && props.theme.colors.cardHover};
      border-radius: 10px;
      padding-bottom: 8px;
      padding-top: 8px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 0;
    `}
  @media screen and (max-width: ${size.mobile}) {
    max-width: 100%;
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
`;

const RadioButtonContainer = styled.div<UiProps>`
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${getOuterContainerBackground};
  border: 1px solid ${props => props.theme.colors.buttonBorder};
  border-radius: 100px;
  align-self: baseline;
  ${props =>
    props.single &&
    css`
      display: none;
    `}
`;

const RadioMiddle = styled.div<UiProps>`
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${getMiddleContainerBackground};
  border-radius: 100px;
`;

const RadioInner = styled.div<{
  checked?: boolean;
}>`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: ${props =>
    props.checked ? props.theme.colors.light : props.theme.colors.buttonDisabled};
`;

import React from "react";

export const OtherDomainIcon = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="100"
            fill="none"
            viewBox="0 0 101 100"
            {...props}
        >
            <g clipPath="url(#clip0_13187_135369)">
                <path
                    fill="#FFE289"
                    d="M50.25 4.62L12.484 34.83v12.43l69.149 51.144h6.383V34.83L50.25 4.62z"
                ></path>
                <path
                    fill="#FFE289"
                    d="M81.633 42.153v56.251H64.08l-13.83-9.413-13.83 9.413H12.484V47.26L50.25 17.05l31.383 25.103z"
                ></path>
                <path
                    fill="#FF4081"
                    d="M64.08 56.064v42.34h-6.383l-10.638-21.17 10.638-21.17h6.383z"
                ></path>
                <path
                    fill="#FF4081"
                    d="M57.697 56.064H36.42v42.34h21.277v-42.34zM97.454 38.466L52.24 2.296a3.176 3.176 0 00-1.991-.7l-2.128 4.149 2.128 3.13 43.222 34.574c.546.437 1.237.7 1.99.7a3.191 3.191 0 001.992-5.683z"
                ></path>
                <path
                    fill="#FF4081"
                    d="M48.259 2.295L3.046 38.465a3.191 3.191 0 003.982 4.984L50.25 8.874V1.596c-.754 0-1.445.263-1.991.7z"
                ></path>
                <path
                    fill="#2DC6DB"
                    d="M50.25 24.809v21.276c5.874 0 10.638-4.762 10.638-10.638 0-5.875-4.764-10.638-10.638-10.638z"
                ></path>
                <path
                    fill="#2DC6DB"
                    d="M50.25 24.809c2.351 0 4.255 4.764 4.255 10.638 0 5.876-1.904 10.638-4.255 10.638-5.874 0-10.638-4.762-10.638-10.638 0-5.875 4.764-10.638 10.638-10.638z"
                ></path>
                <path
                    fill="#000"
                    d="M50.25 68.298a1.596 1.596 0 100-3.192 1.596 1.596 0 000 3.192z"
                ></path>
                <path
                    fill="#000"
                    d="M98.45 37.22L53.239 1.05A4.8 4.8 0 0050.25 0c-1.08 0-2.142.372-2.988 1.05L2.05 37.22A4.765 4.765 0 00.25 40.956a4.793 4.793 0 004.787 4.788 4.8 4.8 0 002.988-1.05l2.864-2.29v56c0 .88.714 1.595 1.595 1.595h75.532c.881 0 1.596-.715 1.596-1.596V42.405l2.863 2.29a4.8 4.8 0 002.988 1.05 4.793 4.793 0 004.787-4.787c0-1.461-.656-2.824-1.8-3.739zM62.485 57.66v39.149H38.016v-39.15h24.468zm32.979-15.107c-.46 0-.795-.19-.995-.35L75.35 26.91a1.596 1.596 0 10-1.993 2.493l13.064 10.45V96.81H65.676v-39.15h.531a1.596 1.596 0 100-3.19H34.293a1.596 1.596 0 100 3.19h.531v39.15H14.08V39.852l36.17-28.934 18.122 14.497a1.596 1.596 0 001.993-2.493L51.247 7.628a1.596 1.596 0 00-1.994 0L6.031 42.203c-.2.16-.534.35-.994.35-.88 0-1.596-.716-1.596-1.596 0-.486.22-.94.602-1.245l45.213-36.17c.2-.16.534-.35.994-.35.46 0 .794.19.994.35l45.213 36.17c.382.305.601.76.601 1.245 0 .88-.715 1.596-1.595 1.596z"
                ></path>
                <path
                    fill="#000"
                    d="M57.697 80.957c.881 0 1.596-.714 1.596-1.595v-4.256a1.596 1.596 0 10-3.192 0v4.256c0 .881.714 1.595 1.596 1.595zM50.25 23.214c-6.746 0-12.234 5.488-12.234 12.234 0 6.746 5.488 12.234 12.234 12.234 6.746 0 12.234-5.488 12.234-12.234 0-6.746-5.488-12.234-12.234-12.234zm0 21.276c-4.986 0-9.043-4.056-9.043-9.042s4.057-9.043 9.043-9.043c4.986 0 9.043 4.057 9.043 9.043 0 4.986-4.057 9.042-9.043 9.042z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_13187_135369">
                    <path
                        fill="#fff"
                        d="M0 0H100V100H0z"
                        transform="translate(.25)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
};
import * as React from 'react';
import styled, { css } from 'styled-components';
import { StyledPropsTheme } from 'Variables';

import { RadioLabel, RadioDescription, RadioLabelContainer } from './radio';

type CheckboxTextProps = {
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
  description?: React.ReactNode;
  name?: string;
  text: string;
  red?: boolean;
  black?: boolean;
  width100?: boolean;
  isMobile:boolean;
};
 
export const CheckboxText = ({
  checked,
  onChange,
  label,
  description,
  name,
  text,
  red,
  black,
  width100,
  isMobile,
  ...props
}: CheckboxTextProps) => {
  return (
    <Container width100={width100}>
      <BackgroundContainer isMobile={isMobile} checked={checked} black={black} {...props}>
        <Input
          checked={checked}
          onChange={onChange}
          name={name}
          type="checkbox"
        />
          <SpanTextCheck checked={checked} >{text}</SpanTextCheck>
      </BackgroundContainer>
      <RadioLabelContainer>
        {label && <RadioLabel checked={checked}>{label}</RadioLabel>}
        {description && <RadioDescription>{description}</RadioDescription>}
      </RadioLabelContainer>
    </Container>
  );
};

type UiBooleanProps = {
  checked?: boolean;
  black?: boolean;
};

type UiProps = StyledPropsTheme & UiBooleanProps;

const getOuterContainerBackground = (props: UiProps) => {
  return css`
      background: ${props.checked
      ? props.theme.colors.nightBlue
      : props.theme.colors.light};
      border: 1px solid ${props => props.theme.colors.buttonBorder};
    `;
};

const getTextColor = (props: UiProps) => {
    return css`
        color: ${props.checked
        ? props.theme.colors.cardHover
        : props.theme.colors.dark};
    `;
};

const Container = styled.label<{ width100?: boolean }>`
  display: flex;
  padding: 7px 0;
  width: ${(props) => props.width100 ? '100%' : 'auto'};
`;

const SpanTextCheck = styled.span<UiProps>`
    font-weight: 500;
    font-size: 14px;
    font-family: "Montserrat",sans-serif;
    line-height: 140%;
    ${getTextColor}
    align-items: center;
    text-align: center;
`;

const BackgroundContainer = styled.div<UiProps & {isMobile: boolean}>`
  position: relative;
  width: ${props => props.isMobile ? `40px` : `50px`};
  height: ${props => props.isMobile ? `40px` : `50px`};
  box-sizing: border-box;
  ${getOuterContainerBackground}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;

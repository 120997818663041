import { QuestionIcon } from '../assets/icons/question.icon';
import * as React from 'react';
import styled from 'styled-components';

import { textList } from '../Variables/mixins';
import { size } from '../Variables/sizes';
import { Popup } from '../Popup/Popup';

type ToggleProps = {
  checked?: boolean;
  onChange?: (e?: any) => any;
  label?: string;
  values: React.ReactNode[];
  name?: string;
  small?: boolean;
  toggleBackground?: boolean;
  value?: string
  textHints?: string;
};

export const Toggle = ({
  checked,
  onChange,
  label,
  values,
  name,
  small,
  value,
  textHints,
  
  ...props
}: ToggleProps) => {

  const [openPopup, setOpenPopup] = React.useState(false);

  let _textHints = textHints && JSON.parse(`${textHints}`)

  return (
  <Container>
    {_textHints?.text && <div onClick={() => setOpenPopup(true)}>
      <QuestionIcon />
    </div> }
    <ContainerLoyaut small={small} checked={checked} {...props}>
        <Input
          checked={checked}
          onChange={onChange}
          type="checkbox"
          name={name}
          value={value}
        />
        <LeftContainer small={small} checked={checked}>
          {values && values[0]}
        </LeftContainer>
        <RightContainer small={small} checked={checked}>
          {values && values[1]}
        </RightContainer>
      </ContainerLoyaut>
      {openPopup && <Popup hits={true} buttons={[_textHints?.buttonsText]} title={_textHints?.label} text={_textHints?.text} img={_textHints?.img} handleButtons={()=>setOpenPopup(false)} onClose={()=>setOpenPopup(false)}/>}
    </Container>   
  );
};

const Input = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const ItemContainer = styled.div<{
  small?: boolean;
}>`
  ${textList};
  min-width: ${props => props.small ? '15px' : '20px'};
  font-size: ${props => (props.small ? '10px' : '14px')};
  height: ${props => (props.small ? '15px' : '20px')};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => (props.small ? '5px' : '10px')};
  box-sizing: content-box;
`;
const RightContainer = styled(ItemContainer)<{
  checked?: boolean;
}>`
  box-shadow: ${props =>
    props.checked ? props.theme.colors.cardShadow : null};
  background: ${props => (props.checked ? props.theme.colors.light : null)};
  color: ${props =>
    props.checked ? props.theme.colors.dark : props.theme.colors.light};
  font-weight: ${props => (props.checked ? 'bold' : 'normal')};
  @media screen and (max-width: ${size.mobile}) {
    flex: 1;
  }
`;
const LeftContainer = styled(ItemContainer)<{
  checked?: boolean;
}>`
  box-shadow: ${props =>
    props.checked ? null : props.theme.colors.cardShadow};
  background: ${props => (props.checked ? null : props.theme.colors.light)};
  color: ${props =>
    props.checked ? props.theme.colors.light : props.theme.colors.dark};
  font-weight: ${props => (props.checked ? 'normal' : 'bold')};
  @media screen and (max-width: ${size.mobile}) {
    flex: 1;
  }
`;
const ContainerLoyaut = styled.div<{
  small?: boolean;
  toggleBackground?: boolean;
  checked?: boolean
}>`
  position: relative;
  display: flex;
  padding: ${props => (props.small ? '2px' : '5px')};
  min-width: fit-content;
  align-items: center;
  height: ${props => (props.small ? '25px' : '40px')};
  // background: ${props => !props.toggleBackground && props.theme.colors.gradientStart};
  background: ${props => props.toggleBackground ? props.checked ? props.theme.colors.checkboxGradientStart : 'grey' : props.theme.colors.checkboxGradientStart};
  border-radius: 100px;
  box-sizing: content-box;
  @media screen and (max-width: ${size.mobile}) {
    width: 100%;
  }
  /* align-items: center; */
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SmallTogleContainer = styled.div`
  max-width: 87px;
`

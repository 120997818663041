import * as React from 'react';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { size } from '../Variables/sizes';
import { P2 } from '../Text/P2';
import { newLoading, LoadingAnimation } from '../Variables/mixins';

type PillCardProps = {
  className?: string;
  hasMargin?: boolean;
  loading?: boolean;
  white?: boolean;
  children?: React.ReactNode;
};

export const PillCard: FC<PillCardProps> = React.memo(
  ({ children, className, hasMargin, loading, white }) => {
    return (
      <Container
        className={className}
        hasMargin={hasMargin}
        loading={loading}
        white={white}
      >
        {children}
      </Container>
    );
  }
);

const Container = styled.div<{
  hasMargin?: boolean;
  loading?: boolean;
  white?: boolean;
}>`
  display: flex;
  max-width: 500px;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 10px;
  box-shadow: ${props => props.theme.colors.pillShadow};
  border-radius: 10px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: ${props => props.hasMargin && '20px'};
  ${props => props.white && `background: ${props.theme.colors.light};`}
  @media screen and (max-width: ${size.mobile}) {
    border-radius: 0;
  }
  ${props =>
    props.loading &&
    css`
      position: relative;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        ${newLoading}
      }
    `}
`;

export const PillLineItem = styled.div<{
  noRightPadding?: boolean;
  noLeftPadding?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 0;
  box-sizing: border-box;
  ${props => !props.noRightPadding && 'padding-right: 20px;'}
  ${props =>
    !props.noLeftPadding && 'padding-left: 20px;'}
  align-items: center;
  width: 100%;
`;

export const PillLineLabel = styled(P2) <{
  bold?: boolean;
}>`
  ${props => props.bold && 'font-weight: 600'}
  margin: 0;
`;

export const PillSelectLabel = styled.label`
  font-family: ${(props) => props.theme.typo.primaryFont};
  font-size: ${(props) => props.theme.typo.p2Size};
  display: block;
  margin-bottom: 5px;
`;

export const PillHorizontalContainer = styled.div<{
  itemCount?: number;
  loading?: boolean;
}>`
 ${(props) => props.loading && LoadingAnimation}
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: ${size.mobile}) {
    flex-direction: column;
  }
  & > div {
    flex: 1;
    ${props => props.itemCount && `max-width: calc(100% / ${props.itemCount})`};
    @media screen and (max-width: ${size.mobile}) {
      max-width: 100%;
      width: 100%;
    }
  }
`;

export const PillMarginContainer = styled.div<{
  loading?: boolean;
}>`
 ${(props) => props.loading && LoadingAnimation}
  padding-top: 20px;
  padding-bottom: 10px;
  & ${PillLineItem} {
    padding-bottom: 10px;
  }
  width: 100%;
`;

import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';

import { textList } from '../Variables/mixins';
import { size } from '../Variables/sizes';

import { PuzzleIcon, StartIcon, PalleteIcon } from '../assets/icons';

const iconsConfig = {
  puzzle: PuzzleIcon,
  star: StartIcon,
  pallete: PalleteIcon,
};

type SubHeaderItemProps = {
  title?: string;
  icon: 'puzzle' | 'star' | 'pallete';
  active?: boolean;
  onClick?: (a: any) => any;
};

const SubHeaderItemComponent = ({
  title,
  icon,
  active,
  onClick,
}: SubHeaderItemProps) => {
  const IconElement = iconsConfig[icon];
  return (
    <Item active={active} onClick={onClick}>
      {IconElement && <IconElement active={active} />}
      <span>{title}</span>
    </Item>
  );
};

const Item = styled.a<{
  active?: boolean;
}>`
  ${textList};
  position: relative;
  font-weight: ${props => props.theme.typo.fontMedium};
  display: flex;
  margin-right: 20px;
  padding: 15px 0;
  cursor: pointer;
  color: ${props =>
    props.active
      ? props.theme.colors.gradientStart
      : props.theme.colors.headerItem};
  &:after {
    content: '';
    height: 3px;
    width: 100%;
    left: 0;
    bottom: -1px;
    position: absolute;
    background: ${props =>
      props.active ? props.theme.colors.gradientStart : ''};
  }
  &:hover {
    &:after {
      background: ${props => props.theme.colors.gradientStart};
    }
  }
  span {
    margin-left: 20px;
  }
  @media screen and (max-width: ${size.mobile}) {
    padding: 15px;
    justify-content: center;
    margin-right: 0;
    height: 54px;
    box-sizing: border-box;
    span {
      display: none;
    }
  }
`;

export const SubHeaderItem = memo(SubHeaderItemComponent);

import * as React from 'react';
import styled from 'styled-components';

export const HandlerButton: React.FC = ({ ...props }) => {
  return (
    <Handler {...props}>
      <HandlerItem>.</HandlerItem>
      <HandlerItem>.</HandlerItem>
      <HandlerItem>.</HandlerItem>
      <HandlerItem>.</HandlerItem>
      <HandlerItem>.</HandlerItem>
      <HandlerItem>.</HandlerItem>
    </Handler>
  );
};

const Handler = styled.div`
  appearance: none;
  background: none;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0 3px;
  cursor: pointer;
  color: ${props => props.theme.colors.buttonActive};
  &:hover {
    color: ${props => props.theme.colors.buttonPushed}
  }
`;

const HandlerItem = styled.span`
  width: 50%;
  line-height: 1px;
  margin: 0;
  transform: scale(3);
`;

import * as React from 'react';
import {
  flattenCategories,
  filterCategoryByParent,
  Category,
} from '../../utils/flatten';
import styled from 'styled-components';
import { size } from '../../Variables/sizes';
import { CloseIcon } from '../../assets/icons';
import { useMobile } from '../../hooks/mobile';
import { useState, useMemo, useEffect, FC } from 'react';
import { ArrowIcon } from '../../assets/icons/arrow.icon';
import { PopupContainer, PopupBackground } from '../Popup';
import { PopupScrollContainer } from '../PopupScrollContainer';
import { PopupCategoriesSimpleList } from './PopupCategoriesSimpleList';



export type CategoryItem = {
  id: string;
  name: string;
};

type Props = {
  active?: string;
  filled?: boolean;
  isAdmin?: boolean;
  onDeny: () => void;
  headerLabel: string;
  categories: Category[];
  searchPlaceholder: string;
  onAdminClick?: () => void;
  allCategoriesLabel: string;
  editCategoriesLabel?: string;
  onSetActive: (value: string) => void;
};

export const PopupCategoriesSimple: FC<Props> = React.memo(
  ({
    onDeny,
    active,
    isAdmin,
    categories,
    onSetActive,
    headerLabel,
    onAdminClick,
    allCategoriesLabel,
    editCategoriesLabel,
  }) => {
    const [isMobile] = useMobile();
    const [parentCategory, setParentCategory] = useState<string | null>(null);

    const categoriesMap = useMemo(() => {
      return flattenCategories(categories);
    }, [categories]);

    const parent = useMemo(() => {
      return parentCategory && categoriesMap[parentCategory];
    }, [categoriesMap, parentCategory]);

    useEffect(() => {
      if (active && categoriesMap[active] && categoriesMap[active].parent_id) {
        setParentCategory(categoriesMap[active].parent_id);
      } else {
        setParentCategory(null);
      }
    }, [active, categoriesMap, setParentCategory]);

    const filteredCategories = useMemo(() => {
      return filterCategoryByParent(categoriesMap, parentCategory);
    }, [parentCategory, categoriesMap]);

    const goBack = () => {
      if (parent) {
        const parentId = parentCategory ? categoriesMap[parentCategory].parent_id : null;
        setParentCategory(parentId)
      } else {
        onDeny()
      }
    }

    return (
      <PopupScrollContainer fullWidth>
        <PopupBackground onClick={onDeny} />
        <Container>
          <StyledPopupContainer>
            <CategoriesHeaderWrapper>
              <CategoriesHeader isMobile={Boolean(isMobile)}>
                <HeaderItem>
                  <ArrowIcon onClick={goBack} />
                </HeaderItem>
                <HeaderItem>
                  {parent ? parent?.name : headerLabel}
                </HeaderItem>
                <HeaderItem>
                  <CloseIcon onClick={onDeny} />
                </HeaderItem>
              </CategoriesHeader>
            </CategoriesHeaderWrapper>
            <PopupCategoriesSimpleList
              parent={parent}
              isAdmin={isAdmin}
              onItemClick={onSetActive}
              onAdminClick={onAdminClick}
              isMobile={Boolean(isMobile)}
              onParentClick={setParentCategory}
              allCategoriesLabel={allCategoriesLabel}
              editCategoriesLabel={editCategoriesLabel}
            >
              {filteredCategories}
            </PopupCategoriesSimpleList>
          </StyledPopupContainer>
        </Container>
      </PopupScrollContainer>
    );
  }
);

const CategoriesHeaderWrapper = styled.div`
  height: 70px;
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: row;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.headerFormBackground};
  & {
    path {
      fill: black;
    }
  }
`

const CategoriesHeader = styled.div<{
  isMobile?: boolean
}>`
  width: 940px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: ${props => props.isMobile ? 'space-between' : 'space-around'}; 
`

const HeaderItem = styled.div`
  display: flex;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  @media screen and (max-width: ${size.mobile}) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledPopupContainer = styled(PopupContainer)`
  background-color: rgba(236, 239, 241, 1);
  border-radius: 0px;
  max-width: unset;
  width: 100%;
  padding: 0;
  flex: 1;
`;

PopupCategoriesSimple.defaultProps = {
  categories: [],
};

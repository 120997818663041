import * as React from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonProps } from './index';

import { textButton, shimmerStyles } from '../Variables/mixins';
import { StyledPropsTheme } from 'Variables';

type Props = Omit<ButtonProps, 'form'| 'filled'> & {
  pressed?: boolean;
  small?: boolean;
  children?: React.ReactNode;
};

export const CartSecondaryButton: React.FC<Props> = ({ children, ...rest }) => (
  <ButtonContainer {...rest}>{children}</ButtonContainer>
);

const getButtonBackground = (props: Props & StyledPropsTheme) => {
  if (props.disabled) {
    return css`
      background: ${props.theme.colors.secondaryButtonPushed};
    `;
  } else if (props.pressed) {
    return css`
      background: ${props => props.theme.colors.secondaryButtonPushed};
      &:hover{
        background: ${props => props.theme.colors.cartButtonHover};
        box-shadow: 0px 4px 4px ${props => props.theme.colors.buttonActive}, inset 0px -3px 0px ${props => props.theme.colors.buttonPushed};
      }
      &:active {
        background: ${props => props.theme.colors.secondaryButtonPushed};
      }
    `
  } else {
    return css`
      background: ${props.theme.colors.secondaryButtonPushed};
      &:hover{
        background: ${props => props.theme.colors.cartButtonHover};
        box-shadow: 0px 4px 4px ${props => props.theme.colors.buttonActive}, inset 0px -3px 0px ${props => props.theme.colors.buttonPushed};
      }
      &:active {
        background: ${props => props.theme.colors.secondaryButtonPushed};
      }
    `;
  }
};

const ButtonContainer = styled(Button)<{
  small?: boolean;
}>`
  ${textButton};
  border: ${props =>
    props.disabled || props.load
      ? '1px solid transparent'
      : '1px solid ' + props.theme.colors.buttonBorder};
  color: ${props => props.theme.colors.light};
  &:active {
    box-shadow: 0px 0px 4px ${props => props.theme.colors.buttonActive};
  }
  ${props =>
    props.pressed &&
    css`
      color: ${props => props.theme.colors.light};
      box-shadow: 0px 0px 4px ${props => props.theme.colors.buttonActive};
    `}
  opacity: ${props => (props.disabled ? '0.15' : '1')};
  ${props => (props.load ? shimmerStyles : getButtonBackground)};
  ${props => props.small && css`
    font-size: ${props => props.theme.typo.fontSize};
    padding: 6px 45px;
    width: auto;
  `}
`;

import * as React from 'react';
import { memo, useCallback, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { usePreventDefaultCallback } from '../hooks/preventDefault';
import { inputNumberAppearance } from '../Variables/mixins';
import {IncreaseIcon, DecreaseIcon, DeleteIcon} from '../assets/icons';
import {PrimaryRoundButton} from "../Buttons/RoundButton/roundButtonPrimary";

type PillNumberTypes = {
  value?: string | number;
  onChange: (a?: any) => any;
  limit: string | number;
  maximum?: boolean
};

const PillNumberComponent = ({ value, onChange, limit, maximum }: PillNumberTypes) => {
  const [isMinimum, setIsMinimum] = useState(false);

  useEffect(() => {
    if (Number(value) === Number(limit)) {
      setIsMinimum(true);
    } else {
      setIsMinimum(false);
    }
  }, [value, limit, setIsMinimum]);

  const fireOnchange = useCallback(
    v => {
      if (v >= limit) {
        onChange(v);
      }
    },
    [onChange, limit]
  );

  const handleChange = usePreventDefaultCallback(
    e => {
      fireOnchange(Number(e.target.value));
    },
    [fireOnchange]
  );

  const handleDecrease = usePreventDefaultCallback(() => {
    fireOnchange(Number(value) - 1);
  }, [value, fireOnchange]);

  const handleIncrease = usePreventDefaultCallback(() => {
    fireOnchange(Number(value) + 1);
  }, [fireOnchange]);

  return (
    <PillContainer>
      <PrimaryRoundButtonStyled onClick={handleDecrease} load={false} disabled={isMinimum} Icon={!isMinimum ? <DecreaseIcon fill="#FFFFFF"/> : <DeleteIcon fill="#FFFFFF"/> } size="large"/>
      <Input 
        value={value} 
        onChange={handleChange}
        type="number"
        error={isMinimum}
      />
      <PrimaryRoundButtonStyled onClick={handleIncrease} load={false} disabled={maximum} Icon={<IncreaseIcon fill="#FFFFFF"/>} size="large"/>
    </PillContainer>
  );
};

PillNumberComponent.defaultProps = {
  limit: 0,
  value: 0,
};

const itemStyles = css`
  width: 33.33%;
  height: 50px;
  box-sizing: border-box;
  font-size: 21px;
  border-top: 1px solid ${props => props.theme.colors.button};
  border-bottom: 1px solid ${props => props.theme.colors.button};
`;

const PillContainer = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-basis: 0;
  align-items: flex-end;
  min-width: 157px;
  max-width: 202px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  > button:first-of-type::before {
    transform: rotateY(-180deg);
  }
`;

const PrimaryRoundButtonStyled = styled(PrimaryRoundButton)`
  margin: 0;
`

const Input = styled.input<{
  error?: boolean;
}>`
  ${itemStyles};
  ${inputNumberAppearance};
  border: none;
  flex: 1;
  appearance: none;
  min-width: 0;
  text-align: center;
  margin: 0;
  background: transparent;
  color: ${props => props.error && props.theme.colors.error};
  border-radius: 0;
`;

export const PillNumber = memo(PillNumberComponent);

import { useCallback } from 'react';

export const usePreventDefaultCallback = (
  callback: (e?: any) => any,
  deps: any
) => {
  return useCallback(
    e => {
      e && e.preventDefault();
      callback(e);
    },
    [deps]
  );
};

import React from "react";
import { P, P2 } from "..";
import { Score } from "./Score";
import styled from "styled-components";
import { size } from "../Variables/sizes"
import { useMobile } from "../hooks/mobile";
import { ReviewsIcon } from "../../src/assets/icons";
import {
  PrimaryRoundButton,
  SecondaryRoundButton,
} from "../index";
import {
  LikeIcon,
  HideIcon,
  ShowIcon,
  DislikeIcon,
} from "../../src/assets/icons";

type FeedbackCardType = {
  images: {
    id: number;
    fullUrl: string;
    thumbUrl: string;
  }[];
  rating: number;
  maxScore: number;
  goodName?: string;
  goodLogo?: string;
  storeName: string;
  showTitle: string;
  hideTitle: string;
  answerText: string;
  answerDate: string;
  authorName: string;
  activeLike?: boolean;
  feedbackText: string;
  mappedPrice?: string;
  activeDislike?: boolean;
  datePublication: string;
  usefulnessTitle: string;
  usefulnessNumber: number;
  onGoodClick: () => void;
  moderationMode: boolean;
  addCommentTitle: string;
  onShowClick: () => void;
  onHideClick: () => void;
  onLikeClick: () => void;
  showCommentForm: boolean;
  onCommentClick: () => void;
  onDislikeClick: () => void;
  commentForm: React.ReactNode;
  onAuthorNameClick: () => void;
  onAddCommentClick: () => void;
  isClickedCommentForm: boolean;
  helpfulnessAssessment: boolean;
  status?: "New" | "Published" | "Hidden";
}

export const FeedbackCard = ({
  images,
  status,
  rating,
  goodName,
  maxScore,
  goodLogo,
  showTitle,
  storeName,
  hideTitle,
  authorName,
  answerText,
  answerDate,
  onShowClick,
  onHideClick,
  onGoodClick,
  mappedPrice,
  commentForm,
  onLikeClick,
  feedbackText,
  onCommentClick,
  onDislikeClick,
  moderationMode,
  addCommentTitle,
  showCommentForm,
  usefulnessTitle,
  datePublication,
  usefulnessNumber,
  onAddCommentClick,
  onAuthorNameClick,
  activeLike = false,
  isClickedCommentForm,
  helpfulnessAssessment,
  activeDislike = false,
}: FeedbackCardType) => {
  const [isMobile] = useMobile();
  return (
    <FeedbackContainerWrapper>
      <FeedbacksContainer>
        {moderationMode
          && <GoodCard
            onClick={onGoodClick}
            data-testid='feedback-good-card'
          >
            <GoodCardLogo background={goodLogo} />
            <div>
              <GoodName>{goodName}</GoodName>
              <GoodCost>{mappedPrice}</GoodCost>
            </div>
          </GoodCard>
        }

        <FeedbackCardWrapper moderationMode={moderationMode} status={Boolean(status)}>
          <FeedbackHeader>
            <AuthorName onClick={onAuthorNameClick}>
              <StyledSpan>{authorName}</StyledSpan>
            </AuthorName>
            <InfoWrapper>
              <StyledSpan noView={true}>
                {rating}
              </StyledSpan>
              <Score starsNumber={maxScore} unpaintedStars={maxScore - rating} />
              <FedbacksCount>
                {datePublication}
              </FedbacksCount>
            </InfoWrapper>
          </FeedbackHeader>
          <FeedbackText data-testid='feedback-text'>
            {feedbackText}
          </FeedbackText>

          <FeedbackBottom isImagesBlock={Boolean(images?.length)}>
            {images?.length
              ? <Images>
                {images.map(image => <ImageCard key={image.id} background={image.fullUrl} />)}
              </Images>
              : null
            }

            {helpfulnessAssessment
              && <UsefulnessContainer data-testid='feedback-usfulness'>
                <FedbacksCount>{usefulnessTitle}:</FedbacksCount>
                <IconWrapper status={!!status}>
                  <StyledLikeIcon status={!!status} active={activeLike} onClick={onLikeClick} />
                </IconWrapper>
                <UsefulnessNumber>
                  {usefulnessNumber > 0 && '+'}
                  {usefulnessNumber}
                </UsefulnessNumber>
                <IconWrapper status={!!status}>
                  <StyledDislikeIcon status={!!status} active={activeDislike} onClick={onDislikeClick} />
                </IconWrapper>
              </UsefulnessContainer>
            }

          </FeedbackBottom>

          {answerText
            && <FeedbackAnswer
              onClick={onCommentClick}
              isClicked={isClickedCommentForm}
              data-testid='feedback-answer-text'
            >
              <FeedbackHeader>
                <AuthorName>{storeName}</AuthorName>
                <FedbacksCount>{answerDate}</FedbacksCount>
              </FeedbackHeader>
              <AnsweText>{answerText}</AnsweText>
            </FeedbackAnswer>
          }

        </FeedbackCardWrapper>

        {showCommentForm && commentForm}

        {!showCommentForm && status
          && <ButtonsContainer>
            {(moderationMode && (status === "New" || status === "Hidden"))
              && <StyledSecondaryRoundButton
                load={false}
                onClick={onShowClick}
                Icon={<StyledShowIcon />}
                data-testid='show-feedback-button'
              >
                {!isMobile && showTitle}
              </StyledSecondaryRoundButton>
            }

            {(status === "New" || status === "Published")
              && <StyledSecondaryRoundButton
                load={false}
                onClick={onHideClick}
                Icon={<StyledHideIcon />}
                data-testid='hide-feedback-button'
              >
                {!isMobile && hideTitle}
              </StyledSecondaryRoundButton>}

            {(!answerText && (status === "Hidden" || status === "Published"))
              && <StyledPrimaryRoundButton
                load={false}
                Icon={<ReviewsIcon />}
                onClick={onAddCommentClick}
                data-testid='add-feedback-comment-button'
              >
                {!isMobile && addCommentTitle}
              </StyledPrimaryRoundButton>}
          </ButtonsContainer>
        }

      </FeedbacksContainer>

    </FeedbackContainerWrapper>
  )
}

const IconWrapper = styled.div<{ status?: boolean }>`
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  ${props => !props.status && `cursor: pointer;`}
 ${props => !props.status
    && `&:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 2px rgba(0, 0, 0, 0.25), inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    path {
      fill: black;
    }
  };
  &:active{
    border: 2px solid #000000;
  `};
`

const StyledSpan = styled.span<{ noView?: boolean; }>`
  ${props => props.noView && 'display: none;'}
`

const StyledDislikeIcon = styled(DislikeIcon) <{
  active?: boolean;
  status?: boolean
}>`
${props => !props.status && `cursor: pointer;`}
path {
  fill: ${props => props.active ? "black" : "white"};
  ${props => !props.status && `&:hover{
    fill: black;
  }`}
}
`

const StyledLikeIcon = styled(LikeIcon) <{
  active?: boolean;
  status?: boolean
}>`
  ${props => !props.status && `cursor: pointer;`}
  path {
  fill: ${props => props.active ? "black" : "white"};
  ${props => !props.status && `&:hover{
    fill: black;
  }`}
}
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
  grid-gap: 20px;
  margin: 15px 20px;
  @media screen and (max-width: ${size.mobile}) {
    grid-template-columns: 1fr;
  }
`

const StyledHideIcon = styled(HideIcon)`
  margin-right: 10xp;
  path {
    fill: ${(props) => props.theme.colors.dark};
    opacity: 1;
  }
  @media screen and (max-width: ${size.mobile}) {
    margin-right: 0;
  }
`

const StyledShowIcon = styled(ShowIcon)`
  margin-right: 10xp;
  path {
    fill: ${(props) => props.theme.colors.dark};
    opacity: 1;
  }
  @media screen and (max-width: ${size.mobile}) {
    margin-right: 0;
  }
`

const StyledSecondaryRoundButton = styled(SecondaryRoundButton)`
  flex-grow: 1;
div:last-child{
  margin-left: 10px;
}
@media screen and (max-width: ${size.mobile}) {
  max-width: 100%;
  div:last-child{
    margin-left: 0;
  }
}
`

const StyledPrimaryRoundButton = styled(PrimaryRoundButton)`
  flex-grow: 1;
div:last-child{
  margin-left: 10px;
}
@media screen and (max-width: ${size.mobile}) {
  max-width: 100%;
  div:last-child{
    margin-left: 0;
  }
}
`

const FeedbackContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  margin-left: 30px ;
  width: 95%;
  @media screen and (max-width: ${size.mobile}) {
    margin-left: 0;
  }
`

const FeedbacksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 10px 10px 10px 10px;
  @media screen and (max-width: ${size.mobile}) {
    margin-left: 0;
    margin-top: 30px
  }
`

const GoodName = styled.div`
  margin-bottom: 5px;
`

const GoodCost = styled(P2)`
  font-weight: 700;
`

const GoodCardLogo = styled.div<{ background?: string }>` 
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 10px;
  background-size: cover; 
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  background-image: ${(props) => props.background ? `url(${props.background})` : "unset"};
`

const GoodCard = styled.div`
  display: flex;
  padding: 10px;
  height: 72px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px;
`

const AnsweText = styled(P2)`
  margin-top:10px;
`

const FeedbackAnswer = styled.div<{ isClicked: boolean }>`
  display: flex;
  padding: 10px;
  margin-top: 15px;
  flex-direction: column;
  align-items: flex-start;
  overflow-wrap: anywhere;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0px 10px 10px 10px;
  ${props => props.isClicked && 'cursor: pointer;'}
`

const UsefulnessNumber = styled.span`
  padding: 3px 10px;
  height: 26px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin: 10px;
`

const UsefulnessContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div:first-child {
    margin-right: 10px;
  }
`

const ImageCard = styled.div<{ background: string }>` 
  width: 80px;
  height: 80px;
  margin-right: 10px;
  background-image: url(${(props) => props.background});
  background-size: cover; 
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
`

const Images = styled.div`
  display: flex;
  flex-direction: row;
`

const FeedbackBottom = styled.div<{ isImagesBlock: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content:${props => props.isImagesBlock ? "space-between" : 'flex-end'};
  align-items: flex-end;
  flex-wrap: wrap;
`

const FeedbackText = styled.div`
  line-height: 140%;
  color: #000000;
  margin: 10px 0 15px 0;
  overflow-wrap: anywhere;
`

const AuthorName = styled(P)`
  font-weight: 500;
  margin-right: 10px;
  cursor: pointer;
`

const FeedbackCardWrapper = styled.div<{
  status?: boolean;
  moderationMode?: boolean;
}>`
  background: #FFFFFF;
  width: 100%;
  padding: 20px;
  ${props => !props.moderationMode
    && `border-top-left-radius: 10px; 
        border-top-right-radius: 10px;
        `
  }
  ${props => !props.status
    && `border-bottom-left-radius: 10px; 
        border-bottom-right-radius: 10px;
        `
  }
`
const FeedbackHeader = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${size.mobile}) {
    flex-direction: column;
  }

`

const FedbacksCount = styled.div`
  color: #000000;
  opacity: 0.5;
  height: 20px;
  margin-left: 10px;
  @media screen and (max-width: ${size.mobile}) {
    margin-left: 0;
  }
`

const InfoWrapper = styled.div`
    display: flex;
  @media screen and (max-width: ${size.mobile}) {
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-top: 10px;
  }
`

import * as React from 'react';
import styled from 'styled-components';

import { textList } from '../Variables/mixins';
import { StyledPropsTheme } from '../Variables';

type ButtonCardProps = {
  active: boolean;
  onClick?: any;
};
export const ButtonCard: React.FC<ButtonCardProps> = ({
  children,
  ...props
}) => {
  return <Card {...props}>{children}</Card>;
};

const Card = styled.button`
  ${textList};
  appearance: none;
  border: none;
  width: 100%;
  max-width: 460px;
  min-height: 100px;
  height: auto;
  border-radius: 10px;
  padding: 20px 20px 20px;
  background: ${(props: ButtonCardProps & StyledPropsTheme) =>
    props.active ? props.theme.colors.yellowInputFocused : props.theme.colors.light};
  box-shadow: ${(props: ButtonCardProps & StyledPropsTheme) =>
    props.theme.colors.cardShadow};
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  margin-bottom: 20px;
  &:hover {
    background: ${(props: ButtonCardProps & StyledPropsTheme) =>
      props.active
        ? props.theme.colors.yellowInputFocused
        : props.theme.colors.cardHover};
  }
  &:active {
    background: ${(props: ButtonCardProps & StyledPropsTheme) =>
      props.theme.colors.yellowInputFocused};
    color: ${(props: ButtonCardProps & StyledPropsTheme) =>
      props.theme.colors.dark};
  }
  
`;

import * as React from 'react';

export const WorldIcon = (props: any) => (
  <svg
    {...props}
    width="43"
    height="13"
    viewBox="0 0 43 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8366 1.34615L20.9296 7.40385H20.6389V0H16.666V12.5H20.0575C20.9296 12.5 21.8017 12.0192 22.0924 11.1538L24.9024 5.09615H25.1931V12.5H29.166V0H25.7745C24.9993 0 24.1273 0.576923 23.8366 1.34615Z"
      fill="#24A84D"
    />
    <path
      d="M9.56439 1.62214L7.95454 7.34733H7.67045L6.0606 1.62214C5.77651 0.66794 4.92424 0 3.88258 0H-3.6124e-07V12.4046H3.88258V5.15267H4.16667L6.43939 12.5H9.18561L11.4583 5.15267H11.7424V12.4046H15.625V0H11.7424C10.7008 0 9.84848 0.66794 9.56439 1.62214Z"
      fill="#24A84D"
    />
    <path
      d="M30.209 5.20838V12.5H34.2444V8.28936H38.5751C40.4452 8.28936 42.02 7.05697 42.709 5.31107L30.209 5.20838Z"
      fill="#24A84D"
    />
    <path
      d="M38.573 0H30.209C30.7605 3.04638 33.1502 5.20833 36.0913 5.20833H42.6171C42.709 4.91352 42.709 4.61871 42.709 4.3239C42.6171 1.96541 40.8708 0 38.573 0Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="30.1727"
        y1="2.63041"
        x2="42.6325"
        y2="2.63041"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stop-color="#0FA5E1" />
        <stop offset="0.35" stop-color="#0C9CDA" />
        <stop offset="0.91" stop-color="#0483C6" />
        <stop offset="1" stop-color="#037EC2" />
      </linearGradient>
    </defs>
  </svg>
);
